.LateralModal__background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 201;
}

.LateralModal {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: -100vw;
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  box-shadow: -4px 0 10px 0 rgba(0, 0, 0, 0.15);
  z-index: 201;
  overflow-y: scroll;

  transition: right 0.5s ease;

  padding: 70px 15px 15px;
}

@media screen and (min-width: 768px) {
  .LateralModal {
    right: -400px;
    width: 400px;
  }
}

.LateralModal__close {
  position: absolute;
  top: 30px;
  left: 15px;
  cursor: pointer;
}

.LateralModal__close--icon {
  width: 20px;
  height: 20px;
}

.LateralModal.LateralModal__visible {
  right: 0px;
}
