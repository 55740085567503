@import "../../../styles/baseExt.css";

.Form__Slider {
  margin-top: 21px;

  &--legend_top {
    display: flex;
    justify-content: flex-end;
    color: #bbbbbb;

    span {
      font-weight: bold;
      margin-right: 1rem;
      color: var(--badi-dark-grey);
    }
  }

  &--legend_bottom {
    color: #bbbbbb;
    font-size: 0.73rem;
    display: flex;
    justify-content: space-between;
  }

  .rheostat {
    margin-top: 24px;
    overflow: visible;
    height: 24px;
    position: relative;

    .DefaultProgressBar_progressBar {
      background-color: var(--badi-blue-sky);
      position: absolute;
      height: 3px;
      top: 0px;
    }

    .DefaultHandle_handle {
      background-color: #fff;
      border: 1px solid #d8d8d8;
      height: 24px;
      width: 24px;
      outline: none;
      top: -11px;
      margin-left: -13px;
      box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.12);
      border-radius: 50%;
      z-index: 2;
      cursor: pointer;
    }

    .DefaultBackground {
      background-color: #d8d8d8;
      position: relative;
      height: 3px;
      top: 0px;
      width: 100%;
      cursor: pointer;
    }

    .DefaultProgressBar_progressBar {
      cursor: pointer;
    }
  }
}
