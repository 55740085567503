@import "../../../../styles/baseExt.css";

.SecurityIntro {
  margin: 0;
  padding: 32px 0 24px 0;
  font-size: 1.25rem;

  @media screen and (min-width: $media-breakpoint-md) {
    padding-top: 64px;
  }
}
