.SearchFlatmatesForm {
  &__Tenants {
    > div {
      margin: 16px 0;
    }

    button {
      font-weight: 400;
    }
  }
}
