.AdvancedFilters {
  position: relative;
  display: flex;
  overflow-x: auto;

  > * {
    flex: 1 0 auto;
  }

  > * + * {
    margin-left: 16px;
  }
}
