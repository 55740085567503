@import "../../../../styles/baseExt.css";

.LandingHeader {
  position: relative;
  width: 100%;
  height: 375px;

  @media screen and (min-width: $media-breakpoint-md) {
    height: 432px;
  }

  @media screen and (min-width: $media-breakpoint-lg) {
    height: 576px;
  }

  &__Image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  &__ContentWrapper {
    width: 100%;
    position: absolute;
    bottom: 24px;

    @media screen and (min-width: $media-breakpoint-md) {
      bottom: 40px;
    }

    @media screen and (min-width: $media-breakpoint-lg) {
      bottom: 56px;
    }
  }

  &__Content > * + * {
    margin-top: 16px;

    @media screen and (min-width: $media-breakpoint-lg) {
      margin-top: 32px;
    }
  }
}
