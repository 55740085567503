@import "baseExt.css";

.section {
  position: relative;

  max-width: 742px;
  margin: 24px 0 0;

  background: #fff;
}
.section:first-child {
  margin-top: 0;
}
.section img {
  max-width: 100%;
}
.section_payment {
  color: #ffffff;
  background: #4990e2;
}
.section_warning {
  color: #ffffff;
  background: var(--badi-pink);
}
.section_missing:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  width: 6px;

  content: "";

  background: #ff7485;
}

.section__main.clickable {
  cursor: pointer;
  outline: none;
}

@media screen and (max-width: 999px) {
  .section {
    max-width: 100%;
    margin: 8px 0;
    padding: 16px 0;
  }
}

.section__head {
  position: relative;

  padding: 32px 24px;
  outline: none;
}
.section__head_light {
  padding-top: 16px;
  padding-bottom: 16px;
}
@media screen and (max-width: 999px) {
  .section__head {
    font-size: 15px;

    position: relative;

    padding: 8px 16px 16px;

    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .section_check .section__head {
    padding: 24px 56px;
  }
  .section_check .section__head:before {
    position: absolute;
    top: 20px;
    left: 16px;

    width: 22px;
    height: 22px;

    content: "";

    border: 2px solid #e1e1e1;
    border-radius: 50%;
    background: #fff url("assets/icons/ico_check_gray.svg") no-repeat 50% 50%;
  }
  .section_check .section__head:after {
    position: absolute;
    top: 20px;
    right: 16px;

    width: 26px;
    height: 26px;

    content: "";
    -webkit-transition: -webkit-transform 0.125s ease-out;
    transition: -webkit-transform 0.125s ease-out;
    transition: transform 0.125s ease-out;
    transition:
      transform 0.125s ease-out,
      -webkit-transform 0.125s ease-out;

    border-radius: 50%;
    background: #fff url("assets/icons/ico_down_gray.svg") no-repeat 50% 50%;
  }
  .section_checked .section__head:before {
    border-color: #0edc84;
    background: #0edc84 url("assets/icons/ico_check_white.svg") no-repeat 50%
      50%;
  }
  .section_open .section__head:after {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.section__title {
  margin: 0;
}
.section__title:after {
  display: none;
}
.section__content .section__title {
  margin-bottom: 32px;
}
.section__head_light .section__title {
  display: none;
}
.section__title_center {
  text-align: center;
}
@media screen and (max-width: 999px) {
  .section__title {
    /* font-size: 15px; */
  }
  .section__content .section__title {
    margin-bottom: 16px;
  }
  .section__head_light .section__title {
    display: block;
  }
  .section__title_center {
    text-align: left;
  }
}

.section__hint {
  width: 100%;
  font-size: 1rem;
  font-weight: 400;

  color: var(--badi-dark-grey);
}
@media screen and (max-width: 999px) {
  .section__hint {
    font-size: 0.86rem;
    display: none;
    margin: 6px 0 0;
  }
  .section_open .section__hint {
    display: block;
  }
}

.section__actions {
  position: relative;
  z-index: 5;

  float: right;

  margin: -3px -16px 0 0;
}
.section__actions .button {
  text-decoration: underline;

  color: #494949;
}
.section__actions .button:hover {
  text-decoration: none;
}
@media screen and (max-width: 999px) {
  .section__actions {
    display: block;
  }
  .section_open .section__actions {
    display: block;
  }
}

@media screen and (max-width: 999px) {
  .section__main {
    position: relative;

    overflow: hidden;
  }
  .section_check .section__main {
    height: 0;

    -webkit-transition: 0.25s ease-out;
    transition: 0.25s ease-out;

    opacity: 0;
  }
  .section_open .section__main {
    overflow: visible;

    height: auto;

    opacity: 1;
  }
}

.section__content {
  padding: 0 24px 24px;
}

.section__content_simple {
  padding-top: 24px;
}

.section__content--flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 999px) {
  .section__content {
    padding: 0 16px 16px;
  }
  .section__content_simple {
    padding-top: 16px;
  }
}
