@import "../../styles/baseExt.css";

.Text__button {
  font-size: 0.93rem;
  font-weight: 500;
  border: 0;
  outline: none;
  cursor: pointer;
  margin: 0;
  text-align: center;
  text-decoration: none;
  padding: 0;
  background-color: transparent;
  color: var(--badi-dark-grey);
}
