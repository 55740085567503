@import "../../../styles/baseExt.css";

.Form {
  &__Counter {
    &--wrapper {
      position: relative;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      line-height: 24px;
      padding: 8px 0;
      border-bottom: 1px solid var(--badi-light-grey);

      &:last-of-type {
        border-bottom: none;
      }

      button {
        cursor: pointer;
        color: var(--badi-dark-grey);
        line-height: 22px;
        width: 24px;
        height: 24px;
        border: 1px solid var(--badi-dark-grey);
        border-radius: 50%;
        background-color: var(--badi-white);
      }

      input {
        border: none;
        font-size: 0.93rem;
        text-align: center;
        margin: 0 3px;
      }
    }
  }
}
