@import "baseExt.css";

.dialogs__list {
  margin: 0;
  padding: 0;

  list-style: none;
}

.dialogs__item {
  border-top: 1px solid var(--badi-soft-grey);
  cursor: pointer;
}
@media screen and (min-width: 999px) {
  .sidebar_chat .dialogs__item {
    border-top: none;
    border-bottom: 1px solid var(--badi-soft-grey);
  }
}
@media screen and (max-width: 999px) {
  .sidebar__nav .dialogs__item {
    overflow: hidden;

    height: 0;

    -webkit-transition: 0.125s ease-out;
    transition: 0.125s ease-out;

    opacity: 0;
    border-top: 0;
  }
  .sidebar__nav .dialogs__item_active {
    height: 52px;

    opacity: 1;
  }
  .sidebar__nav_open .dialogs__item {
    height: 52px;

    opacity: 1;
    border-top: 1px solid var(--badi-soft-grey);
  }
}

.dialogs__box {
  position: relative;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  box-sizing: border-box;
  width: 100%;
  padding: 24px 7px 24px 10px;

  text-decoration: none;

  color: #494949;
  border-right: 3px solid #fff;
  background: #fff;

  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.dialogs__box:hover {
  text-decoration: none;

  color: #494949;
  border-color: #e1e1e1;
}
.dialogs__item_active .dialogs__box {
  border-color: var(--badi-green);
}
@media screen and (max-width: 999px) {
  .dialogs__box {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
  }
  .sidebar__nav .dialogs__box {
    overflow: hidden;

    padding-right: 0;
    padding-left: 0 !important;

    color: #b2b2b2;
    border-right: none;
  }
  .sidebar__nav .dialogs__box:hover {
    color: #494949;
  }
  .dialogs__item_active .sidebar__nav .dialogs__box {
    color: #494949;
  }
  .sidebar__nav .dialogs__item_active .dialogs__box {
    color: #494949;
  }
}

.dialogs__view {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.dialogs__avatar {
  display: inline-block;
  overflow: hidden;

  width: 56px;
  height: 56px;
  margin: 0 0 0 -14px;

  vertical-align: middle;

  border: 2px solid #fff;
  border-radius: 50%;
  background-position: 50% 50%;
  background-size: cover;
}
.dialogs__avatar:first-child {
  margin-left: -2px;
}
.dialogs__view_double .dialogs__avatar {
  width: 46px;
  height: 46px;
}
@media screen and (max-width: 999px) {
  .dialogs__avatar {
    width: 32px;
    height: 32px;
  }
  .dialogs__view_double .dialogs__avatar {
    width: 32px;
    height: 32px;
  }
}

.dialogs__photo {
  width: 100%;
  height: 100%;
}

.dialogs__content {
  min-height: 52px;
  overflow: hidden;

  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.dialogs__view + .dialogs__content {
  padding-left: 14px;
}
@media screen and (max-width: 999px) {
  .dialogs__view + .dialogs__content {
    padding-left: 8px;
  }
}

.dialogs__label {
  font-size: 0.86rem;
  font-weight: 500;
  text-align: left;
  color: var(--badi-dark-grey);
}

.dialogs__text {
  font-size: 0.86rem;
  text-align: left;
  color: var(--badi-warm-grey);

  line-height: 1.25;
  overflow: hidden;
  max-width: 100%;
  max-height: 35px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media screen and (max-width: 999px) {
  .dialogs__text {
    font-size: 13px;

    max-height: 18px;
  }
}

.dialogs__tip {
  white-space: initial;
}

.dialogs__info {
  padding-left: 16px;

  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.dialogs__time {
  font-size: 0.67rem;

  position: absolute;
  top: 8px;
  right: 7px;

  color: var(--badi-soft-grey);
}
@media screen and (max-width: 999px) {
  .dialogs__time {
    top: 2px;
  }
  .sidebar__nav .dialogs__time {
    transition: opacity 0.125s ease-out;
    opacity: 0;
  }
  .sidebar__nav_open .dialogs__time {
    opacity: 1;
  }
}

.dialogs__counter {
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;

  box-sizing: border-box;
  min-width: 20px;
  height: 20px;
  padding: 0 4px;

  text-align: center;

  color: #fff;
  border-radius: 20px;
  background: var(--badi-error);
}
.dialogs__advise {
  position: absolute;
  bottom: 3px;
  right: 8px;
  font-size: 0.75em;
  font-weight: 500;
  line-height: 1.04;
  text-align: center;
  color: var(--badi-booking-blue);
}
.dialogs__advise.fullfilled {
  background-color: var(--badi-booking-blue);
  color: #ffffff;
  border: solid 1px var(--badi-booking-blue);
  border-radius: 2px;
  padding: 1px 2px;
}
@media screen and (max-width: 999px) {
  .dialogs__counter {
    font-size: 10px;
    line-height: 18px;

    min-width: 16px;
    height: 16px;
  }
  .sidebar__nav .dialogs__counter {
    -webkit-transition: opacity 0.125s ease-out;
    transition: opacity 0.125s ease-out;

    opacity: 0;
  }
  .sidebar__nav_open .dialogs__counter {
    opacity: 1;
  }
}
.browser_ff .dialogs__counter {
  font-family: Arial, sans-serif;
}
