@import "../../../styles/baseExt.css";

.RoomList {
  .Form__InputWithLabel--wrapper.disabled {
    border: 1px solid var(--badi-new-soft-grey);
    span {
      color: var(--badi-new-soft-grey);
    }
  }

  .Form__InputWithLabel--wrapper {
    > span {
      min-width: 30px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    z-index: 1;

    @media screen and (min-width: $media-breakpoint-lg) {
      > form > .row {
        min-height: calc(100vh - 220px);
      }
    }

    &--controls {
      background-color: white;
      z-index: 1;

      div[class*="col"]:first-of-type > .row {
        background-color: var(--badi-white);
        z-index: 1;
        padding: 30px 0;
        border-top: 1px solid var(--badi-light-grey);

        @media screen and (max-width: $media-breakpoint-md) {
          padding: 15px 0;
        }
      }
      @media screen and (max-width: $media-breakpoint-lg) {
        bottom: -1px;
        position: fixed;
        left: 0;
        width: 100%;
      }
    }
  }

  &__back {
    @media screen and (max-width: $media-breakpoint-sm) {
      display: none;
    }
  }

  &__fixed {
    z-index: 0;

    @media screen and (max-width: $media-breakpoint-lg) {
      position: relative !important;
    }
  }

  &__photos {
    .DropZone__Empty {
      min-height: 0;
      padding: 15px;
    }
  }

  &__example {
    font-size: 0.86rem;
    color: #bbb;
  }

  &__tip {
    position: relative;
    margin-top: 10px;
    padding: 8px 15px;
    font-size: 0.86rem;
    background-color: var(--badi-light-grey);
    border-radius: 4px;
    color: var(--badi-warm-grey);

    &--triangle {
      position: absolute;
      top: -10px;
      width: 0;
      height: 0;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;

      border-bottom: 14px solid var(--badi-light-grey);
    }
  }

  &__intro {
    &_image {
      position: absolute;
      bottom: 27px;

      @media screen and (max-width: $media-breakpoint-sm) {
        position: relative;
      }
    }

    h1,
    h2,
    p {
      margin: 0;
      padding: 12px 0;
      box-sizing: initial;

      @media screen and (max-width: $media-breakpoint-sm) {
        padding: 8px 0;
      }
    }
    h1 {
      font-size: 2.13rem;
      font-weight: bold;
    }
    h2 {
      font-size: 1.2rem;
      font-weight: bold;
      padding-top: 36px;

      @media screen and (max-width: $media-breakpoint-sm) {
        padding-top: 32px;
      }
    }
    p {
      font-size: 1rem;
    }
    button {
      margin: 12px 0 28px 0;

      @media screen and (max-width: $media-breakpoint-sm) {
        margin: 8px 0;
      }
    }

    @media screen and (max-width: $media-breakpoint-lg) {
      max-width: 572px;
      margin: 8px auto 0 auto;
    }
  }

  &__content {
    max-width: 370px;

    @media screen and (max-width: $media-breakpoint-lg) {
      max-width: initial;
    }
  }

  .MinimumStay {
    display: flex !important;
    justify-content: space-between;
  }
}
