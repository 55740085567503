@import "../../styles/baseExt.css";

.Onboarding__modal.popup__main {
  width: 530px;
  min-height: 510px;
}

.Onboarding__modal .popup__content {
  max-width: 100%;
  margin: 0;
}
