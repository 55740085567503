.SearchBudgetForm__Content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;

  > * {
    width: calc(50% - 8px);
  }

  > div + div {
    margin-top: 0 !important;
  }

  p {
    margin-bottom: 8px;
  }
}
