@import "../../../styles/baseExt.css";

.Form {
  &__TextArea {
    &--wrapper {
      box-sizing: border-box;
      margin-bottom: 16px;
      position: relative;

      textarea {
        box-sizing: border-box;
        padding: 10px 15px;
        font-size: 1rem;
        color: #bbb;
        width: 100%;
        border: 1px solid #bbb;
        border-radius: 4px;

        &:focus {
          color: var(--badi-dark-grey);
          border: 1px solid var(--badi-dark-grey);
        }
      }

      textarea.Form__TextArea--textarea-active {
        color: var(--badi-dark-grey);
        border: 1px solid var(--badi-dark-grey);
      }

      label {
        cursor: pointer;
      }
    }

    &--counter {
      position: absolute;
      bottom: -20px;
      right: 10px;

      text-align: right;
      color: #bbb;
      font-size: 0.866rem;
    }
  }
}
