.layer--full {
  position: fixed;
  left: 0;
  width: 100%;
}
.layer--bottom {
  bottom: 0;
}

.content--centered {
  position: relative;

  box-sizing: border-box;
  min-width: 320px;
  max-width: 1126px;
  margin: 0 auto;
  padding: 0 16px;
}
@media screen and (max-width: 480px) {
  .content--centered {
    padding-right: 8px;
    padding-left: 8px;
  }
}
.content--centered {
  display: flex;
  flex-direction: column;

  width: 100%;

  flex: 1 1 auto;
}
