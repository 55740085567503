.LeftArrow {
  z-index: 1;
  position: absolute;
  top: 50%;
  display: block;
  width: 40px;
  height: 100%;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  opacity: 0.9;
  background: url(assets/icons/arrow-left.svg) no-repeat center;
  content: "";
  left: 0;
  display: block;
}

.LeftArrow:hover {
  opacity: 1;
}
