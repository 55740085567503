@import "baseExt.css";
@import "fontsExt.css";
@import "headerExt.css";
@import "propertiesExt.css";

.layout__inner,
.post__inner {
  position: relative;

  box-sizing: border-box;
  min-width: 320px;
  max-width: 1126px;
  margin: 0 auto;
  padding: 0 16px;
}
@media screen and (max-width: 480px) {
  .layout__inner,
  .post__inner {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.form__network,
.layout__control,
.map__point {
  font-size: inherit;
  font-weight: inherit;

  margin: 0;
  padding: 0;

  cursor: pointer;

  border: none;
  outline: none;
  background: none;
}

body {
  display: flex;
  overflow: hidden;
  overflow-y: scroll;
  flex-direction: column;

  width: 100%;
  margin: 0;
  padding: 0;
}

.page_ready {
  overflow-y: scroll;

  min-height: 100%;
}

.page_open,
.popup_open,
.slide_open {
  overflow: hidden;
}

.popup_open > #root {
  overflow: hidden;
  max-height: 100vh;
}
h1 {
  margin: 0;
}
h1:first-child {
  margin-top: 0;
}
@media screen and (max-width: 999px) {
  h1 {
    font-size: 32px;
  }
}
@media screen and (max-width: 762px) {
  h1 {
    font-size: 23.42857px;
  }
}
@media screen and (max-width: 600px) {
  h1 {
    font-size: 14.01126px;
  }
}

h2,
.title_h2 {
  position: relative;
  margin: 40px 0;
}
h2:first-child,
.title_h2:first-child {
  margin-top: 0;
}

@media screen and (max-width: 600px) {
  h2,
  .title_h2 {
    margin: 20px 0;
  }
}

.title_h2_light {
  margin-bottom: 32px;
}
.title_h2_light:after {
  display: none;
}
@media screen and (max-width: 600px) {
  .title_h2_light {
    margin-bottom: 16px;
  }
}

h3,
.title_h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.25;

  margin: 20px 0;
}
h3:first-child,
.title_h3:first-child {
  margin-top: 0;
}

h4,
.title_h4 {
  font-weight: bold;

  margin: 12px 0;
}

a {
  text-decoration: none;

  color: inherit;
}
a:hover {
  text-decoration: underline;

  color: inherit;
}

.darklink {
  text-decoration: underline;

  color: #494949;
}
.darklink:hover {
  text-decoration: none;

  color: #494949;
}

p {
  margin: 12px 0;
}
p:first-child {
  margin-top: 0;
}
p:last-child {
  margin-bottom: 0;
}

img {
  border: 0;
}

img[align="left"] {
  float: left;

  margin: 0 60px 20px 0;
}
@media screen and (max-width: 999px) {
  img[align="left"] {
    margin-right: 20px;
  }
}

img[align="right"] {
  float: right;

  margin: 0 0 20px 60px;
}
@media screen and (max-width: 999px) {
  img[align="right"] {
    margin-left: 20px;
  }
}

hr {
  margin: 16px 0;

  border: none;
  border-bottom: 2px solid #e1e1e1;
}
hr:first-child {
  margin-top: 0;
}
hr:last-child {
  margin-bottom: 0;
}
hr.light {
  border-bottom: 1px solid #fff;
}

.hidden {
  display: none;
}

.displayFlex {
  display: flex;
}

.text_large {
  font-size: 22px;
  line-height: 1.15;
}

.text_center,
.text_center > h2 {
  text-align: center;
}

.text_left {
  text-align: left;
}

.text_right {
  text-align: right;
}

.text_body {
  color: #494949;
}

.text_primary {
  color: var(--badi-green);
}

.text_accent {
  color: #fec059;
}

.text_warning {
  color: var(--badi-error);
}

.text_alt {
  color: var(--badi-booking-blue);
}

.text_dark {
  color: #747474;
}

.text_success {
  color: var(--badi-green);
}

.text_secondary {
  color: #939393;
}

.text_hint {
  color: #b2b2b2;
}

.text_faint {
  color: #c3c3c3;
}

.text_big {
  font-size: 1rem;
}
@media screen and (max-width: 600px) {
  .text_big {
    font-size: 16px;
  }
}

.no_photo {
  display: block;

  width: 75px;
  height: 75px;
  margin: auto;
  padding: 0;

  cursor: pointer;

  border: 0;
  outline: none;
  background: url("assets/icons/ico_no_photo.svg") no-repeat 50% 50%;
}

@media screen and (max-width: 999px) {
  .text_desktop {
    display: none;
  }
}

.text_mobile {
  display: none;
}
@media screen and (max-width: 999px) {
  .text_mobile {
    display: inline;
  }
}

.text_nowrap {
  white-space: nowrap;
}

@media screen and (max-width: 999px) {
  .block_desktop {
    display: none;
  }
}

.block_mobile {
  display: none;
}
@media screen and (max-width: 999px) {
  .block_mobile {
    display: block;
  }
}

.button {
  font-size: 1rem;
  font-weight: 500;
  line-height: 38px;
  margin: 0;

  position: relative;

  display: inline-block;

  box-sizing: border-box;
  min-width: 50px;
  height: 38px;
  padding: 0 16px;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: 0.125s ease-out;
  transition: 0.125s ease-out;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;

  color: #fff;
  border: 0;
  border-radius: 4px;
  outline: none;
  background: var(--badi-green);
  text-shadow: none;
}
.button:hover {
  text-decoration: none;

  color: #fff;
  background: var(--badi-green);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.12);
}
.button:active {
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
}
.button_light {
  line-height: 34px;

  color: var(--badi-green);
  border: 2px solid var(--badi-green);
  background: none;
}
.button_light:hover {
  color: #06b86c;
  border-color: #06b86c;
  background: none;
  box-shadow: none;
}
.button_light:active {
  color: #d8d8d8;
  border-color: #d8d8d8;
  box-shadow: none;
}
.button_normal {
  color: #fff;
  background: var(--badi-green);
}
.button_normal:hover {
  color: #fff;
  background: #00cb73;
}
.button_accent {
  color: #fff;
  background: #fec059;
}
.button_accent:hover {
  color: #fff;
  background: #fec059;
}
.button_accent.button_light {
  color: #fec059;
  border-color: #fec059;
  background: none;
}
.button_accent.button_light:hover {
  color: #edaf48;
  border-color: #edaf48;
}
.button_accent.button_light:active {
  color: #d8d8d8;
  border-color: #d8d8d8;
  box-shadow: none;
}
.button_payment {
  color: #fff;
  background: var(--badi-booking-blue);
}
.button_payment:hover {
  color: #fff;
  background: var(--badi-booking-blue);
}
.button_payment.button_light {
  color: var(--badi-booking-blue);
  border-color: var(--badi-booking-blue);
  background: none;
}
.button_payment.button_light:hover {
  color: var(--badi-booking-blue);
  border-color: var(--badi-booking-blue);
}
.button_payment.button_light:active {
  color: #d8d8d8;
  border-color: #d8d8d8;
  box-shadow: none;
}

.button_warning {
  color: #fff;
  background: var(--badi-error);
}
.button_warning:hover {
  color: #fff;
  background: var(--badi-error);
}
.button_warning.button_light {
  color: var(--badi-error);
  border-color: var(--badi-error);
  background: none;
}
.button_warning.button_light:hover {
  color: #ee6374;
  border-color: #ee6374;
}
.button_warning.button_light:active {
  color: #d8d8d8;
  border-color: #d8d8d8;
  box-shadow: none;
}
.button_dark {
  color: #fff;
  background: #d9d9d9;
}
.button_dark:hover {
  color: #fff;
  background: #a6a6a6;
}
.button_dark.button_light {
  color: #747474;
  border-color: #d9d9d9;
  background: none;
}
.button_dark.button_light:hover {
  color: #747474;
  border-color: #a6a6a6;
}
.button_dark.button_light:active {
  color: #d8d8d8;
  border-color: #d8d8d8;
  box-shadow: none;
}
.button_white {
  color: #494949;
  background: #ffffff;
}
.button_white:hover {
  color: #494949;
  background: #ffffff;
}
.button_white.button_light {
  color: #ffffff;
  border-color: #ffffff;
  background: none;
}
.button_white.button_light:hover {
  color: #ffffff;
  border-color: #f8f8f8;
}
.button_white.button_light:active {
  color: #ffffff;
  border-color: #f8f8f8;
  box-shadow: none;
}
.button_white.button_wait {
  background: #d8d8d8;
}
.button_link {
  color: var(--badi-green);
  background: none;
}
.button_link:hover {
  color: #06b86c;
  background: none;
  box-shadow: none;
}
.button_link_dark {
  color: #b2b2b2;
  background: none;
}
.button_link_dark:hover {
  color: #494949;
  background: none;
  box-shadow: none;
}
.button_underline {
  height: 26px;
  line-height: 30px;
  background: none;
  min-width: auto;
  border-bottom: 1px solid #d9d9d9;
  border-radius: initial;
  padding: 0;
}
.button_text {
  font-weight: 700;
  border: 0;
  outline: none;
  cursor: pointer;
  font-size: 1.125em;
}
.button_bold {
  font-weight: 700;
}
.button_wide {
  width: 100%;
}
.button_long {
  min-width: 200px;
}
.button + .button,
.Button + .Button,
.text_text + .button,
.text_text + .Button {
  margin-left: 10px;
}
.text_text {
  font-weight: 500;
}
.form__fieldset .button + .button,
.form__fieldset .Button + .Button {
  margin-left: 16px;
}
.button_small {
  font-size: 14px;
  line-height: 28px;

  height: 28px;
}
.button_small.button_light {
  line-height: 24px;
}
.button_big {
  font-size: 20px;
  line-height: 48px;

  height: 48px;
}
.button_big.button_light {
  line-height: 44px;
}
.button_disabled,
.button:disabled {
  cursor: default;

  color: #fff;
  background: #d9d9d9;
  box-shadow: none;
}
.button_disabled:hover,
.button:disabled:hover {
  color: #fff;
  background: #d9d9d9;
  box-shadow: none;
}
.button_disabled.button_light,
.button_disabled.button_light:hover,
.button:disabled.button_light,
.button:disabled.button_light:hover {
  color: #d9d9d9;
  border-color: #d9d9d9;
  background: none;
  box-shadow: none;
}
.button_text.button_disabled,
.button_text:disabled,
.button_text.button_disabled:hover,
.button_text:disabled:hover {
  color: #d9d9d9;
  border: none;
  background: none;
  box-shadow: none;
}
.button_underline:disabled,
.button_underline:hover,
.button_underline:disabled:hover {
  color: #d9d9d9;
  border-color: #d9d9d9;
  background: none;
  box-shadow: none;
}
.button_round,
.button_circle {
  position: relative;

  width: 36px;
  min-width: 10px;
  height: 36px;
  padding: 0;

  text-align: center;

  border: 1px solid #f5f5f5;
  border-radius: 50%;
  background: #f5f5f5;
  box-shadow: none;
}
.button_round svg,
.button_round img,
.button_circle svg,
.button_circle img {
  position: absolute;
  top: 50%;
  left: 50%;

  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.button_round:hover,
.button_round.button_wait,
.button_round.button_active,
.button_circle:hover,
.button_circle.button_wait,
.button_circle.button_active {
  border-color: var(--badi-green);
  background: #fff;
  box-shadow: none;
}
.button_round:hover {
  border: none;
}
.button_round.button_bold,
.button_circle.button_bold {
  border-width: 2px;
}
.button_circle {
  width: 34px;
  height: 34px;

  border-color: #e1e1e1;
  background: #fff;
}
.button_circle:hover,
.button_circle.button_wait,
.button_circle.button_active {
  border-color: var(--badi-green);
}
.button_wait {
  color: transparent !important;
  box-shadow: none;
}
.button_wait:hover {
  box-shadow: none;
}
.button_wait:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: "";

  background: url("assets/icons/ico_wait_white.svg") no-repeat 50% 50%;
  background-size: auto 80%;
}
.button_wait.button_light:after,
.button_wait.button_round:after {
  background-image: url("assets/icons/ico_wait_white.svg");
}
.button_wait.button_underline,
.button_wait.button_underline:hover {
  border-color: transparent;
}
.button_wait.button_underline.button_blue:after {
  background-image: url("assets/icons/ico_wait_blue.svg");
}
.button_restrain,
.footer .button {
  min-width: 110px;
}
@media screen and (min-width: 1260px) {
  .button_restrain,
  .footer .button {
    min-width: 150px;
  }
}
.button_facebook,
.button_email,
.button_google {
  line-height: 32px;

  height: 36px;
  padding: 0 8px;

  border-radius: 4px;
}
.button_facebook {
  background: #3b5998;
}
.button_facebook:hover {
  background: #2a4887;
}
.button_facebook:active {
  box-shadow: inset 0 2px 0 #3b5998;
}
.button_email {
  background: #ffffff !important;
}
.button_email:hover {
  background: #f8f8f8;
}
.button_google {
  background: #ffffff !important;
}
.button_google:hover {
  background: #f8f8f8;
}
.button_facebook .button__ico,
.button_facebook img,
.button_email .button__ico,
.button_email img,
.button_google img,
.button_google .button__ico {
  float: left;
  margin-left: 8px;
  margin-top: 6px;
}
.button_facebook_big,
.button_email_big,
.button_google_big {
  line-height: 42px;

  height: 46px;
}
.button_facebook_big .button__ico,
.button_facebook_big img,
.button_email_big .button__ico,
.button_google_big .button__ico {
  margin-top: 13px;
}
.button_email_big img {
  margin-top: 8px;
}

@media screen and (max-width: 762px) {
  .button_mobile_link {
    width: auto;

    border: none;
    background: none;
  }
}
@media screen and (max-width: 480px) {
  .button {
    font-size: 14px;
  }
  .button_big {
    line-height: 38px;

    height: 38px;
  }
  .button_big.button_light {
    line-height: 34px;
  }
  .footer .button {
    min-width: 100px;
  }
  .button_wide {
    padding: 0 1px;
  }
}
.browser_ff .button {
  font-family: Arial, sans-serif;
}
.form__input .button {
  white-space: nowrap;
}

.button_icon {
  width: 24px;
  height: 24px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.button__ico {
  display: inline-block;

  width: 19px;
  height: 19px;
  max-height: 19px;

  -webkit-transition: 0.125s ease-out;
  transition: 0.125s ease-out;
  vertical-align: middle;

  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.button__ico path {
  fill: #fff;
}
.button_light .button__ico path {
  fill: var(--badi-green);
}
.button_accent.button_light .button__ico path {
  fill: #fec059;
}
.button_warning.button_light .button__ico path {
  fill: var(--badi-error);
}
.button_light.button_disabled .button__ico path,
.button_light.button:disabled .button__ico path {
  fill: #d9d9d9;
}
.button_round .button__ico path {
  fill: #b2b2b2;
  stroke: #b2b2b2;
}
.button_round:hover .button__ico path,
.button_active .button__ico path {
  fill: var(--badi-green);
  stroke: var(--badi-green);
}
.button_bold .button__ico {
  width: 18px;
  height: 18px;
}
.button_big .button__ico {
  width: 24px;
  height: 24px;
  max-height: 24px;
}
.button_small .button__ico {
  width: 14px;
  height: 14px;
  max-height: 14px;
}
.button_wait .button__ico {
  opacity: 0;
}
.button_refresh .button__ico {
  -webkit-animation: refresh 2s linear infinite;
  animation: refresh 2s linear infinite;
}
@media screen and (max-width: 762px) {
  .button_mobile_link .button__ico {
    display: none;
  }
}

@-webkit-keyframes refresh {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes refresh {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.button__text {
  display: inline-block;

  margin: 0 auto;

  vertical-align: middle;
}
.button__ico + .button__text {
  margin: 0 0 0 12px;
}

.button_facebook .button__ico + .button__text,
.button_google .button__ico + .button__text,
.button_email .button__ico + .button__text {
  margin: 0 0 0 -12px;
}

.button__hint {
  font-size: 10px;
  line-height: 1.15;

  position: absolute;
  bottom: 100%;
  left: 50%;

  margin: 0 0 5px;
  padding: 2px 4px;

  -webkit-transition: 0.25s 0.1s ease-out;
  transition: 0.25s 0.1s ease-out;
  -webkit-transform: translate(-50%, -6px);
  -ms-transform: translate(-50%, -6px);
  transform: translate(-50%, -6px);

  opacity: 0;
  color: #b2b2b2;
  border-radius: 3px;
  background: #fff;
}
.button__hint:after {
  position: absolute;
  top: 100%;
  left: 50%;

  width: 0;
  height: 0;
  margin: 0 0 0 -3px;

  content: "";

  border-top: 3px solid #fff;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.button:hover .button__hint {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);

  opacity: 1;
}
@media screen and (max-width: 762px) {
  .button_mobile_link .button__hint {
    font-size: 14px;
    line-height: inherit;

    position: relative;
    bottom: auto;
    left: auto;

    margin: 0;
    padding: 0;

    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    text-decoration: underline;

    opacity: 1 !important;
    color: #494949;
    background: none;
  }
  .button_mobile_link .button__hint:after {
    display: none;
  }
}

.button_green {
  color: var(--badi-green);
  border-color: var(--badi-green);
}
.button_green:hover {
  color: #06b86c;
  border-color: var(--badi-green);
}
.button_blue {
  color: var(--badi-booking-blue);
  border-color: var(--badi-booking-blue);
}
.button_blue:hover {
  color: var(--badi-booking-blue);
  border-color: var(--badi-booking-blue);
}
.button_gray {
  color: #b2b2b2;
  border-color: #b2b2b2;
}
.button_gray:hover {
  color: #b2b2b2;
  border-color: #b2b2b2;
}

.form {
  position: relative;
}

.form__fieldset {
  position: relative;

  margin: 24px 0;
}
.form__fieldset:first-child {
  margin-top: 0;
}
.form__fieldset:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 600px) {
  .form__fieldset {
    margin: 16px 0;
  }
}

.form__set {
  margin: 32px 0;
}
.form__set:first-child {
  margin-top: 0;
}
.form__set:last-child {
  margin-bottom: 0;
}
.form__set .human {
  margin-right: 16px;
}

.form__legend {
  font-size: 16px;
  font-weight: 700;

  display: table;

  margin: 0 0 16px;
}

.form__half,
.form__third {
  display: table;

  width: 100%;
}
.form__title + .form__half,
.form__description + .form__half,
.form__title + .form__third,
.form__title + .form__text {
  margin-top: 16px;
}
@media screen and (max-width: 762px) {
  .form__half,
  .form__third {
    display: block;
  }
}

.form__list {
  -webkit-columns: 3 200px;
  -moz-columns: 3 200px;
  columns: 3 200px;
  -webkit-column-gap: 16px;
  -moz-column-gap: 16px;
  column-gap: 16px;
}
.form__list_thin {
  -webkit-columns: 2 150px;
  -moz-columns: 2 150px;
  columns: 2 150px;
}
.form__list_vertical {
  -webkit-columns: 1 150px;
  -moz-columns: 1 150px;
  columns: 1 150px;
}

.form__row {
  position: relative;

  margin: 12px 0;
}
.form_light .form__row {
  margin: 16px 0;
}
@media screen and (max-width: 600px) {
  .form_light .form__row {
    margin: 8px 0;
  }
}
.form__row_half {
  width: 47.5%;
}
.form_signup .form__row_half {
  width: 46%;
}
@media screen and (max-width: 600px) {
  .form__row_half {
    width: auto;
  }
}
.form__row_third {
  width: 30%;
}
@media screen and (max-width: 600px) {
  .form__row_third {
    width: auto;
  }
}
.form__row:first-child {
  margin-top: 0;
}
.form__row:last-child {
  margin-bottom: 0;
}
.form__row_submit {
  margin-top: 50px;

  text-align: center;
}
.form_light .form__row_submit {
  margin-top: 40px;
}

.form__element {
  position: relative;

  display: block;

  max-width: 460px;
  margin-bottom: 20px;
}
.form__element_empty {
  visibility: hidden;
}
.form__element_wide {
  max-width: 100%;
}
.form__half .form__element,
.form__third .form__element {
  display: table-cell;

  padding: 0 16px;

  vertical-align: bottom;
}
.form__half .form__element:first-child,
.form__third .form__element:first-child {
  padding-left: 0;
}
.form__half .form__element:last-child,
.form__third .form__element:last-child {
  padding-right: 0;
}
.form__half_top .form__element {
  vertical-align: top;
}
.form__half_middle .form__element {
  vertical-align: middle;
}
.form__half .form__element {
  width: 48.5%;
  max-width: 350px;
}
.form__third .form__element {
  width: 31.3%;
}
.form__element_complex {
  padding-left: 130px !important;
}
@media screen and (max-width: 999px) {
  .form__half .form__element {
    max-width: 100%;
  }
}
@media screen and (max-width: 762px) {
  .form__half .form__element,
  .form__third .form__element {
    display: block;

    width: auto;
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  .form__element + .form__element {
    margin: 32px 0 0;
  }
  .form__element + .form__element:first-child {
    margin-top: 0;
  }
  .form__element + .form__element:last-child {
    margin-bottom: 0;
  }
  .form__element.text_right {
    text-align: left;
  }
}
@media screen and (max-width: 480px) {
  .form__element_complex {
    padding-left: 0 !important;
  }
  .form__element + .form__element {
    margin: 16px 0 0;
  }
  .form__element + .form__element:first-child {
    margin-top: 0;
  }
  .form__element + .form__element:last-child {
    margin-bottom: 0;
  }
  .form__element_error,
  .form__element + .form__element_error {
    margin-top: 32px;
  }
}

.form__string {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  min-height: 42px;

  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.form__title + .form__string {
  margin-top: 25px;
}
.form__string + .form__string {
  margin-top: 16px;
}
.form__string_ext {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;

  margin-top: 0 !important;
}
@media screen and (max-width: 762px) {
  .form__string_months {
    display: block;
  }
  .form__string_ext {
    position: relative;
    top: auto;
    left: auto;

    margin-top: 16px !important;
  }
  .form__string_or {
    position: absolute;
    top: 0;
    left: 330px;

    margin-top: 0 !important;
  }
  .form__string_mobile_center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form__string_fablet_start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}
.form__string_or {
  margin-left: 8px;
}
@media screen and (max-width: 600px) {
  .form__string_mobile_fall {
    display: block;
  }
  .form__string_or {
    top: 24px;
    left: 210px;
  }
}
@media screen and (max-width: 480px) {
  .form__string_or {
    left: 165px;
  }
}

.form__title,
.form__description {
  display: table;

  width: 100%;
  height: 25px;
  margin: 0 0 5px;
}
.form__title:after {
  display: none;
}
.form__title_beside {
  width: auto;
}
.form__string .form__title {
  line-height: 1.25;

  display: inline;

  width: 134px;
  height: auto;
  margin: 0 4px 0 0;

  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.form__string .form__title_short {
  width: auto;
  max-width: 134px;
}
.form__title .switcher__view {
  display: table-cell;
}
.form__title_vertical {
  display: block;

  height: auto;
}
.form__element_included .form__title {
  max-width: 190px;
}
@media screen and (max-width: 762px) {
  .form__string_months .form__title {
    display: block;

    width: auto;
    margin-bottom: 8px;
  }
}

.form__name,
.form__info,
.form__ico {
  display: table-cell;

  vertical-align: bottom;
}

.form__ico + .form__name,
.form__ico + .form__name {
  padding-left: 16px;

  vertical-align: middle;
}

.form__name {
  padding-right: 16px;
}

.form__info {
  font-weight: 400;
  text-align: right;
  color: var(--badi-warm-grey);
}

.form__label,
.form__error {
  font-size: 0.8rem;
  font-weight: 500;

  position: absolute;
  top: 0;

  overflow: hidden;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: 0.125s ease-out;
  transition: 0.125s ease-out;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: var(--badi-warm-grey);
}
.form__string .form__label,
.form__input_unlabled .form__label,
.form__string .form__error,
.form__input_unlabled .form__error {
  top: -20px;
}

.form__label {
  left: 0;
}
.form__field ~ .form__label {
  top: 32px;
  right: 22px;
  left: 22px;
}
.form__element_big .form__field ~ .form__label {
  top: 37px;
}
@media screen and (max-width: 999px) {
  .form__field ~ .form__label {
    right: 12px;
    left: 12px;
  }
}
.form__element_fill .form__label,
.form__field:focus ~ .form__label {
  top: 0 !important;
  left: 0 !important;
}

.form__error {
  right: 0;
  line-height: 1.2;
  top: -18px;

  opacity: 0;
  color: var(--badi-error);
}
.form__error_active,
.form__field_error ~ .form__error,
.form__select_error ~ .form__error,
.form__textarea_error ~ .form__error,
.form__element_error .form__error {
  opacity: 1;
}
.form__list > .form__error {
  margin-right: 32px;
}
.form__fieldset > .form__error {
  top: auto;
  bottom: -10px;
}
.form__fieldset > .form__error.form__error_photo {
  bottom: -30px;
  width: 100%;
  white-space: normal;
}
.form__error_prelist {
  position: static;
  right: auto;
  bottom: auto;

  display: block;
}

.form__counter {
  font-size: 11px;
  font-weight: 400;

  display: block;

  margin: 4px 0 0;

  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: #b2b2b2;
}
.form__counter_limit {
  color: var(--badi-error);
}

.form__act {
  display: block;
  margin-top: 8px;
  color: var(--badi-dark-grey);

  @media screen and (min-width: $media-breakpoint-sm) {
    margin-top: 0;
    color: #b2b2b2;
    position: absolute;
    top: 14px;
    right: 16px;
  }
}

.form__act:hover {
  color: var(--badi-blue-sky);
}

.form__input {
  position: relative;

  display: block;
}
.form__input_unlabled {
  padding-top: 0;
}
.form__string .form__input {
  display: inline;

  padding-top: 0;

  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.form__date .form__input {
  padding-top: 0;
}
.form__date .form__input + .form__input {
  margin-left: 5px;
}
.form__input_country {
  float: left;

  width: 120px;
  margin-left: -130px;
}
.form__input_short {
  margin-right: auto;

  -webkit-box-flex: 0 !important;
  -ms-flex: 0 0 auto !important;
  flex: 0 0 auto !important;
}
.form__element_included .form__input {
  margin-right: 0;
}
@media screen and (max-width: 762px) {
  .form__string .form__input_ext {
    display: block;

    width: 180px;

    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}
@media screen and (max-width: 600px) {
  .form__string .form__input {
    display: block;

    margin-top: 8px;
    padding-top: 0;
  }
  .form__string .form__input_ext {
    width: 180px;
  }
  .form__element_included .form__input {
    text-align: center;
  }
  .form__element_included .form__input .switcher {
    margin-right: 16px;
    margin-left: 16px;
  }
  .form__element_included .form__input .switcher__text {
    min-width: 90px;
  }
}
@media screen and (max-width: 480px) {
  .form__string .form__input_ext {
    width: 146px;
  }
  .form__input_country {
    float: none;

    width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }
}

.form__field,
.form__select,
.form__textarea {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;

  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 9px 20px 9px;

  transition: 0.125s linear;

  color: var(--badi-dark-grey);
  border: 2px solid var(--badi-light-grey);
  border-radius: 4px;
  outline: none;
  background: #fff;

  appearance: none;
}
.form__element_big .form__field,
.form__element_big .form__select,
.form__element_big .form__textarea {
  padding-top: 14px;
  padding-bottom: 14px;
}
.form__field_error,
.form__element_error .form__field,
.form__select_error,
.form__element_error .form__select,
.form__textarea_error,
.form__element_error .form__textarea {
  border-color: var(--badi-error);
}
.form__field:focus,
.form__select:focus,
.form__textarea:focus {
  border-color: var(--badi-green);
}

.form__field--blue-sky:focus {
  border-color: var(--badi-blue-sky);
}

.form__field:disabled,
.form__select:disabled,
.form__textarea:disabled {
  cursor: default;

  border: 2px solid #f2f2f2;
}
@media screen and (max-width: 999px) {
  .form__field,
  .form__select,
  .form__textarea {
    padding-right: 10px;
    padding-left: 10px;
  }
}
@media screen and (max-width: 600px) {
  .form__field,
  .form__select,
  .form__textarea {
    font-size: 0.86rem;

    padding-top: 11px;
    padding-bottom: 10px;
  }
}
.form__input_ext .form__field,
.form__input_ext .form__select,
.form__input_ext .form__textarea {
  padding-right: 60px;
}

@media screen and (max-width: 480px) {
  .form__input_ext .form__field,
  .form__input_ext .form__select,
  .form__input_ext .form__textarea {
    padding-right: 36px;
  }
}

.form__field::-webkit-contacts-auto-fill-button {
  position: absolute;
  right: 0;

  display: none !important;
  visibility: hidden;

  pointer-events: none;
}

.form__textarea {
  font-size: 14px;
  line-height: inherit;

  display: block;
  overflow: hidden;

  height: 160px;
  max-height: 300px;
  padding-top: 16px;
  padding-bottom: 16px;

  resize: vertical;
}
.form__textarea_big {
  height: 260px;
}

.form__select {
  height: 42px;
  padding-top: 0;
  padding-bottom: 0;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form__select::-ms-expand {
  display: none;
}

.form__input_select {
  position: relative;
}
.form__input_select:after {
  position: absolute;
  top: 23px;
  right: 2px;

  width: 40px;
  height: 38px;

  content: "";
  pointer-events: none;

  background: url("assets/icons/ico_down_gray.svg") no-repeat 50% 50%;
}
.form__input_select.form__input_unlabled:after,
.form__string .form__input_select:after,
.form__date .form__input_select:after {
  top: 2px;
}

.form__symbol {
  font-size: 16px;
  font-weight: 400;
  line-height: 38px;

  position: absolute;
  top: 21px;
  right: 0;

  box-sizing: border-box;
  width: 40px;
  height: 38px;
  margin: 1px 0 0;

  -webkit-transition:
    border-color 0.125s linear,
    color 0.125s linear;
  transition:
    border-color 0.125s linear,
    color 0.125s linear;
  text-align: center;

  color: #b2b2b2;
  border-left: 1px solid #e1e1e1;
}
.form__field:focus + .form__symbol {
  border-color: var(--badi-green);
}
.form__string .form__symbol,
.form__input_unlabled .form__symbol {
  top: 1px;
}
.form__symbol_calendar {
  border-left: 0;
  background: url("assets/icons/ico_calendar.svg") no-repeat 50% 50%;
}
.form__symbol_search {
  border-left: 0;
  background: url("assets/icons/ico_search.svg") no-repeat 50% 50%;
}

.form__hidden {
  position: absolute;
  left: -5000px;
}

.form__note {
  font-size: 14px;

  color: #b2b2b2;
}
.form__note a {
  color: #939393;
}

.form__dsc {
  font-size: 0.86rem;

  color: #b2b2b2;
}

.form__map {
  position: relative;

  height: 280px;
}
.form__map iframe {
  width: 100%;
  height: 100%;
  margin: 0;

  vertical-align: bottom;
}
@media screen and (max-width: 762px) {
  .form__map {
    margin-right: -16px;
    margin-left: -16px;
  }
}

.form__address {
  position: absolute;
  z-index: 10;
  top: 48px;
  left: 48px;

  padding: 24px;

  text-align: center;

  border-radius: 4px;
  background: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}
.form__address .form__title {
  max-width: 170px;

  text-align: left;
}
@media screen and (max-width: 999px) {
  .form__address {
    position: relative;
    top: auto;
    left: auto;

    margin: 24px 0 0;
    padding: 0;

    border-radius: 0;
    box-shadow: none;
  }
  .form__address .form__title {
    max-width: 100%;

    text-align: center;
  }
}

.form__actions {
  margin: 16px 0;
}
.form__actions:first-child {
  margin-top: 0;
}
.form__actions:last-child {
  margin-bottom: 0;
}

.form__step {
  float: right;
  margin: 12px 6px;
  color: #b2b2b2;
}

.form__separator {
  position: relative;

  margin: 32px 0;

  text-align: center;

  border-bottom: 1px solid #e1e1e1;
}
.form__separator:first-child {
  margin-top: 0;
}
.form__separator:last-child {
  margin-bottom: 0;
}
.form__separator_big {
  border-width: 2px;
}
@media screen and (max-width: 600px) {
  .form__separator {
    margin: 16px 0;
  }
}

.form__separator_transparent {
  border-bottom: 1px solid transparent;
}

.form__septext {
  font-size: 14px;
  line-height: 1;

  position: absolute;
  top: -8px;
  left: 50%;

  box-sizing: content-box;
  padding: 0 16px;

  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);

  color: #939393;
  background: #fff;
}

.form__attachment {
  text-align: center;
}

.form__row .form__text {
  display: inline-block;

  vertical-align: middle;
}

.form__true {
  vertical-align: middle;
}
.form__true,
.form__true_left {
  font-size: 0.86rem;
  font-weight: 500;
  display: inline-block;
}
.form__true:after {
  display: inline-block;

  width: 17px;
  height: 12px;
  margin: 0 0 0 8px;

  content: "";

  background: url("assets/icons/icon-check.svg") no-repeat 50% 50%;
}
.form__true_left:before {
  display: inline-block;

  width: 17px;
  height: 12px;
  margin: 0 8px 0 0;

  content: "";

  background: url("assets/icons/icon-check.svg") no-repeat 50% 50%;
}

.form__date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px 0 0;
}

.form__switchers_error {
  position: relative;

  display: inline-block;
}

.form__share {
  text-align: right;
}
@media screen and (max-width: 762px) {
  .form__share {
    text-align: left;
  }
}

.form__network {
  display: inline-block;
}

.form__soclogo {
  width: 45px;
  height: 45px;
  margin: 0 0 0 8px;
}
@media screen and (min-width: 1260px) {
  .form__soclogo {
    margin-left: 16px;
  }
}
@media screen and (max-width: 999px) {
  .form__soclogo {
    margin: 0 8px 0 0;
  }
}

.switcher {
  font-size: 16px;

  position: relative;

  display: inline-block;

  margin-right: 32px;

  text-align: left;
  white-space: nowrap;
}
.switcher_button {
  margin-right: 5px;
}
.switcher_button:last-of-type {
  margin-right: 0;
}
.form__table {
  display: table;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 4px 0px;
  padding: 5px 0 0;
  width: 100%;
}
.form__table .switcher {
  display: table-cell;
  text-align: center;
}
.form__list .switcher {
  display: block;
}
.form__list_vertical .switcher {
  margin-top: 8px;
}

.switcher__state {
  position: absolute;
  left: -9999px;
}

.switcher__text {
  font-size: 0.86rem;
  font-weight: 500;
  line-height: 42px;
  box-sizing: border-box;

  position: relative;

  display: inline-flex;

  min-height: 42px;
  padding-left: 36px;

  cursor: pointer;
  user-select: none;
  transition: 0.25s ease-out;
  vertical-align: middle;

  align-items: center;
}
.switcher__text:before {
  box-sizing: border-box;
  position: absolute;
  top: 14px;
  left: 0;

  width: 16px;
  height: 16px;

  content: "";
  transition: 0.125s ease-out;

  border: 1px solid var(--badi-dark-grey);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 80% 80%;
}
.switcher_button .switcher__text {
  min-width: 46px;
  padding: 0 10px;
  height: 45px;

  text-align: center;
  transition: none;

  box-sizing: border-box;
  border: 1px solid var(--badi-dark-grey);
  border-radius: 40px;

  display: flex;
  flex-direction: column;
}
.switcher_button .switcher__text:before {
  display: none;
}

.switcher_big_button .switcher__text {
  width: 100%;
  padding: initial;

  border: 2px solid #e1e1e1;
  border-radius: 5px;
}
.switcher__view + .switcher__text {
  padding-left: 0;
}
.switcher__view + .switcher__text:before {
  display: none;
}
.switcher_active .switcher__text {
  cursor: default;

  color: var(--badi-dark-grey);
}
.switcher__state:checked ~ .switcher__text {
  font-weight: 500;
}
.switcher__state:checked ~ .switcher__text:before {
  box-sizing: border-box;
  border: solid 2px var(--badi-green);
  background-color: var(--badi-green);
  background-image: url("assets/icons/ico_check_white.svg");
}
.switcher_button .switcher__state:checked ~ .switcher__text {
  box-sizing: border-box;
  border: solid 2px var(--badi-green);
  height: 46px;
}
.switcher__state:disabled ~ .switcher__text {
  cursor: default;

  color: #b2b2b2;
  border-color: #e1e1e1;
}
.switcher__state:disabled ~ .switcher__text:before {
  border-color: #e1e1e1;
}

.switcher__view {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;

  width: 30px;
  height: 30px;
  margin: 6px 16px 6px 0;

  text-align: center;
  vertical-align: middle;

  border: 2px solid #f0f0f0;
  border-radius: 50px;
  background: #f0f0f0 url("assets/icons/map_options.svg") no-repeat 100px 100px;

  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.switcher__view_couples {
  background-position: 0 0;
}
.switcher__view_smoker {
  background-position: 0 -120px;
}
.switcher__view_pet {
  background-position: 0 -240px;
}
.switcher__view_wifi {
  background-position: 0 -360px;
}
.switcher__view_tv {
  background-position: 0 -480px;
}
.switcher__view_heating {
  background-position: 0 -600px;
}
.switcher__view_air {
  background-position: 0 -720px;
}
.switcher__view_dishwasher {
  background-position: 0 -840px;
}
.switcher__view_dryer {
  background-position: 0 -960px;
}
.switcher__view_washing {
  background-position: 0 -1080px;
}
.switcher__view_elevator {
  background-position: 0 -1200px;
}
.switcher__view_parking {
  background-position: 0 -1320px;
}
.switcher__view_wheelchair {
  background-position: 0 -1440px;
}
.switcher__view_doorman {
  background-position: 0 -1560px;
}
.switcher__view_pool {
  background-position: 0 -1680px;
}
.switcher__view_male {
  background-position: 0 -1800px;
}
.switcher__view_female {
  background-position: 0 -1920px;
}
.switcher__view_study {
  background-position: 0 -2040px;
}
.switcher__view_studywork {
  background-position: 0 -2160px;
}
.switcher__view_work {
  background-position: 0 -2280px;
}
.switcher__view_cocktail {
  background-position: 0 -2400px;
}
.switcher__view_books {
  background-position: 0 -2520px;
}
.switcher__view_ball {
  background-position: 0 -2640px;
}
.switcher__view_game {
  background-position: 0 -2760px;
}
.switcher__view_chat {
  background-position: 0 -2880px;
}
.switcher__view_flash {
  background-position: 0 -3000px;
}
.switcher__view_smile {
  background-position: 0 -3120px;
}
.switcher__view_camera {
  background-position: 0 -3240px;
}
.switcher__view_point {
  background-position: 0 -3360px;
}
.switcher__view_down {
  background-position: 0 -3480px;
}
.switcher__view_bathroom {
  background-position: 0 -3600px;
}
.switcher__view_balcony {
  background-position: 0 -3840px;
}
.switcher__view_window {
  background-position: 0 -3720px;
}
.switcher__view_bed {
  background-position: 0 -3960px;
}
.switcher__state:checked ~ .switcher__view,
.switcher_active .switcher__view,
.form__string_active .switcher__view,
.person__parameter .switcher__view,
.info__parameter .switcher__view,
.person__skills .switcher__view,
.button_active .switcher__view,
.level__item_active .switcher__view {
  background-color: #fff;
}
.switcher__state:checked ~ .switcher__view_couples,
.switcher_active .switcher__view_couples,
.form__string_active .switcher__view_couples,
.person__parameter .switcher__view_couples,
.info__parameter .switcher__view_couples,
.person__skills .switcher__view_couples,
.button_active .switcher__view_couples,
.level__item_active .switcher__view_couples {
  background-position: 0 -60px;
}
.switcher__state:checked ~ .switcher__view_smoker,
.switcher_active .switcher__view_smoker,
.form__string_active .switcher__view_smoker,
.person__parameter .switcher__view_smoker,
.info__parameter .switcher__view_smoker,
.person__skills .switcher__view_smoker,
.button_active .switcher__view_smoker,
.level__item_active .switcher__view_smoker {
  background-position: 0 -180px;
}
.switcher__state:checked ~ .switcher__view_pet,
.switcher_active .switcher__view_pet,
.form__string_active .switcher__view_pet,
.person__parameter .switcher__view_pet,
.info__parameter .switcher__view_pet,
.person__skills .switcher__view_pet,
.button_active .switcher__view_pet,
.level__item_active .switcher__view_pet {
  background-position: 0 -300px;
}
.switcher__state:checked ~ .switcher__view_wifi,
.switcher_active .switcher__view_wifi,
.form__string_active .switcher__view_wifi,
.person__parameter .switcher__view_wifi,
.info__parameter .switcher__view_wifi,
.person__skills .switcher__view_wifi,
.button_active .switcher__view_wifi,
.level__item_active .switcher__view_wifi {
  background-position: 0 -420px;
}
.switcher__state:checked ~ .switcher__view_tv,
.switcher_active .switcher__view_tv,
.form__string_active .switcher__view_tv,
.person__parameter .switcher__view_tv,
.info__parameter .switcher__view_tv,
.person__skills .switcher__view_tv,
.button_active .switcher__view_tv,
.level__item_active .switcher__view_tv {
  background-position: 0 -540px;
}
.switcher__state:checked ~ .switcher__view_heating,
.switcher_active .switcher__view_heating,
.form__string_active .switcher__view_heating,
.person__parameter .switcher__view_heating,
.info__parameter .switcher__view_heating,
.person__skills .switcher__view_heating,
.button_active .switcher__view_heating,
.level__item_active .switcher__view_heating {
  background-position: 0 -660px;
}
.switcher__state:checked ~ .switcher__view_air,
.switcher_active .switcher__view_air,
.form__string_active .switcher__view_air,
.person__parameter .switcher__view_air,
.info__parameter .switcher__view_air,
.person__skills .switcher__view_air,
.button_active .switcher__view_air,
.level__item_active .switcher__view_air {
  background-position: 0 -780px;
}
.switcher__state:checked ~ .switcher__view_dishwasher,
.switcher_active .switcher__view_dishwasher,
.form__string_active .switcher__view_dishwasher,
.person__parameter .switcher__view_dishwasher,
.info__parameter .switcher__view_dishwasher,
.person__skills .switcher__view_dishwasher,
.button_active .switcher__view_dishwasher,
.level__item_active .switcher__view_dishwasher {
  background-position: 0 -900px;
}
.switcher__state:checked ~ .switcher__view_dryer,
.switcher_active .switcher__view_dryer,
.form__string_active .switcher__view_dryer,
.person__parameter .switcher__view_dryer,
.info__parameter .switcher__view_dryer,
.person__skills .switcher__view_dryer,
.button_active .switcher__view_dryer,
.level__item_active .switcher__view_dryer {
  background-position: 0 -1020px;
}
.switcher__state:checked ~ .switcher__view_washing,
.switcher_active .switcher__view_washing,
.form__string_active .switcher__view_washing,
.person__parameter .switcher__view_washing,
.info__parameter .switcher__view_washing,
.person__skills .switcher__view_washing,
.button_active .switcher__view_washing,
.level__item_active .switcher__view_washing {
  background-position: 0 -1140px;
}
.switcher__state:checked ~ .switcher__view_elevator,
.switcher_active .switcher__view_elevator,
.form__string_active .switcher__view_elevator,
.person__parameter .switcher__view_elevator,
.info__parameter .switcher__view_elevator,
.person__skills .switcher__view_elevator,
.button_active .switcher__view_elevator,
.level__item_active .switcher__view_elevator {
  background-position: 0 -1260px;
}
.switcher__state:checked ~ .switcher__view_parking,
.switcher_active .switcher__view_parking,
.form__string_active .switcher__view_parking,
.person__parameter .switcher__view_parking,
.info__parameter .switcher__view_parking,
.person__skills .switcher__view_parking,
.button_active .switcher__view_parking,
.level__item_active .switcher__view_parking {
  background-position: 0 -1380px;
}
.switcher__state:checked ~ .switcher__view_wheelchair,
.switcher_active .switcher__view_wheelchair,
.form__string_active .switcher__view_wheelchair,
.person__parameter .switcher__view_wheelchair,
.info__parameter .switcher__view_wheelchair,
.person__skills .switcher__view_wheelchair,
.button_active .switcher__view_wheelchair,
.level__item_active .switcher__view_wheelchair {
  background-position: 0 -1500px;
}
.switcher__state:checked ~ .switcher__view_doorman,
.switcher_active .switcher__view_doorman,
.form__string_active .switcher__view_doorman,
.person__parameter .switcher__view_doorman,
.info__parameter .switcher__view_doorman,
.person__skills .switcher__view_doorman,
.button_active .switcher__view_doorman,
.level__item_active .switcher__view_doorman {
  background-position: 0 -1620px;
}
.switcher__state:checked ~ .switcher__view_pool,
.switcher_active .switcher__view_pool,
.form__string_active .switcher__view_pool,
.person__parameter .switcher__view_pool,
.info__parameter .switcher__view_pool,
.person__skills .switcher__view_pool,
.button_active .switcher__view_pool,
.level__item_active .switcher__view_pool {
  background-position: 0 -1740px;
}
.switcher__state:checked ~ .switcher__view_male,
.switcher_active .switcher__view_male,
.form__string_active .switcher__view_male,
.person__parameter .switcher__view_male,
.info__parameter .switcher__view_male,
.person__skills .switcher__view_male,
.button_active .switcher__view_male,
.level__item_active .switcher__view_male {
  background-position: 0 -1860px;
}
.switcher__state:checked ~ .switcher__view_female,
.switcher_active .switcher__view_female,
.form__string_active .switcher__view_female,
.person__parameter .switcher__view_female,
.info__parameter .switcher__view_female,
.person__skills .switcher__view_female,
.button_active .switcher__view_female,
.level__item_active .switcher__view_female {
  background-position: 0 -1980px;
}
.switcher__state:checked ~ .switcher__view_study,
.switcher_active .switcher__view_study,
.form__string_active .switcher__view_study,
.person__parameter .switcher__view_study,
.info__parameter .switcher__view_study,
.person__skills .switcher__view_study,
.button_active .switcher__view_study,
.level__item_active .switcher__view_study {
  background-position: 0 -2100px;
}
.switcher__state:checked ~ .switcher__view_studywork,
.switcher_active .switcher__view_studywork,
.form__string_active .switcher__view_studywork,
.person__parameter .switcher__view_studywork,
.info__parameter .switcher__view_studywork,
.person__skills .switcher__view_studywork,
.button_active .switcher__view_studywork,
.level__item_active .switcher__view_studywork {
  background-position: 0 -2220px;
}
.switcher__state:checked ~ .switcher__view_work,
.switcher_active .switcher__view_work,
.form__string_active .switcher__view_work,
.person__parameter .switcher__view_work,
.info__parameter .switcher__view_work,
.person__skills .switcher__view_work,
.button_active .switcher__view_work,
.level__item_active .switcher__view_work {
  background-position: 0 -2340px;
}
.switcher__state:checked ~ .switcher__view_cocktail,
.switcher_active .switcher__view_cocktail,
.form__string_active .switcher__view_cocktail,
.person__parameter .switcher__view_cocktail,
.info__parameter .switcher__view_cocktail,
.person__skills .switcher__view_cocktail,
.button_active .switcher__view_cocktail,
.level__item_active .switcher__view_cocktail {
  background-position: 0 -2460px;
}
.switcher__state:checked ~ .switcher__view_books,
.switcher_active .switcher__view_books,
.form__string_active .switcher__view_books,
.person__parameter .switcher__view_books,
.info__parameter .switcher__view_books,
.person__skills .switcher__view_books,
.button_active .switcher__view_books,
.level__item_active .switcher__view_books {
  background-position: 0 -2580px;
}
.switcher__state:checked ~ .switcher__view_ball,
.switcher_active .switcher__view_ball,
.form__string_active .switcher__view_ball,
.person__parameter .switcher__view_ball,
.info__parameter .switcher__view_ball,
.person__skills .switcher__view_ball,
.button_active .switcher__view_ball,
.level__item_active .switcher__view_ball {
  background-position: 0 -2700px;
}
.switcher__state:checked ~ .switcher__view_game,
.switcher_active .switcher__view_game,
.form__string_active .switcher__view_game,
.person__parameter .switcher__view_game,
.info__parameter .switcher__view_game,
.person__skills .switcher__view_game,
.button_active .switcher__view_game,
.level__item_active .switcher__view_game {
  background-position: 0 -2820px;
}
.switcher__state:checked ~ .switcher__view_chat,
.switcher_active .switcher__view_chat,
.form__string_active .switcher__view_chat,
.person__parameter .switcher__view_chat,
.info__parameter .switcher__view_chat,
.person__skills .switcher__view_chat,
.button_active .switcher__view_chat,
.level__item_active .switcher__view_chat {
  background-position: 0 -2940px;
}
.switcher__state:checked ~ .switcher__view_flash,
.switcher_active .switcher__view_flash,
.form__string_active .switcher__view_flash,
.person__parameter .switcher__view_flash,
.info__parameter .switcher__view_flash,
.person__skills .switcher__view_flash,
.button_active .switcher__view_flash,
.level__item_active .switcher__view_flash {
  background-position: 0 -3060px;
}
.switcher__state:checked ~ .switcher__view_smile,
.switcher_active .switcher__view_smile,
.form__string_active .switcher__view_smile,
.person__parameter .switcher__view_smile,
.info__parameter .switcher__view_smile,
.person__skills .switcher__view_smile,
.button_active .switcher__view_smile,
.level__item_active .switcher__view_smile {
  background-position: 0 -3180px;
}
.switcher__state:checked ~ .switcher__view_camera,
.switcher_active .switcher__view_camera,
.form__string_active .switcher__view_camera,
.person__parameter .switcher__view_camera,
.info__parameter .switcher__view_camera,
.person__skills .switcher__view_camera,
.button_active .switcher__view_camera,
.level__item_active .switcher__view_camera {
  background-position: 0 -3300px;
}
.switcher__state:checked ~ .switcher__view_point,
.switcher_active .switcher__view_point,
.form__string_active .switcher__view_point,
.person__parameter .switcher__view_point,
.info__parameter .switcher__view_point,
.person__skills .switcher__view_point,
.button_active .switcher__view_point,
.level__item_active .switcher__view_point {
  background-position: 0 -3420px;
}
.switcher__state:checked ~ .switcher__view_down,
.switcher_active .switcher__view_down,
.form__string_active .switcher__view_down,
.person__parameter .switcher__view_down,
.info__parameter .switcher__view_down,
.person__skills .switcher__view_down,
.button_active .switcher__view_down,
.level__item_active .switcher__view_down {
  background-position: 0 -3540px;
}
.switcher__state:checked ~ .switcher__view_bathroom,
.switcher_active .switcher__view_bathroom,
.form__string_active .switcher__view_bathroom,
.person__parameter .switcher__view_bathroom,
.info__parameter .switcher__view_bathroom,
.person__skills .switcher__view_bathroom,
.button_active .switcher__view_bathroom,
.level__item_active .switcher__view_bathroom {
  background-position: 0 -3660px;
}
.switcher__state:checked ~ .switcher__view_balcony,
.switcher_active .switcher__view_balcony,
.form__string_active .switcher__view_balcony,
.person__parameter .switcher__view_balcony,
.info__parameter .switcher__view_balcony,
.person__skills .switcher__view_balcony,
.button_active .switcher__view_balcony,
.level__item_active .switcher__view_balcony {
  background-position: 0 -3900px;
}
.switcher__state:checked ~ .switcher__view_window,
.switcher_active .switcher__view_window,
.form__string_active .switcher__view_window,
.person__parameter .switcher__view_window,
.info__parameter .switcher__view_window,
.person__skills .switcher__view_window,
.button_active .switcher__view_window,
.level__item_active .switcher__view_window {
  background-position: 0 -3780px;
}
.switcher__state:checked ~ .switcher__view_bed,
.switcher_active .switcher__view_bed,
.form__string_active .switcher__view_bed,
.person__parameter .switcher__view_bed,
.info__parameter .switcher__view_bed,
.person__skills .switcher__view_bed,
.button_active .switcher__view_bed,
.level__item_active .switcher__view_bed {
  background-position: 0 -4020px;
}
.switcher__state:checked ~ .switcher__view_doublebed,
.switcher_active .switcher__view_doublebed,
.form__string_active .switcher__view_doublebed,
.person__parameter .switcher__view_doublebed,
.info__parameter .switcher__view_doublebed,
.person__skills .switcher__view_doublebed,
.button_active .switcher__view_doublebed,
.level__item_active .switcher__view_doublebed {
  background: #ffffff url("assets/icons/double-bed-on.svg") no-repeat 100px
    100px;
  background-position: -1px;
}
.switcher__state:checked ~ .switcher__view_sofa,
.switcher_active .switcher__view_sofa,
.form__string_active .switcher__view_sofa,
.person__parameter .switcher__view_sofa,
.info__parameter .switcher__view_sofa,
.person__skills .switcher__view_sofa,
.button_active .switcher__view_sofa,
.level__item_active .switcher__view_sofa {
  background: #ffffff url("assets/icons/sofa-on.svg") no-repeat 100px 100px;
  background-position: -2px;
}
.switcher__state:checked ~ .switcher__view_nobed,
.switcher_active .switcher__view_nobed,
.form__string_active .switcher__view_nobed,
.person__parameter .switcher__view_nobed,
.info__parameter .switcher__view_nobed,
.person__skills .switcher__view_nobed,
.button_active .switcher__view_nobed,
.level__item_active .switcher__view_nobed {
  background: #ffffff url("assets/icons/no-bed-on.svg") no-repeat 100px 100px;
  background-position: -2px;
}
.switcher input ~ .switcher__view {
  cursor: pointer;
}
.person__parameter .switcher__view,
.info__parameter .switcher__view,
.person__skills .switcher__view,
.level__pic .switcher__view {
  margin: 0;
}
.level__pic .switcher__view {
  border-color: #fff;
  background-color: #fff;
}
.form__string .switcher__view {
  border-color: #fff;
  background-color: #fff;
}
.button_circle .switcher__view {
  display: inline-block;

  margin: -1px 0 0 -1px;

  vertical-align: baseline;

  border-color: transparent;
  background-color: transparent;
}

.number {
  position: relative;

  max-width: 200px;
}

.number__plus,
.number__minus {
  font-size: 24px;
  line-height: 32px;

  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 2px;

  width: 48px;
  margin: 0;
  padding: 0 0 4px;

  cursor: default;

  color: #b2b2b2;
  border: none;
  border-right: 2px solid #e1e1e1;
  outline: none;
  background: none;
}
@media screen and (max-width: 480px) {
  .number__plus,
  .number__minus {
    width: 36px;
  }
}

.number__plus {
  right: 2px;
  left: auto;

  cursor: pointer;

  border-right: none;
  border-left: 2px solid #e1e1e1;
}
.form__string_active .number__plus {
  color: var(--badi-green);
}

.form__string_active .number__minus {
  cursor: pointer;
}
.form__string_active .number__minus:hover {
  color: var(--badi-error);
}

.number__field {
  font-size: 24px;

  padding-top: 4px;
  padding-bottom: 4px;

  text-align: center;

  color: #b2b2b2;
}
.form__string_active .number__field {
  color: #494949;
}

.level__item {
  position: relative;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;

  height: 34px;
  margin: 24px 0;

  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.level__pic {
  margin: 0 8px 0 0;

  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.level__skill {
  font-size: 0.86rem;
  font-weight: 500;
  color: var(--badi-dark-grey);
  line-height: 1.25;
  min-width: 90px;
  flex: 0 0 auto;
}

.level__progress {
  padding: 0 8px 0 0;
  flex: 1 1 auto;
}

.level__range {
  width: 100%;
}

.viewpoint {
  display: block;
}

.viewpoint__note {
  color: #b2b2b2;
}

.viewpoint__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  margin: 0 -8px;

  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.viewpoint__item {
  position: relative;

  display: inline-block;

  width: 33.3333%;
  min-width: 140px;
  margin: 0 0 24px;

  vertical-align: middle;

  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}
.viewpoint__item_main {
  width: 100%;

  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
@media screen and (max-width: 762px) {
  .viewpoint__item {
    width: 48%;
  }
  .viewpoint__item_main {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .viewpoint__item {
    width: 100%;
    margin-bottom: 0;
  }
  .viewpoint__item_main {
    margin-bottom: 16px;

    border-bottom: 2px solid #e1e1e1;
  }
}
.viewpoint__item_avatar {
  width: 150px;
  margin: 0 auto;
}

.viewpoint__box {
  position: relative;

  margin: 0 8px;
}

.viewpoint__view {
  position: relative;
}

.viewpoint__cover {
  position: relative;

  display: block;
  overflow: hidden;

  box-sizing: border-box;
  width: 100%;
  height: 135px;

  vertical-align: top;

  border-radius: 10px;
  background-color: #e1e1e1;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}
.viewpoint__cover_profile {
  height: 135px;
  width: 135px;
}
.viewpoint__item_main .viewpoint__cover {
  height: 285px;
}
.viewpoint__item_empty .viewpoint__cover {
  cursor: pointer;

  border: 3px solid #e1e1e1;
  outline: none;
  background: #fff;
}
@media screen and (max-width: 600px) {
  .viewpoint__item_main .viewpoint__cover {
    height: 200px;
  }
}
@media screen and (max-width: 480px) {
  .viewpoint__cover {
    height: 170px;
  }
  .viewpoint__item_main .viewpoint__cover {
    height: 170px;
  }
}

.viewpoint__status {
  position: absolute;
  top: 12px;
  right: 12px;

  width: 14px;
  height: 14px;

  opacity: 0;
  border: 2px solid #e1e1e1;
  border-radius: 50%;
}
.viewpoint__item_loaded .viewpoint__status {
  opacity: 1;
  border-color: var(--badi-green);
  background: var(--badi-green) url("assets/icons/ico_check_white.svg")
    no-repeat 50% 50%;
  background-size: 80% 80%;
}
.viewpoint__item_error .viewpoint__status {
  opacity: 1;
  border-color: var(--badi-error);
  background: #fff url("assets/icons/ico_exclamation.svg") no-repeat 50% 50%;
  background-size: 80% 80%;
}

.viewpoint__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  height: 50px;
  padding: 0 8px;

  text-align: right;

  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.viewpoint__item_avatar .viewpoint__actions {
  position: absolute;
  bottom: -8px;
  left: 100%;
}
@media screen and (max-width: 762px) {
  .viewpoint__item_avatar .viewpoint__actions {
    position: relative;
    bottom: auto;
    left: auto;

    display: block;

    text-align: center;
  }
}

.viewpoint__action {
  display: inline-block;

  margin: 8px 0 8px 8px;

  vertical-align: middle;
}
.viewpoint__cover .viewpoint__action {
  position: absolute;
  top: 50%;
  left: 50%;

  margin: -18px 0 0 -18px;
}
.viewpoint__item_main .viewpoint__action_main {
  visibility: hidden;

  opacity: 0;
}
.viewpoint__item_avatar .viewpoint__action_main {
  display: none;
}
@media screen and (max-width: 762px) {
  .viewpoint__action {
    margin-left: 16px;
  }
  .viewpoint__item_avatar .viewpoint__action_delete {
    margin-bottom: 0;
    margin-left: 0;
  }
}

.viewpoint__text {
  font-size: 13px;
  line-height: 1.25;

  display: inline-block;

  text-align: left;
  vertical-align: middle;

  color: var(--badi-error);
}

.human {
  font-size: 1rem;

  position: relative;

  display: inline-block;

  margin: 0;
  padding: 0;

  cursor: pointer;
  text-align: left;
  text-decoration: none;

  color: var(--badi-dark-grey);
  border: 0;
  outline: none;
  background: none;
}

.list_popup .human {
  font-size: 17px;
}

.human:hover {
  text-decoration: none;

  color: #494949;
}
@media screen and (max-width: 999px) {
  .human {
    font-size: 14px;
  }
}

.human__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.human_v .human__box {
  display: block;

  text-align: center;
}

.human__view {
  position: relative;

  display: inline-block;

  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.human_online .human__view:after {
  position: absolute;
  top: 1px;
  right: -4px;

  width: 10px;
  height: 7px;

  content: "";

  background: url("assets/icons/icon-check.svg") no-repeat 0 0;
  background-size: cover;
}
.human__view .human_v {
  margin: 0 auto;
}
@media screen and (max-width: 999px) {
  .list__profile .human_online .human__view:after {
    right: -6px;
  }
}

.human__avatar {
  overflow: hidden;

  width: 52px;
  height: 52px;

  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;

  border: 2px solid white;
  border-radius: 50%;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.human_online .human__avatar {
  border-color: var(--badi-green);
}
.human_offline .human__avatar,
.human_total .human__avatar {
  border-color: #cacaca;
}
.human_outline .human__avatar {
  border-color: #fff;
}
.flow__profile .human__avatar {
  width: 40px;
  height: 40px;
}
.flow__item_active .flow__profile .human__avatar {
  width: 68px;
  height: 68px;
}
.human__avatar_big,
.list__item_active .human__avatar,
.list_popup .human__avatar {
  width: 72px;
  height: 72px;
  border: 3px solid white;
}
@media screen and (max-width: 999px) {
  .list__profile .human__avatar {
    width: 32px;
    height: 32px;
  }
  .list_popup .human__avatar {
    width: 72px;
    height: 72px;
  }
}
@media screen and (max-width: 600px) {
  .human__avatar {
    width: 40px;
    height: 40px;
  }

  .human__avatar_big {
    width: 72px;
    height: 72px;
  }
}

.human__photo {
  width: 100%;
  height: 100%;

  vertical-align: bottom;
}

.human__about {
  font-size: 1rem;
  font-weight: 500;
  margin: 0 0 0 16px;
  flex: 1 1 auto;
}
.human_v .human__about {
  margin: 0;
}

.human__label {
  line-height: 1.25;
}
.human__label_dark {
}

.human__label_accent {
  color: var(--badi-green);
}

.human_v .human__label {
  margin: -6px 0 6px;
}

.list_popup .human__label_accent {
  color: #494949;
  font-weight: 700;
  font-size: 16px;
}

.list_popup .human__label {
  margin: auto;
}

.human__name {
  display: inline-block;
  overflow: hidden;

  max-width: 140px;

  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.human__age {
  display: inline-block;

  vertical-align: middle;
}
.human__age:before {
  display: inline-block;

  margin: 0 4px 0 1px;

  content: ",";
}

.human__number {
  font-size: 22px;
  font-weight: 500;
  line-height: 52px;

  text-align: center;

  color: #cacaca;
}

.layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  box-sizing: border-box;
  width: 100%;
  min-width: 320px;
  min-height: 100vh;

  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -ms-flex-item-align: start;
  align-self: flex-start;
}
.layout_home,
.layout_search,
.layout_page {
  background: #fff;
}
.layout_fullsize {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  min-height: 10vh;
}

.layout__wrapper,
.layout > .form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;

  min-height: calc(100vh - 120px);

  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
}
@media screen and (max-width: 999px) {
  .layout__wrapper,
  .layout > .form {
    min-height: 10px;
  }
}

.layout__content {
  position: relative;
  z-index: 1;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;

  margin: 85px 0 0;

  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
}
.layout_home .layout__content {
  margin-top: 0;
}
.filter + .layout__content {
  padding-top: 60px;
}
.layout__wrapper > .menu__wrapper {
  z-index: 192;
}
.menu__wrapper + .layout__content {
  margin-top: 125px;
}
@media screen and (max-width: 1060px) {
  .layout__wrapper > .menu__wrapper {
    top: 51px;
    position: absolute;
  }
  .page_scrolled .layout__wrapper > .menu__wrapper {
    box-shadow: initial;
  }

  .menu__wrapper + .layout__content {
    margin-top: 110px;
  }
}
@media screen and (max-width: 999px) {
  .layout__content {
    min-height: 300px;
    margin-top: 66px;
  }
  .layout_home .layout__content {
    margin-top: 50px;
  }
  .filter + .layout__content {
    padding-top: 118px;
  }
}

.layout__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;

  width: 100%;

  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
}

.layout__columns,
.layout__columns > .form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media screen and (max-width: 999px) {
  .layout__columns,
  .layout__columns > .form {
    flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
  }
}

.layout__head {
  padding: 20px 0;

  background: #fff;
}

.layout__search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;

  width: 100%;
  margin-top: -24px;

  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
@media screen and (max-width: 999px) {
  .layout__search {
    display: block;

    margin-top: -16px;
  }
}

.layout__map {
  position: relative;
  z-index: 15;

  width: 40%;
  max-width: 590px;
  max-height: calc(100vh - 119px);

  -webkit-transition: width 0.125s ease-out;
  transition: width 0.125s ease-out;

  background: #f7f7f7;

  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.layout__search_close .layout__map {
  width: 0;
}
@media screen and (max-width: 999px) {
  .layout__map {
    overflow: hidden;

    width: 100% !important;
    max-width: 100%;
    height: 0;
  }
  .layout__search_map .layout__map {
    height: calc(100vh - 167px);
  }
}

.layout__results {
  width: 60%;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 16px;

  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
@media screen and (max-width: 999px) {
  .layout__results {
    overflow: hidden;

    width: 100%;
  }
  .layout__search_map .layout__results {
    height: 0;
  }
}

.layout__control {
  font-size: 12px;
  font-weight: 700;

  position: absolute;
  top: 24px;
  left: 100%;

  margin: 0 0 0 20px;

  white-space: nowrap;

  color: var(--badi-green);
}
@media screen and (max-width: 999px) {
  .layout__control {
    left: -9999px;
  }
}

.layout__view {
  position: absolute;
  z-index: 16;
  bottom: 16px;
  left: -9999px;

  width: 140px;
  margin: 0 0 0 -70px;

  text-align: center;
}
@media screen and (max-width: 999px) {
  .layout__view {
    position: fixed;
    left: 50%;
  }
}

.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;

  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
}
.content_start {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
.content_nochat {
  position: absolute;
  left: -9999px;

  width: 1px;
  height: 1px;
}
@media screen and (max-width: 999px) {
  .content_start {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .content_nochat {
    position: relative;
    left: auto;

    width: auto;
    height: auto;
  }
  .content_chat {
    position: absolute;
    left: -9999px;

    width: 1px;
    height: 1px;
  }
}

.sidebar {
  width: 30%;
  min-height: 10px;
  margin: 0 24px 0 0;

  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.sidebar_booking {
  position: absolute;
  right: 0px;
  margin: 0;
  width: auto;
  border-radius: 4.8px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  font-size: 0.93rem;
}
.bookingmenu + .sidebar_booking {
  top: -40px;
}
@media screen and (max-width: 762px) {
  .sidebar_booking {
    display: none;
  }
}

.sidebar_chat,
.sidebar_requests {
  position: relative;

  background: #fff;

  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}
.sidebar_profile,
.sidebar_owner {
  position: relative;
}

@media screen and (max-width: 999px) {
  .sidebar {
    min-height: 0;
  }
  .sidebar_profile,
  .sidebar_payment,
  .sidebar_owner,
  .sidebar_requests {
    width: auto;
    margin: 0 0 16px;
  }
  .sidebar_payment > .sidebar__content {
    position: relative;
    top: auto !important;
    left: auto !important;
    width: auto;
  }
  .sidebar_chat {
    position: absolute;
    left: -9999px;

    width: 1px;
    height: 1px;
  }
  .sidebar_menu {
    position: relative;
    z-index: 80;
  }

  .sidebar_pin {
    position: relative;

    height: 50px;
  }
}
@media screen and (max-width: 480px) {
  .sidebar {
    margin: 0 0 8px;
  }
}

.sidebar__content {
  position: fixed;
  top: auto;
  left: 32px;

  width: 328.188px;
  max-width: 29%;
  margin-left: -16px;
}
.layout__content_top .sidebar__content {
  top: 85px;
}

@media screen and (min-width: 1126px) {
  .sidebar__content {
    left: 50%;

    margin-left: -547px;
  }
}
.sidebar_chat .sidebar__content {
  position: absolute;
  top: 0 !important;
  left: 0 !important;

  overflow: hidden;
  overflow-y: visible;

  width: 100%;
  max-width: 100%;
  height: 100%;
  margin-left: 0;

  background: #fff;
}
.sidebar_requests .sidebar__content {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  overflow-y: visible;
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin-left: 0;
  background: #fff;
}

.sidebar_profile .sidebar__content,
.sidebar_owner .sidebar__content {
  position: relative;
  top: auto;
  left: auto;

  width: auto;
  max-width: 100%;
  margin-left: auto;
}

.sidebar_booking .sidebar__content {
  position: relative;
  margin: 0;
  left: auto;
  width: 277px;
  max-width: none;
}
@media screen and (max-width: 999px) {
  .sidebar__content {
    position: absolute;
    left: 0;
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }
  .sidebar_chat .sidebar__content,
  .sidebar_requests .sidebar__content {
    position: relative;

    overflow: visible;
  }

  .sidebar_pin .sidebar__content {
    position: fixed;
    z-index: 150;
    top: 50px !important;
    right: 0 !important;
    left: 0 !important;

    -webkit-transition: box-shadow 0.25s ease-out;
    transition: box-shadow 0.25s ease-out;

    border-bottom: 1px solid #e1e1e1;
  }
  .sidebar_pinned .sidebar__content {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
  }
}

.sidebar__overlay {
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 100%;
  left: 0;

  -webkit-transition: opacity 0.25s ease-out;
  transition: opacity 0.25s ease-out;

  opacity: 0;
  background: #000;
}
@media screen and (max-width: 999px) {
  .sidebar__nav_open .sidebar__overlay {
    z-index: 1;
    bottom: 0;

    opacity: 0.4;
  }
  .sidebar__nav_close .sidebar__overlay {
    z-index: -1;
    bottom: 0;

    opacity: 0;
  }
}

@media screen and (max-width: 999px) {
  .sidebar__nav {
    margin: 0px -16px 0 -16px;

    background: #fff;
  }
  .sidebar__content:last-child > .sidebar__nav:after {
    position: absolute;
    z-index: 87;
    top: 14px;
    right: 0;

    width: 26px;
    height: 26px;

    content: "";
    -webkit-transition: -webkit-transform 0.125s ease-out;
    transition: -webkit-transform 0.125s ease-out;
    transition: transform 0.125s ease-out;
    transition:
      transform 0.125s ease-out,
      -webkit-transform 0.125s ease-out;
    pointer-events: none;

    background: url("assets/icons/ico_down_gray.svg") no-repeat 50% 50%;
  }
  .sidebar__nav_open:after {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@media screen and (max-width: 999px) {
  .sidebar__head {
    position: relative;
    z-index: 1;

    min-height: 52px;
    padding: 2px 0;

    background: #fff;
  }
}

@media screen and (max-width: 999px) {
  .sidebar__list {
    position: absolute;
    z-index: 1;
    top: 0;
    right: -16px;
    left: -16px;

    padding: 0 16px;

    -webkit-transition: margin 0.125s ease-out;
    transition: margin 0.125s ease-out;

    background: #fff;
  }
  .sidebar_chat .sidebar__list,
  .sidebar_requests .sidebar__list {
    overflow-y: auto;

    max-height: 270px;
  }
  .sidebar__nav_open .sidebar__list {
    margin: 52px 0 0;
  }
}

@media screen and (max-width: 999px) {
  .sidebar__trigger {
    position: absolute;
    z-index: 2;
    top: 0;
    right: -16px;
    left: -16px;

    height: 52px;

    cursor: pointer;

    opacity: 0.3;
  }
}

.post {
  background: #fff;

  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.post img {
  max-width: 100%;
}
.post_middle,
.post_empty {
  display: flex;
}
.post_urban {
  margin-top: 55px;
}
.post_urban h2 {
  margin-left: -32px;
}
.post_urban .text_big {
  max-width: 580px;
}
@media screen and (max-width: 999px) {
  .post_urban {
    margin-top: 30px;
  }
  .post_urban h2 {
    margin-left: 0;
  }
  .layout_page .post,
  .post_urban,
  .post_empty {
    margin-right: -16px;
    margin-left: -16px;
  }
}
@media screen and (max-width: 480px) {
  .post_urban {
    margin-top: 20px;
  }
  .post_empty {
    margin-right: -8px;
    margin-left: -8px;
  }
}

.post__container {
  padding: 32px;
}
.post_middle .post__container,
.post_empty .post__container {
  flex: 1 1 auto;
}
@media screen and (max-width: 999px) {
  .post__container {
    padding: 16px;
  }
}

.post_empty .post__container > p,
.post_empty .post__container > h2 {
  max-width: 400px;
}

.post_matching .post__container,
.post_system .post__container,
.post_requests .post__container {
  padding: 40px 40px;
}

.post__slim {
  max-width: 520px;
  margin: 0 auto;
}

.post__thin {
  max-width: 400px;
  margin: 0 auto;
}

.stars {
  width: 79px;
  height: 12px;

  background: url("assets/icons/stars.svg") no-repeat 0 0;
}

.stars__rating {
  height: 100%;

  background: url("assets/icons/stars.svg") no-repeat 0 0;
}
.stars__rating_1 {
  width: 15px;
}
.stars__rating_2 {
  width: 30px;
}
.stars__rating_3 {
  width: 50px;
}
.stars__rating_4 {
  width: 65px;
}
.stars__rating_5 {
  width: 79px;
}

.progress {
  position: relative;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.progress__percent {
  margin: 0 0 0 16px;

  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
@media screen and (max-width: 999px) {
  .progress__percent {
    font-weight: 700;
  }
}

.progress__scale {
  position: relative;

  overflow: hidden;

  height: 5px;
  margin: 10px 0 0;

  border-radius: 5px;
  background: #eaeaea;

  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.progress__line {
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;

  border-radius: inherit;
  background: var(--badi-green);
}
.progress__scale_low .progress__line {
  background: var(--badi-pink);
}
.progress__scale_fit .progress__line {
  background: var(--badi-pink);
}

.map {
  position: relative;
}
.map_search {
  position: fixed;
  top: 120px;
  bottom: 0;
  left: 0;

  overflow: hidden;

  width: 40%;
  max-width: 590px;

  -webkit-transition: width 0.125s ease-out;
  transition: width 0.125s ease-out;

  box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
}
.layout__search_close .map_search {
  width: 0;
}
@media screen and (max-width: 999px) {
  .map_search {
    position: absolute;
    top: 0;

    width: 100% !important;
    max-width: 100%;
  }
}
.map iframe {
  width: 100%;
  height: 100%;

  vertical-align: bottom;
}

.map__dark {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  pointer-events: none;

  opacity: 0.1;
  background: #000;
}

.map__point {
  z-index: 45;

  width: 2px;
  height: 2px;
}
@-moz-document url-prefix() {
  .map__point {
    width: auto;
    height: auto;
    position: absolute;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    top: -29px;
  }
}
.map__point_active {
  z-index: 46;
}
.map__point:hover {
  z-index: 47;
}

.map__price {
  color: var(--badi-dark-grey);
  font-weight: 500;
  line-height: 20px;

  position: absolute;
  bottom: 1px;
  left: 1px;

  margin: 0 0 6px;
  padding: 2px 6px 0;

  user-select: none;
  transition:
    background 0.125s ease-out,
    padding 0.125s ease-out;
  transform: translateX(-50%);

  border: 2px solid #fff;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}

@-moz-document url-prefix() {
  .map__price {
    padding: 4px 6px 2px 6px;
    position: relative;
  }
}

.map__point_visited > .map__price {
  color: var(--badi-soft-grey);
}
.map__price:before {
  position: absolute;
  top: 100%;
  left: 50%;

  margin: 2px 0 0 -4px;

  content: "";

  border-top: 6px solid #fff;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.map__price:after {
  position: absolute;
  top: 100%;
  left: 50%;

  margin: -2px 0 0 -4px;

  content: "";
  -webkit-transition: 0.125s ease-out;
  transition: 0.125s ease-out;

  border-top: 6px solid #fff;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.map__point:hover .map__price,
.map__point_active .map__price {
  color: #fff;
  background: var(--badi-green);
}
.map__point:hover .map__price:after,
.map__point_active .map__price:after {
  border-top-color: var(--badi-green);
}
.map__point_active .map__price {
  line-height: 28px;

  padding-right: 10px;
  padding-left: 10px;
}
@-moz-document url-prefix() {
  .map__point_active .map__price {
    line-height: 20px;
    padding: 4px 6px 2px 6px;
  }
}

.map__cost {
  font-size: 14px;

  -webkit-transition: 0.125s ease-out;
  transition: 0.125s ease-out;
}
.map__point_active .map__cost {
  font-size: 16px;
}

.map__tip {
  position: absolute;
  z-index: 50;
  left: 15px;
  bottom: 15px;
}

.actions {
  position: relative;
}

.actions__button {
  font:
    300 13px/1 Arial,
    sans-serif;

  margin: -6px 0 0;
  padding: 15px 10px;

  cursor: pointer;
  vertical-align: bottom;

  color: #494949;
  border: none;
  outline: none;
  background: none;
}

.actions__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 9;
}

.actions__dot {
  display: block;
  overflow: hidden;

  width: 3px;
  height: 3px;
  margin: 4px 0;

  -webkit-transition: 0.125s ease-out;
  transition: 0.125s ease-out;

  border-radius: 50%;
  background: #494949;
}
.actions_active .actions__bg {
  display: block;
}
.actions_active .actions__dot {
  -webkit-transform: translate(0, 2px);
  -ms-transform: translate(0, 2px);
  transform: translate(0, 2px);
}
.actions_active .actions__dot:first-child {
  -webkit-transform: translate(-5px, 4px);
  -ms-transform: translate(-5px, 4px);
  transform: translate(-5px, 4px);
}
.actions_active .actions__dot:last-child {
  -webkit-transform: translate(5px, -10px);
  -ms-transform: translate(5px, -10px);
  transform: translate(5px, -10px);
}

.actions__menu {
  font-size: 14px;

  position: absolute;
  top: 100%;
  right: 0;

  visibility: hidden;

  margin: -10px 0 0;

  -webkit-transition: 0.125s ease-out;
  transition: 0.125s ease-out;
  -webkit-transform: translate(0, 30px);
  -ms-transform: translate(0, 30px);
  transform: translate(0, 30px);
  text-align: left;
  white-space: nowrap;

  opacity: 0;
  background: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}
.actions_active .actions__menu {
  z-index: 10;

  visibility: visible;

  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);

  opacity: 1;
}

.actions__list {
  margin: 0;
  padding: 18px 0;

  list-style: none;
}

.actions__link {
  line-height: 2em;
  font-size: 0.8125rem;
  font-weight: 400;
  display: block;
  margin: 0;
  padding: 5px 30px;
  cursor: pointer;
  color: var(--badi-warm-grey);
  border: none;
  outline: none;
  background: none;
}
.actions__link:hover {
  text-decoration: none;

  color: #494949;
}

.no_padding {
  padding: 0;
}

.loader {
  font-size: 1px;

  position: relative;

  overflow: hidden;

  width: 100%;
  height: 3px;

  border-radius: 3px;
  background: rgba(14, 220, 132, 0.5);
}

.loader:before {
  position: absolute;

  height: 3px;

  content: "";
  -webkit-animation: loading 2s infinite ease-out 1.5s;
  animation: loading 2s infinite ease-out 1.5s;

  border-radius: 3px;
  background: var(--badi-green);
}

@-webkit-keyframes loading {
  0% {
    left: -10%;

    width: 5%;
  }
  30% {
    width: 40%;
  }
  100% {
    left: 110%;

    width: 5%;
  }
}

@keyframes loading {
  0% {
    left: -10%;

    width: 5%;
  }
  30% {
    width: 40%;
  }
  100% {
    left: 110%;

    width: 5%;
  }
}

.spinner {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  visibility: hidden;

  opacity: 0;

  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.spinner_active {
  z-index: 3000;

  visibility: visible;

  opacity: 1;
}

.spinner__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -100px;
  left: 0;

  visibility: hidden;

  -webkit-transition: 0.125s ease-out;
  transition: 0.125s ease-out;

  opacity: 0;
  background: #000;
}
.spinner_active .spinner__bg {
  visibility: visible;

  opacity: 0.42;
}
.spinner_closed .spinner__bg {
  opacity: 0;
}

.spinner__main {
  font-size: 14px;

  position: relative;

  min-width: 260px;
  max-width: 500px;

  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  text-align: center;

  opacity: 0;
  color: #fff;
}
.spinner_active .spinner__main {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);

  opacity: 1;
}
.spinner_closed .spinner__main {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);

  opacity: 0;
}

.spinner__view {
  position: relative;

  width: 120px;
  height: 4px;
  margin: 43px auto 43px;

  background: #fff;
}

.spinner__round {
  position: absolute;
  top: -5px;
  left: 50%;

  width: 8px;
  height: 8px;
  margin: 0 0 0 -5px;

  -webkit-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
  transform: translateX(-30px);

  border: 3px solid #fff;
  border-radius: 50%;
  background: #d2d2d2;
}
.spinner_active .spinner__round {
  -webkit-animation: spinnerRound 2s ease-in forwards;
  animation: spinnerRound 2s ease-in forwards;
}

@-webkit-keyframes spinnerRound {
  0% {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  100% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
}

@keyframes spinnerRound {
  0% {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  100% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
}

.spinner__start,
.spinner__finish {
  position: absolute;
  top: -43px;
  left: -45px;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  width: 90px;
  height: 90px;

  -webkit-transform: scale(0.6);
  -ms-transform: scale(0.6);
  transform: scale(0.6);

  border-radius: 50%;
  background: #fff;

  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.spinner_active .spinner__start,
.spinner_active .spinner__finish {
  -webkit-animation: spinnerEdges 2s ease-in forwards;
  animation: spinnerEdges 2s ease-in forwards;
}

.spinner__finish {
  right: -45px;
  left: auto;
}
.spinner_active .spinner__finish {
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

.spinner__ico {
  vertical-align: bottom;
}

@-webkit-keyframes spinnerEdges {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
}

@keyframes spinnerEdges {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
}

.spinner__text {
  padding-top: 20px;
}

.spinner__ico_bed {
  max-width: 62px;
}
.spinner__ico_search {
  max-width: 62px;
}

#password::-ms-clear,
#password::-ms-reveal {
  display: none;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
