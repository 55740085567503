@import "../../../styles/baseExt.css";

.roomdetail_wrapper .row [class*="col-"] {
  padding: 0;
}

.room__inner {
  position: relative;

  box-sizing: border-box;
  min-width: 320px;
  max-width: 1126px;
  margin: 0 auto;
  padding: 0 16px;

  @media screen and (max-width: $media-breakpoint-md) {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.room {
  position: relative;

  width: 100%;
  margin-bottom: 32px;

  @media screen and (max-width: 480px) {
    margin-bottom: 8px;
  }
}

.room + .layout__content {
  margin: 22px 0 0;
}

.room_scrolled {
  z-index: 10;
}

.room__slider {
  position: relative;

  height: 555px;

  @media screen and (max-width: $media-breakpoint-lg) {
    height: 400px;
  }
  @media screen and (max-width: $media-breakpoint-md) {
    height: 350px;
  }
  @media screen and (max-width: $media-breakpoint-sm) {
    height: 300px;
  }
  @media screen and (max-width: 480px) {
    height: 250px;
  }
}

.room__list {
  cursor: pointer;
  position: relative;

  width: 100%;
  height: 100%;
}

.room__slide {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  -webkit-transition: opacity 0.125s ease-out;
  transition: opacity 0.125s ease-out;

  opacity: 0;
  background: no-repeat 50% 50%;
  background-size: cover;
}
.room__slide_active {
  opacity: 1;
}

.room__bullets {
  position: absolute;
  z-index: 12;
  right: 16px;
  bottom: 16px;
  left: 16px;

  text-align: center;
}

.room__bullet {
  width: 8px;
  height: 8px;
  margin: 0 2px;
  padding: 0;

  cursor: pointer;
  -webkit-transition: opacity 0.125s ease-out;
  transition: opacity 0.125s ease-out;

  opacity: 0.5;
  border: 0;
  border-radius: 50%;
  outline: none;
  background: #fff;
}
.room__bullet:hover {
  opacity: 0.8;
}
.room__bullet_active {
  opacity: 1;
}

.room__all {
  position: absolute;
  bottom: 16px;
  left: 16px;
  z-index: 1;
}

.room__button,
.room__button:hover {
  font-size: 1em;
  font-weight: 700;
  line-height: 1em;
  height: 32px;
  text-align: center;
  color: #4a4a4a;
  background: #fff;
  border: solid 2px #ffffff;
}
.room__button .button__ico,
.room__button:hover .button__ico {
  width: 19px !important;
  height: 16px !important;
  max-height: 16px !important;
}

.room__main {
  min-height: 118px;
}

.room__bar {
  box-sizing: border-box;
  min-height: 118px;
  padding: 16px 0 20px;

  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
  border-bottom: 2px solid var(--badi-light-grey);

  background: #fff;

  @media screen and (max-width: $media-breakpoint-md) {
    padding: 16px 0 32px;
  }
}
.room_scrolled .room__bar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  min-height: 80px;
  padding: 8px 0 8px;

  box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: $media-breakpoint-md) {
  .room__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
  }
}

.room__actions {
  float: right;
  margin: 8px 0 0;

  @media screen and (max-width: $media-breakpoint-md) {
    float: none;

    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
}

.room__text {
  width: calc(100% - 170px);

  @media screen and (max-width: $media-breakpoint-md) {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    width: auto;
  }
}

.room__bar--line {
  display: flex;
  align-items: center;

  @media screen and (max-width: $media-breakpoint-md) {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: $media-breakpoint-md) {
  .room__bar--line-responsive {
    flex-direction: column;
    align-items: flex-start;
  }
}

.room__bar--line-dot {
  font-size: 10px;
  margin: 0 5px;
  @media screen and (max-width: $media-breakpoint-sm) {
    display: none;
  }
}

.room__title {
  margin: 6px 0 0;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.5;

  -webkit-transition: font-size 0.25s ease-out;
  transition: font-size 0.25s ease-out;

  @media screen and (max-width: $media-breakpoint-sm) {
    &:after {
      display: none;
    }
  }
}
.room_scrolled .room__title {
  font-size: 20px;
}
.room_scrolled .room__title:after {
  display: none;
}

.room__status_warning {
  color: var(--badi-warm-grey);
  font-weight: 500;
  margin-right: 100px;

  @media screen and (max-width: $media-breakpoint-lg) {
    margin-right: auto;
  }
}

.room__report {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 24px 0 0;
}

.room__matchprofile {
  top: 25px;
  z-index: 1;

  @media screen and (max-width: $media-breakpoint-sm) {
    top: 10px;
  }
}

.pendingRoomText {
  color: rgb(255, 201, 71);
  margin-right: 24px;
  font-weight: 500;
}
