@import "baseExt.css";

.person__ava {
  font-size: inherit;
  font-weight: inherit;

  margin: 0;
  padding: 0;

  cursor: pointer;

  border: none;
  outline: none;
  background: none;
}

.person {
  position: relative;

  overflow: hidden;

  min-height: 500px;

  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
@media screen and (max-width: 600px) {
  .person {
    min-height: 300px;
  }
}

.person__inner {
  position: absolute;
  top: 56px;
  right: 0;
  bottom: 70px;
  left: 0;

  overflow: hidden;
  overflow-y: auto;

  color: #494949;
  background: #fff;
}
@media screen and (max-width: 600px) {
  .person__inner {
    top: 41px;
    bottom: 52px;
  }
}

.person__header {
  line-height: 24px;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  box-sizing: border-box;
  min-height: 58px;

  text-align: right;

  color: #b2b2b2;
  border-bottom: 1px solid #e1e1e1;
  background: #fff;
}
.person__header_main {
  color: #fff;
  border-bottom: none;
  background: #fec059;
}
.person_empty .person__header {
  border-bottom: 1px solid #f5f4f4;
  box-shadow: none;
}
@media screen and (max-width: 600px) {
  .person__header {
    font-size: 14px;
    line-height: 24px;

    min-height: 44px;
    padding-top: 8px;
  }
}

.person__num {
  font-size: 18px;
  font-weight: 700;

  float: left;
}
@media screen and (max-width: 600px) {
  .person__num {
    font-size: inherit;
    font-weight: 400;
  }
}

.person__reg:after {
  display: inline-block;

  width: 24px;
  height: 24px;
  margin: 0 0 0 16px;

  content: "";
  vertical-align: middle;

  background: url("assets/icons/ico_room.svg") no-repeat 50% 50%;
  background-size: contain;
}

.person__main {
  position: relative;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  padding: 16px;

  -ms-flex-line-pack: end;
  align-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
@media screen and (max-width: 600px) {
  .person__main {
    display: block;

    padding: 0;
  }
}

.person__avatar {
  width: 160px;
  height: 160px;

  background: #fafafa url("assets/images/no_photo.svg") no-repeat 50% 50%;
  background-size: cover;
}
.person__avatar_empty {
  background-size: auto;
}
@media screen and (max-width: 600px) {
  .person__avatar {
    width: auto;
    height: 280px;
  }
}
@media screen and (max-width: 480px) {
  .person__avatar {
    height: 210px;
  }
}

.person__photo {
  width: 100%;
  height: 100%;

  vertical-align: bottom;
}

.person__info {
  margin-left: 32px;
}
@media screen and (max-width: 762px) {
  .person__info {
    margin-left: 16px;
  }
}
@media screen and (max-width: 600px) {
  .person__info {
    position: relative;

    margin: 0;
    padding: 16px 146px 16px 16px;
  }
}

.person__user {
  margin: 0 0 10px;
}
.person__user:after {
  display: none;
}
.person_empty .person__user {
  width: 148px;
  height: 24px;

  border-radius: 3px;
  background: #fafafa;
}
@media screen and (max-width: 762px) {
  .person__user {
    margin: 0 0 8px;
  }
}
@media screen and (max-width: 600px) {
  .person__user {
    font-size: 20px;
  }
}

.person__location {
  margin-bottom: 10px;
}

.person_empty .person__location {
  width: 148px;
  height: 16px;
  margin-bottom: 12px;

  border-radius: 3px;
  background: #fafafa;
}

@media screen and (max-width: 600px) {
  .person__parameters {
    position: absolute;
    top: 8px;
    right: 12px;

    max-width: 130px;

    text-align: right;
  }

  .person__flag {
    top: -26px;
  }
}

.person__parameter {
  display: inline-block;

  margin: 0 8px 0 0;

  vertical-align: middle;
}
.person_empty .person__parameter {
  width: 36px;
  height: 36px;

  border-radius: 50%;
  background: #fafafa;
}
@media screen and (max-width: 762px) {
  .person__parameter {
    margin-top: 8px;
  }
}
@media screen and (max-width: 600px) {
  .person__parameter {
    margin: 4px 4px 0 0;
  }
}

.person__verify,
.person__trusted {
  line-height: 32px;

  position: absolute;
  top: 32px;
  right: -1px;

  height: 35px;
  padding: 0 16px 0 8px;

  color: #fff;
  background-size: cover;
}

.person__verify {
  background: url("assets/images/verify.svg") no-repeat 100% 0;
}

.person__trusted {
  background: url("assets/images/trusted-flag.svg") no-repeat 100% 0;
}

.person_empty .person__verify,
.person_empty .person__trusted {
  width: 80px;

  background-image: url("assets/images/verify_empty.svg");
}

.person__verify:before,
.person__trusted:before {
  position: absolute;
  top: 0;
  right: 100%;

  width: 34px;
  height: 100%;

  content: "";

  background: inherit;
  background-position: 0 0;
}
@media screen and (max-width: 600px) {
  .person__verify,
  .person__trusted {
    font-size: 12px;
    line-height: 24px;

    top: -242px;

    height: 26px;
  }
  .person__verify:before,
  .person__trusted:before {
    margin-right: -8px;
  }
}
@media screen and (max-width: 480px) {
  .person__verify,
  .person__trusted {
    top: -202px;
  }
}

.person__file {
  clear: both;
  overflow: hidden;
}
.person__main + .person__file {
  margin-top: 18px;
}
@media screen and (max-width: 762px) {
  .person__main + .person__file {
    margin-top: 0;

    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.person__box {
  float: left;
  clear: left;

  box-sizing: border-box;
  width: 46%;
  margin: 0 16px;
  padding: 16px 0;

  border-top: 1px solid var(--badi-soft-grey);
}
.person__box_skills,
.person__box_roommates,
.person__box_location {
  float: right;
  clear: none;

  margin-left: 0;
}
.person__box_skills,
.person__box_roommates {
  border-top-width: 0;
}
.person__file_info .person__box {
  float: none;

  width: auto;
  margin: 0;
  padding: 32px 0;

  border-width: 1px;
}
.person__file_info .person__box_skills {
  float: none;

  border-top-width: 1px;
}
.person__file_info .person__box_friends {
  text-align: center;
}
.invitation .person__box {
  border-top-width: 0;
}
@media screen and (max-width: 999px) {
  .person__box {
    float: none;

    width: auto;

    border-top: 2px solid #e1e1e1 !important;
  }
  .person__box_skills,
  .person__box_roommates,
  .person__box_location {
    margin-left: 16px;
  }
  .invitation .person__box {
    border-top-width: 0 !important;
  }
}

.person__label {
  margin-top: 0;
}
.sidebar .person__label {
  font-weight: 400;
}

.person__list {
  margin: 0;
  padding: 0;

  list-style: none;
}

.person__item {
  display: inline-block;

  margin: 0 0 0 5px;
}
.person__item:before {
  display: inline-block;

  width: 2px;
  height: 2px;
  margin: 12px 8px 0 0;

  content: "";
  vertical-align: top;

  border-radius: 50%;
  background: #494949;
}
.person__item:first-child {
  margin: 0;
}
.person__item:first-child:before {
  display: none;
}

.person__community {
  position: relative;

  overflow: hidden;

  height: 50px;
  margin: -16px 0 -48px -16px;
  padding: 16px 0 48px 16px;
}
@media screen and (max-width: 999px) {
  .person__community {
    margin-right: -16px;
  }
}

.person__friends {
  position: absolute;

  margin: 0;
  padding: 0;

  list-style: none;

  white-space: nowrap;
}
.person__file_info .person__friends {
  position: relative;
}

.person__friend {
  position: relative;

  display: inline-block;

  padding: 0 5px;

  vertical-align: top;
}

.person__ava {
  display: block;
  overflow: hidden;

  width: 50px;
  height: 50px;

  cursor: pointer;
  -webkit-transition: -webkit-transform 0.25s ease-out;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition:
    transform 0.25s ease-out,
    -webkit-transform 0.25s ease-out;

  border-radius: 50%;
  background-position: 50% 50%;
  background-size: cover;
}
.person__friend:hover .person__ava {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  text-decoration: none;

  opacity: 1;
}

.person__friendname {
  font-size: 15px;

  position: absolute;
  top: 55px;
  left: 50%;

  padding: 0 8px;

  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
  -webkit-transform: translate(-50%, 20px);
  -ms-transform: translate(-50%, 20px);
  transform: translate(-50%, 20px);

  opacity: 0;
  background: #fff;
}
.person__friend:hover .person__friendname {
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);

  opacity: 1;
}

.person__row {
  position: relative;

  overflow: hidden;

  height: 34px;
  margin: 0 16px 16px 0;
  padding: 0 0 0 50px;
}

.person__pic {
  position: absolute;
  top: 0;
  left: 0;
}

.person__skill {
  font-size: 0.86rem;
  font-weight: 400;
  line-height: 1.25;
  padding: 4px 0 3px 0;
  color: var(--badi-dark-grey);
}

.person__progress {
  position: relative;

  overflow: hidden;

  height: 3px;

  border-radius: 2px;
}

.person__line {
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;

  border-radius: 2px;
  background: var(--badi-green);
}

.person__empty {
  padding: 50px;
  height: calc(100% - 100px);
}

.person_empty .person__body,
.person_empty .person__file {
  height: 100%;
}

.person__empty > h2,
.person__empty > p {
  max-width: 400px;
}

.person__empty > div {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  height: calc(100% - 108px);
}

.person__empty > div > div {
  max-height: 300px;
  margin: 0 auto;
  height: 100%;
}

.person__number {
  font-size: 22px;
  font-weight: 500;
  line-height: 46px;

  width: 46px;

  text-align: center;

  color: #cacaca;
  border: 2px solid #cacaca;
  border-radius: 50%;
}

.person__text .form__set {
  min-height: 50px;
}

@media screen and (max-width: 999px) {
  .person__text .form__set {
    min-height: 10px;
  }
}
