@import "../../styles/baseExt.css";

.Button {
  font-size: 0.93rem;
  font-weight: 500;
  box-sizing: border-box;
  border: 0;
  border-radius: 4px;
  outline: none;
  text-shadow: none;
  min-height: 38px;
  line-height: inherit;
  cursor: pointer;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  padding: 2px 16px 0 16px;

  transition: 0.125s ease-out;
}
.Button.Button_icon,
.Button__share {
  width: 24px;
  height: 24px;
  min-height: initial;
  border-radius: none;
  position: relative;
  padding: 0;
  background-color: transparent;
}
.Button__share {
  cursor: pointer;
  outline: none;
  width: 36px;
  height: 36px;
  margin: 0 12px;
  display: inline-block;
}
.Button__share > div {
  height: 100%;
}
.Button.Button__underline {
  padding-left: 5px;
  padding-right: 5px;
  min-width: initial;
}
.Button.Button__underline > svg {
  max-height: 25px;
}
.Button.Button__href {
  display: inline-block;
  line-height: 38px;
}
.Button.Button__restrain {
  min-width: 110px;
}

@media screen and (min-width: 1260px) {
  .Button.Button__restrain {
    min-width: 150px;
  }
  .Button.Button__underline {
    min-width: initial;
  }
}
.Button.Button__big {
  font-size: 20px;
  min-height: 48px;
}
.Button.Button__disabled {
  color: var(--badi-white);
  background: var(--badi-soft-grey);
  cursor: inherit;
}
.Button.Button__disabled:hover {
  background-color: var(--badi-soft-grey);
  box-shadow: none;
}
.Button.Button__disabled:active {
  border: none;
}
.Button.Button__underline.Button__disabled {
  color: var(--badi-soft-grey);
  background: none;
}
.Button.Button__underline.Button__disabled:hover {
  color: var(--badi-soft-grey);
  background-color: none;
}
.Button.Button__small {
  font-size: 0.86rem;
  min-height: 28px;
}
.Button.Button__light.Button__big {
  line-height: 44px;
}
.Button.Button__wide {
  width: 100%;
}
@media screen and (max-width: 480px) {
  .Button.Button__wide {
    padding: 0 1px;
  }
}

.Button__blue {
  color: var(--badi-white);
  background: var(--badi-booking-blue);
}
.Button__blue:hover {
  text-decoration: none;
  color: var(--badi-white);
  background: var(--badi-booking-blue);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.12);
}
.Button__blue:active {
  box-shadow: inset 0 2px 0 var(--badi-booking-blue-dark);
}
.Button__blue.Button__light {
  color: var(--badi-booking-blue);
  background: none;
  border: 2px solid var(--badi-booking-blue);
}
.Button__blue.Button__light:hover {
  box-shadow: none;
  color: var(--badi-booking-blue-dark);
  border-color: var(--badi-booking-blue-dark);
}
.Button__blue.Button__light:active {
  color: var(--badi-warm-grey);
  border-color: var(--badi-warm-grey);
}
.Button__blue.Button__link {
  background: none;
  color: var(--badi-booking-blue);
}
.Button__blue.Button__link:hover {
  color: var(--badi-booking-blue-dark);
  box-shadow: none;
}
.Button__blue.Button__link:active {
  border: none;
}
.Button__blue.Button__underline {
  background: none;
  color: var(--badi-booking-blue);
  text-decoration: underline;
  border-color: transparent;
}
.Button__blue.Button__underline > svg {
  fill: var(--badi-booking-blue);
}
.Button__blue.Button__underline:hover {
  background: none;
  color: var(--badi-booking-blue-dark);
  box-shadow: none;
  text-decoration: underline;
  border-color: transparent;
}
.Button__blue.Button__underline.Button__disabled:hover {
  color: var(--badi-soft-grey);
}
.Button__blue.Button__underline:active {
  border: none;
  box-shadow: none;
  border-color: transparent;
}

.Button__blue-sky {
  color: var(--badi-white);
  background: var(--badi-blue-sky);
}
.Button__blue-sky:hover {
  text-decoration: none;
  color: var(--badi-white);
  background: var(--badi-blue-sky);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.12);
}
.Button__blue-sky:active {
  box-shadow: inset 0 2px 0 var(--badi-blue-sky-dark);
}
.Button__blue-sky.Button__light {
  color: var(--badi-blue-sky);
  background: none;
  border: 2px solid var(--badi-blue-sky);
}
.Button__blue-sky.Button__light:hover {
  box-shadow: none;
  color: var(--badi-blue-sky-dark);
  border-color: var(--badi-blue-sky-dark);
}
.Button__blue-sky.Button__light:active {
  color: var(--badi-warm-grey);
  border-color: var(--badi-warm-grey);
}
.Button__blue-sky.Button__link {
  background: none;
  color: var(--badi-blue-sky);
}
.Button__blue-sky.Button__link:hover {
  color: var(--badi-blue-sky-dark);
  box-shadow: none;
}
.Button__blue-sky.Button__link:active {
  border: none;
}
.Button__blue-sky.Button__underline {
  background: none;
  color: var(--badi-blue-sky);
  text-decoration: underline;
  border-color: transparent;
}
.Button__blue-sky.Button__underline:hover {
  background: none;
  color: var(--badi-blue-sky-dark);
  box-shadow: none;
  text-decoration: underline;
  border-color: transparent;
}
.Button__blue-sky.Button__underline > svg {
  fill: var(--badi-blue-sky);
}
.Button__blue-sky.Button__underline.Button__disabled:hover {
  color: var(--badi-soft-grey);
}
.Button__blue-sky.Button__underline:active {
  border: none;
  box-shadow: none;
  border-color: transparent;
}

.Button__green {
  color: var(--badi-white);
  background: var(--badi-green);
}
.Button__green:hover {
  text-decoration: none;
  color: var(--badi-white);
  background: var(--badi-green);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.12);
}
.Button__green:active {
  box-shadow: inset 0 2px 0 var(--badi-green-dark);
}
.Button__green.Button__light {
  color: var(--badi-green);
  background: none;
  border: 2px solid var(--badi-green);
}
.Button__green.Button__light:hover {
  box-shadow: none;
  color: var(--badi-green-dark);
  border-color: var(--badi-green-dark);
}
.Button__green.Button__light:active {
  color: var(--badi-warm-grey);
  border-color: var(--badi-warm-grey);
}
.Button__green.Button__link {
  background: none;
  color: var(--badi-green);
}
.Button__green.Button__link:hover {
  color: var(--badi-green-dark);
  box-shadow: none;
}
.Button__green.Button__link:active {
  border: none;
}
.Button__green.Button__underline {
  background: none;
  color: var(--badi-green);
  text-decoration: underline;
  border-color: transparent;
}
.Button__green.Button__underline:hover {
  background: none;
  color: var(--badi-green-dark);
  box-shadow: none;
  text-decoration: underline;
  border-color: transparent;
}
.Button__green.Button__underline > svg {
  fill: var(--badi-green);
}
.Button__green.Button__underline:active {
  border: none;
  box-shadow: none;
  border-color: transparent;
}
.Button__green.Button__underline.Button__disabled:hover {
  color: var(--badi-soft-grey);
}

.Button__green-dark {
  color: var(--badi-white);
  background: var(--badi-green-dark);
}
.Button__green-dark:hover {
  text-decoration: none;
  color: var(--badi-white);
  background: var(--badi-green-dark);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.12);
}
.Button__green-dark:active {
  box-shadow: inset 0 2px 0 var(--badi-green-dark);
}
.Button__green-dark.Button__light {
  color: var(--badi-green-dark);
  background: none;
  border: 2px solid var(--badi-green-dark);
}
.Button__green-dark.Button__light:hover {
  box-shadow: none;
  color: var(--badi-green-dark);
  border-color: var(--badi-green-dark);
}
.Button__green-dark.Button__light:active {
  color: var(--badi-warm-grey);
  border-color: var(--badi-warm-grey);
}
.Button__green-dark.Button__link {
  background: none;
  color: var(--badi-green-dark);
}
.Button__green-dark.Button__link:hover {
  color: var(--badi-green-dark);
  box-shadow: none;
}
.Button__green-dark.Button__link:active {
  border: none;
}
.Button__green-dark.Button__underline {
  background: none;
  color: var(--badi-green-dark);
  text-decoration: underline;
  border-color: transparent;
}
.Button__green-dark.Button__underline:hover {
  background: none;
  color: var(--badi-green-dark);
  box-shadow: none;
  text-decoration: underline;
  border-color: transparent;
}
.Button__green-dark.Button__underline > svg {
  fill: var(--badi-green-dark);
}
.Button__green-dark.Button__underline:active {
  border: none;
  box-shadow: none;
  border-color: transparent;
}
.Button__green-dark.Button__underline.Button__disabled:hover {
  color: var(--badi-soft-grey);
}

.Button__grey {
  color: var(--badi-white);
  background: var(--badi-soft-grey);
}
.Button__grey:hover {
  text-decoration: none;
  color: var(--badi-white);
  background: var(--badi-soft-grey);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.12);
}
.Button__grey:active {
  box-shadow: inset 0 2px 0 var(--badi-warm-grey);
}
.Button__grey.Button__light {
  color: var(--badi-warm-grey);
  background: none;
  border: 2px solid var(--badi-soft-grey);
}
.Button__grey.Button__light:hover {
  box-shadow: none;
  color: var(--badi-warm-grey);
  border-color: var(--badi-warm-grey);
}
.Button__grey.Button__light:active {
  color: var(--badi-warm-grey);
  border-color: var(--badi-warm-grey);
}
.Button__grey.Button__link {
  background: none;
  color: var(--badi-warm-grey);
}
.Button__grey.Button__link:hover {
  color: var(--badi-dark-grey);
  box-shadow: none;
}
.Button__grey.Button__link:active {
  border: none;
}
.Button__grey.Button__underline {
  background: none;
  color: var(--badi-warm-grey);
  text-decoration: underline;
  border-color: transparent;
}
.Button__grey.Button__underline > svg {
  fill: var(--badi-warm-grey);
}
.Button__grey.Button__underline:hover {
  color: var(--badi-dark-grey);
  box-shadow: none;
  text-decoration: underline;
  border-color: transparent;
}
.Button__grey.Button__underline:active {
  border: none;
  box-shadow: none;
  border-color: transparent;
}
.Button__grey.Button__underline.Button__disabled:hover {
  color: var(--badi-soft-grey);
}

.Button__purple {
  color: var(--badi-white);
  background: var(--badi-purple);
}
.Button__purple:hover {
  text-decoration: none;
  color: var(--badi-white);
  background: var(--badi-purple-dark);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.12);
}
.Button__blue:active {
  box-shadow: inset 0 2px 0 var(--badi-booking-blue-dark);
}

.Button__yellow {
  color: var(--badi-white);
  background: var(--badi-yellow);
}
.Button__yellow:hover {
  text-decoration: none;
  color: var(--badi-white);
  background: var(--badi-yellow);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.12);
}
.Button__yellow:active {
  box-shadow: inset 0 2px 0 var(--badi-yellow-dark);
}
.Button__yellow.Button__light {
  color: var(--badi-yellow);
  background: none;
  border: 2px solid var(--badi-yellow);
}
.Button__yellow.Button__light:hover {
  box-shadow: none;
  color: var(--badi-yellow-dark);
  border-color: var(--badi-yellow-dark);
}
.Button__yellow.Button__light:active {
  color: var(--badi-warm-grey);
  border-color: var(--badi-warm-grey);
}
.Button__yellow.Button__underline {
  background: none;
  color: var(--badi-yellow);
  text-decoration: underline;
  border-color: transparent;
}
.Button__yellow.Button__underline > svg {
  fill: var(--badi-yellow);
}
.Button__yellow.Button__underline:hover {
  background: none;
  color: var(--badi-yellow-dark);
  box-shadow: none;
  text-decoration: underline;
  border-color: transparent;
}
.Button__yellow.Button__underline:active {
  border: none;
  box-shadow: none;
  border-color: transparent;
}
.Button__yellow.Button__underline.Button__disabled:hover {
  color: var(--badi-soft-grey);
}

.Button__white {
  color: var(--badi-dark-grey);
  background: var(--badi-white);
}

.Button__white.Button__disabled,
.Button__white.Button__disabled:hover {
  color: var(--badi-soft-grey);
  background: var(--badi-white);
}

.Button__white:hover {
  text-decoration: none;
  color: var(--badi-dark-grey);
  background: var(--badi-white);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.12);
}
.Button__white:active {
  box-shadow: inset 0 2px 0 var(--badi-dark-grey);
}
.Button__white.Button__light {
  color: var(--badi-white);
  background: none;
  border: 2px solid var(--badi-white);
}
.Button__white.Button__light:hover {
  box-shadow: none;
}
.Button__white.Button__light:active {
  color: var(--badi-warm-grey);
  border-color: var(--badi-warm-grey);
}

.Button__white.Button__light,
.Button__white.Button__light:active,
.Button__white.Button__light:hover {
  color: white;
  background: none;
  border: 2px solid white;
}
.Button__white.Button__link:hover {
  box-shadow: none;
}
.Button__white.Button__link:active {
  border: none;
}

.Button__pink {
  color: var(--badi-white);
  background: var(--badi-pink);
}
.Button__pink:hover {
  text-decoration: none;
  color: var(--badi-white);
  background: var(--badi-pink);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.12);
}
.Button__pink:active {
  box-shadow: inset 0 2px 0 var(--badi-pink-dark);
}
.Button__pink.Button__light {
  color: var(--badi-pink);
  background: none;
  border: 2px solid var(--badi-pink);
}
.Button__pink.Button__light:hover {
  box-shadow: none;
  color: var(--badi-pink-dark);
  border-color: var(--badi-pink-dark);
}
.Button__pink.Button__light:active {
  color: var(--badi-warm-grey);
  border-color: var(--badi-warm-grey);
}
.Button__pink.Button__link {
  background: none;
  color: var(--badi-pink);
}
.Button__pink.Button__link:hover {
  color: var(--badi-pink-dark);
  box-shadow: none;
}
.Button__pink.Button__link:active {
  border: none;
}
.Button__pink.Button__underline {
  background: none;
  color: var(--badi-pink);
  text-decoration: underline;
  border-color: transparent;
}
.Button__pink.Button__underline:hover {
  background: none;
  color: var(--badi-pink-dark);
  box-shadow: none;
  text-decoration: underline;
  border-color: transparent;
}
.Button__pink.Button__underline > svg {
  fill: var(--badi-pink);
}
.Button__pink.Button__underline.Button__disabled:hover {
  color: var(--badi-soft-grey);
}
.Button__pink.Button__underline:active {
  border: none;
  box-shadow: none;
  border-color: transparent;
}

.Button__icon {
  width: 19px;
  height: 19px;
  max-height: 19px;
  transition: 0.125s ease-out;
  vertical-align: middle;
}
.Button__icon + .Button__text {
  margin: 0 0 0 12px;
}

.Button.Button_icon > .Button__icon {
  width: 24px;
  height: 24px;
  max-height: 24px;
}

.Button__gradient {
  background-image: var(--badi-gradient-background-image);
}

.Button__gradient:hover {
  background-image: var(--badi-gradient-background-image);
}

.Button__gradient:active {
  box-shadow: inset 0 2px 0 var(--badi-purple-dark);
}

.Link__button {
  min-height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;

  color: var(--badi-white);
  background: var(--badi-green);
  min-width: 150px;
  font-size: 0.93rem;
  font-weight: 500;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  margin: 32px 0 0 0;
  text-decoration: none;
  padding: 2px 16px 0 16px;
}

.Link__button:active {
  box-shadow: inset 0 2px 0 var(--badi-green-dark);
}

.Link__button:hover {
  text-decoration: none;
  color: var(--badi-white);
  background: var(--badi-green);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.12);
}

.Link__button.Button__light {
  color: var(--badi-green);
  background: none;
  border: 2px solid var(--badi-green);
}

.Link__button.Button__light:hover {
  text-decoration: none;
  color: var(--badi-green);
  box-shadow: none;
}

@media screen and (max-width: 1050px) {
  .Button__share {
    margin: 0 7px;
  }
}

@media screen and (max-width: 320px) {
  .Button__share {
    margin: 0 9px;
  }
}

.Button__img {
  display: flex;
  outline: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: 0px;
}
