@import "../../styles/baseExt.css";
@import "../../../../node_modules/react-dates/lib/css/_datepicker.css";

.SingleDatePicker {
  display: block;
}

.SingleDatePicker_picker__portal {
  z-index: 2001;
}

.SingleDatePickerInput__withBorder {
  border: 0px;
  display: block;
}

.DateInput {
  background: transparent;
}

.DateInput_input {
  box-sizing: border-box;
  border: 0;
  color: var(--badi-dark-grey);
  font-weight: 500;
  font-size: 1rem;
  background: transparent;
  padding: 0 0 0 14px;
  min-height: 36px;
}

.DateInput_input__disabled {
  color: rgb(221, 221, 221) !important;
}

.CalendarDay {
  outline: none;
}

.CalendarDay__blocked_calendar {
  background: #cacccd;
  color: #82888a;
  cursor: default;
  border: 1px solid #e4e7e7;

  &:hover {
    border: 1px solid #e4e7e7;
  }
}

.CalendarDay__blocked_out_of_range {
  color: #cacccd;
  background: #fff;
  border: 1px solid #e4e7e7;
  cursor: default;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #0edc84;
  border: 1px double #0edc84;
  color: #fff;
}
