@import "../../../styles/baseExt.css";

.Form {
  &__Input {
    &--wrapper {
      box-sizing: border-box;
      margin-bottom: 16px;

      input {
        box-sizing: border-box;
        padding: 0 15px;
        font-size: 1rem;
        color: #bbb;
        width: 100%;
        height: 48px;
        max-height: 48px;
        line-height: 48px;
        border: 1px solid #bbb;
        border-radius: 4px;

        &:focus {
          color: var(--badi-dark-grey);
          border: 1px solid var(--badi-dark-grey);
        }
      }
    }

    &--input-active > input {
      color: var(--badi-dark-grey);
      border: 1px solid var(--badi-dark-grey);
    }
  }

  &__InputWithLabel {
    &--wrapper {
      position: relative;
      margin-bottom: 16px;
      display: flex;
      border: 1px solid #bbb;
      border-radius: 4px;
      max-height: 48px;
      padding: 5px;

      &-active {
        color: var(--badi-dark-grey);
        border: 1px solid var(--badi-dark-grey);
      }

      input {
        font-size: 1rem;
        border: none;
        flex-grow: 1;
        padding: 10px;
        flex-shrink: 1;
        width: calc(100% - 80px);
      }
    }

    &--input-active {
      color: var(--badi-dark-grey);
      border: 1px solid var(--badi-dark-grey);
    }

    &--label {
      flex-grow: 0;
      display: flex;
      align-items: center;
      padding-right: 10px;
      flex-shrink: 0;
      min-width: 80px;
    }
  }
}
