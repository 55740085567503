$media-breakpoint-sm: 576px;
$media-breakpoint-md: 768px;
$media-breakpoint-lg: 992px;
$media-breakpoint-xl: 1200px;
$media-breakpoint-xxl: 1440px;

:root {
  --main-bg-color: brown;
  --badi-error: #fb4848;
  --badi-white: #ffffff;
  --badi-light-grey: #eeeeee;
  --badi-soft-grey: #bbbbbb;
  --badi-new-soft-grey: #dddddd;
  --badi-warm-grey: #757575;
  --badi-mid-grey: #999999;
  --badi-dark-grey: #333333;
  --badi-blue: #254cef;
  --badi-blue-dark: #2a48c8;
  --badi-blue-sky: #5ddefa;
  --badi-blue-sky-dark: #2bc8ea;
  --badi-booking-blue: #489cff;
  --badi-booking-blue-dark: #3a87e1;
  --badi-main-color: #1e3255;

  --badi-green: #0aeb8b;
  --badi-green-dark: #08c781;
  --badi-pink: #ff3888;
  --badi-pink-dark: #da266e;
  --badi-purple: #943ffa;
  --badi-purple-dark: #7429ce;
  --badi-yellow: #fed759;
  --badi-yellow-dark: #ffc947;
  --badi-orange: #ff8800;
  --badi-teal: #2eb0a8;
  --badi-gradient-background-image: linear-gradient(
    318deg,
    #ff3888,
    #943ffa 34%,
    #5e7bf0 48%,
    #3aa4e9 71%,
    #38fea1
  );
  --badi-new-gradient: linear-gradient(to right, #08c781, #00bfb3);
  --badi-font-family: "modelica", sans-serif;
  --badi-font-size: 16px;
  --badi-line-height: 24px;
  --badi-brand-secondary-default: #6d8bff;
}
