@import "../../../../../../styles/baseExt.css";

.ReducedFilter {
  max-width: 125px;
  height: 32px;
  padding: 0 12px;
  border-radius: 16px;
  border: solid 1px var(--badi-soft-grey);
  box-sizing: border-box;

  background-color: var(--badi-white);
  display: flex;
  align-items: center;

  > button {
    cursor: pointer;
    display: flex;
    padding: 0;
    border: none;
    margin-left: 8px;
    max-width: 16px;
    background: transparent;
  }

  &__Text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ReducedFilter + .ReducedFilter {
  margin-left: 8px;
}
