@import "../../styles/baseExt.css";

.Security {
  &__Content {
    ul > li > .Security__ListItem_Trust {
      background-color: var(--badi-purple);
      background-image: none;

      &:after {
        content: counter(info-list-counter);
        color: white;
        font-weight: 700;
        font-size: 1.2rem;
      }
    }
  }

  &__LandingImage {
    width: 100%;
    height: 375px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @media screen and (min-width: $media-breakpoint-md) {
      height: 432px;
    }

    @media screen and (min-width: $media-breakpoint-lg) {
      height: 576px;
    }
  }

  &__Title {
    margin-top: -200px;
  }
}
