@import "baseExt.css";
@import "fontsExt.css";

.search__inner,
.list__inner,
.features__inner,
.apps__inner,
.sitemap__inner,
.install__inner {
  position: relative;

  box-sizing: border-box;
  min-width: 320px;
  max-width: 1126px;
  margin: 0 auto;
  padding: 0 16px;
}
@media screen and (max-width: 480px) {
  .search__inner,
  .list__inner,
  .features__inner,
  .apps__inner,
  .sitemap__inner,
  .install__inner {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.list__prev,
.list__next,
.list__action {
  font-size: inherit;
  font-weight: inherit;

  margin: 0;
  padding: 0;

  cursor: pointer;

  border: none;
  outline: none;
  background: none;
}
.browser_ff .list__prev,
.browser_ff .list__next,
.browser_ff .list__action {
  font-family: Arial, sans-serif;
}

.search {
  margin: 73px 0 25px;
}
@media screen and (max-width: 999px) {
  .search {
    margin-top: 40px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 600px) {
  .search {
    margin-top: 32px;
    margin-bottom: 6px;
  }
}

.search__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  padding: 0 0 28px;

  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-line-pack: end;
  align-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
@media screen and (max-width: 762px) {
  .search__content {
    padding-bottom: 16px;
  }
}
.lt-ie10 .search__content {
  text-align: right;
}

.search__title {
  font-weight: 700;
  font-size: 42px;

  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
[lang="en"] .search__title {
  font-size: 45px;
}
[lang="es"] .search__title {
  font-size: 41px;
}
[lang="it"] .search__title {
  font-size: 38px;
}
[lang="ca"] .search__title {
  font-size: 40px;
}
@media screen and (max-width: 999px) {
  .search__title,
  [lang="en"] .search__title,
  [lang="es"] .search__title,
  [lang="ca"] .search__title {
    font-size: 29px;
  }

  [lang="fr"] .search__title,
  [lang="it"] .search__title {
    font-size: 27px;
  }
}
@media screen and (max-width: 762px) {
  .search__title,
  [lang="en"] .search__title,
  [lang="es"] .search__title,
  [lang="ca"] .search__title,
  [lang="fr"] .search__title,
  [lang="it"] .search__title {
    font-size: 24px;
  }
}
@media screen and (max-width: 600px) {
  .search__title,
  [lang="en"] .search__title,
  [lang="es"] .search__title,
  [lang="ca"] .search__title,
  [lang="fr"] .search__title,
  [lang="it"] .search__title {
    line-height: 1.4;
    font-size: 18px;
  }
}
.lt-ie10 .search__title {
  font-size: 38px;

  float: left;

  width: 600px;
  padding-top: 30px;

  text-align: left;
}

.search__view {
  margin: 0 0 10px 20px;

  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
@media screen and (max-width: 762px) {
  .search__view {
    display: none;
  }
}

.search__badis {
  width: 393px;
  height: 112px;

  vertical-align: bottom;
}

.search__panel {
  position: relative;
}

.search__button {
  position: absolute;
  top: 0;
  right: 0;

  width: 44px;
  height: 44px;
  margin: 0;
  padding: 0;

  cursor: pointer;

  border: 0;
  outline: none;
  background: none;
}
.lt-ie10 .search__button {
  top: 18px;
}

.search__ico {
  width: 25px;
  height: 22px;
}

.list {
  position: relative;

  margin: 55px 0 0 0;
}
.layout__search_map .list:last-child {
  margin-bottom: 90px;
}
@media screen and (max-width: 999px) {
  .list {
    margin-top: 40px;
  }
}
@media screen and (max-width: 600px) {
  .list {
    margin-top: 30px;
  }
}
.list_popup {
  position: relative;

  overflow: hidden;

  width: 392px;
  height: 469px;
  margin: 0;
}

@media screen and (max-width: 400px) {
  .list_popup {
    width: 352px;
  }
}

.list__slider {
  width: 100%;
  height: 100%;
}

.layout__results .list__inner {
  margin-left: 0;
  padding-left: 40px;
}

.list__title {
  position: relative;
  margin-bottom: 20px;
}
.list__title:after {
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 36px;
  height: 3px;
  content: "";
  background: var(--badi-dark-grey);
}
.layout__results .list__title {
  font-size: 20px;
}
@media screen and (max-width: 600px) {
  .list__title {
    font-size: 18px;
  }
}

.list__direction {
  position: relative;

  margin: 16px 48px 16px 0;

  text-align: right;
}
.list_popup .list__direction {
  position: absolute;
  z-index: 50;
  top: 0;
  right: 0;
  left: 0;

  margin: 0;
}

.list__prev,
.list__next {
  width: 18px;
  height: 13px;

  -webkit-transition: opacity 0.25s ease-out;
  transition: opacity 0.25s ease-out;
  vertical-align: top;

  background: url("assets/icons/arrow_next_dark.svg") no-repeat 50% 50%;
  background-size: cover;
}
.list__prev_hidden,
.list__next_hidden {
  cursor: default;

  visibility: hidden;
}
.list_popup .list__prev,
.list_popup .list__next {
  position: absolute;
  top: 116px;
  right: 10px;

  width: 32px;
  height: 47px;

  background-image: url("assets/icons/arrow_right_white.svg");
}

.list__prev {
  float: left;

  background-image: url("assets/icons/arrow_prev_dark.svg");
}
.list_popup .list__prev {
  right: auto;
  left: 10px;

  background-image: url("assets/icons/arrow_left_white.svg");
}

.list__list {
  position: relative;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  margin: -10px 0 0 32px;
  padding: 0;

  list-style: none;

  -webkit-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;

  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.list__list_over {
  z-index: 10;

  margin-right: -44px;
  margin-left: -74px;
}
.list__flow .list__list {
  display: block;

  margin-right: 0;
  margin-left: 0;

  -webkit-transition: -webkit-transform 0.25s ease-out;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition:
    transform 0.25s ease-out,
    -webkit-transform 0.25s ease-out;
  white-space: nowrap;
}
.list_popup .list__list {
  margin: 0 !important;
}
@media screen and (max-width: 999px) {
  .list__list {
    display: block;
    overflow: auto;

    margin: -20px -16px -10px 0 !important;
    padding: 10px 0 10px 16px;

    white-space: nowrap;
  }
  .list__flow .list__list {
    overflow: visible;

    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .list_popup .list__list {
    padding: 0;
  }
}
@media screen and (max-width: 480px) {
  .list__list {
    margin-right: -8px !important;
  }
  .list__flow .list__list {
    margin-right: 0 !important;
  }
}

.list__item {
  position: relative;

  display: inline-block;

  width: 24.3%;
  margin: 40px 4px 0 0;

  white-space: normal;
}
.list__item_triple {
  width: 32.7%;
}
.list__item_double {
  width: 49.5%;
}
.list__item_room {
  height: 284px;

  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.list__list_over .list__item_room {
  width: 21.3%;
}
.list__list_over .list__item_room.list__item_active {
  width: 33.6%;
}
.list_popup .list__item {
  height: 409px !important;

  background: #fff;
}
.list__flow .list__item {
  display: inline-block;

  width: 258px;
  margin-top: 0;

  vertical-align: top;
}
.list_popup .list__flow .list__item {
  width: 392px !important;
}
@media screen and (max-width: 400px) {
  .list_popup .list__flow .list__item {
    width: 352px !important;
    margin-right: 0;
  }
}
@media screen and (max-width: 999px) {
  .list__item {
    display: inline-block;

    width: 23.5vw !important;
    min-width: 180px;
    margin-top: 20px;
    margin-right: 4px;

    vertical-align: top;
  }
  .list__item_triple {
    width: 270px !important;
  }
  .list__item_double {
    width: 360px !important;
  }
  .list__item_room {
    height: 209px;

    background: #e1e1e1;
  }
  .list__flow .list__item {
    width: 180px !important;
  }
}
.list__item_active {
  z-index: 300;
}

.list__flow .list__list_over .list__item_room {
  width: 284px;
}
.list__flow .list__list_over .list__item_room.list__item_active {
  width: 370px;
}

.list__box {
  position: relative;
  display: block;
  text-decoration: none;
  color: var(--badi-dark-grey);
}
.list__item_room .list__box {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;

  border: 2px solid #e1e1e1;
  background: #fff;
}
.list_popup .list__item_room .list__box {
  top: 0 !important;

  margin: 0 !important;

  border: 0;
}
@media screen and (max-width: 999px) {
  .list__item_room .list__box {
    position: relative !important;
    z-index: 1;
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;

    -webkit-transition: none;
    transition: none;

    box-shadow: none !important;
  }
}
.list__item_active .list__box {
  z-index: 5;
  top: -34px;
  bottom: -90px;
}
.list__box:hover {
  text-decoration: none;

  color: #494949;
}
@media screen and (max-width: 999px) {
  .list__item_room .list__box {
    position: relative !important;
    z-index: 1;
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;

    -webkit-transition: none;
    transition: none;

    box-shadow: none !important;
  }
  .list__item_active .list__box {
    position: fixed !important;
    z-index: 5;
    top: 50% !important;
    left: 50% !important;

    width: 412px !important;
    height: 408px !important;
    margin: -204px 0 0 -206px !important;
  }
}
@media screen and (max-width: 600px) {
  .list__item_active .list__box {
    width: 310px !important;
    height: 408px !important;
    margin: -204px 0 0 -155px !important;
  }
}

.list__cover {
  position: relative;

  height: 170px;
  margin: 0 0 10px;

  background-color: #e1e1e1;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}
.list__cover:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: "";
  -webkit-transition: opacity 0.25s ease-out;
  transition: opacity 0.25s ease-out;

  opacity: 0;
  background: #000;
}
.list__item_room .list__cover {
  height: 190px;

  -webkit-transition: height 0.25s ease-out;
  transition: height 0.25s ease-out;
}
.list__item_room .list__cover:before {
  display: none;
}
.list__item_active .list__cover {
  height: 224px !important;
}
.list_popup .list__cover {
  height: 244px !important;
}
.list__box:hover .list__cover:before {
  opacity: 0.2;
}
@media screen and (max-width: 999px) {
  .list__cover {
    height: 120px;
  }
  .list__item_room .list__cover {
    height: 135px;

    -webkit-transition: none;
    transition: none;
  }
}

@media screen and (max-width: 999px) {
  .list__label {
    font-weight: 700;
  }
}

.list__beta {
  font-size: 16px;
  margin-left: 5px;
}
@media screen and (max-width: 999px) {
  .list__beta {
    font-size: 13px;
  }
}

.list__badge {
  position: absolute;
  top: -10px;
  right: 12px;

  width: 42px;
  height: 37px;

  -webkit-transition: top 0.25s ease-out;
  transition: top 0.25s ease-out;

  background: url("assets/icons/badge.svg") no-repeat 0 0;
  background-size: cover;
}
.list__badge:before {
  position: absolute;
  top: 7px;
  left: 14px;

  width: 15px;
  height: 15px;

  content: "";

  background: url("assets/icons/ico_star.svg") no-repeat 0 0;
  background-size: cover;
}
.list__badge_like:before {
  top: 7px;
  left: 13px;

  width: 18px;
  height: 15px;

  background-image: url("assets/icons/ico_favorite.svg");
  background-size: cover;
}
.list__item_active .list__badge,
.list_popup .list__badge {
  top: 14px;
}

.list__info {
  box-sizing: border-box;
  height: 80px;
  padding: 5px 0 5px 12px;

  background: #fff;
}

.list_popup .list__info {
  padding: 12px 0 5px 18px;
}

@media screen and (max-width: 999px) {
  .list__info {
    font-size: 13px;

    height: 60px;
  }
}

@media screen and (max-width: 999px) {
  .list__bg {
    position: fixed;
    z-index: 2;
    top: 100%;
    right: 0;
    bottom: 0;
    left: 0;

    -webkit-transition: opacity 0.25s ease-out;
    transition: opacity 0.25s ease-out;

    opacity: 0;
    background: #000;
  }
  .list__item_active .list__bg {
    top: 0;

    opacity: 0.5;
  }
}

@media screen and (max-width: 999px) {
  .list__close {
    position: fixed;
    top: 66px;
    right: 16px;

    width: 48px;
    height: 48px;

    cursor: pointer;

    opacity: 0;
    border-radius: 50%;
    background: #fff url("assets/icons/ico_close.svg") no-repeat 50% 50%;
  }
  .list__item_active .list__close {
    z-index: 40;

    -webkit-transition: opacity 0.25s 0.25s ease-out;
    transition: opacity 0.25s 0.25s ease-out;

    opacity: 1;
  }
}

.list__user {
  font-size: 16px;

  position: relative;

  overflow: hidden;

  height: 80px;
}
@media screen and (max-width: 999px) {
  .list__user {
    height: 50px;
  }
}
.list__item_active .list__user,
.list_popup .list__user {
  height: auto;
}

.list__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.lt-ie10 .list__content {
  display: table;

  width: 100%;
}

.list__city {
  font-size: 13px;
}

.list__price {
  font-size: 18px;
}

.list_popup .list__price {
  font-size: 19px;
  padding-top: 11px;
  font-weight: bold;
}

@media screen and (max-width: 999px) {
  .list__price {
    font-size: 12px !important;
  }
}

.list__about {
  font-size: 1rem;
  line-height: 1.15;

  overflow: hidden;

  height: 50px;
  margin: 10px 0 0;
}

.list_popup .list__about {
  font-weight: 500;
  line-height: 1.4;
  height: 70px;
}

.list__actions {
  margin: 10px 0 0;
}

.list__action {
  display: inline-block;

  width: 22px;
  height: 22px;
  margin: 0 10px 0 0;

  background: no-repeat 50% 50%;
}
.list__action_send {
  background-image: url("assets/icons/ico_send_primary.svg");
}
.list__action_send:hover {
  background-image: url("assets/icons/ico_send_primary_hover.svg");
}
.list__action_load {
  background-image: url("assets/icons/ico_load.svg");
}
.list__action_load:hover {
  background-image: url("assets/icons/ico_load_green.svg");
}
.list__action_like {
  background-image: url("assets/icons/ico_like.svg");
}
.list__action_like:hover {
  background-image: url("assets/icons/ico_like_green.svg");
}

.list__text {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow: hidden;
}
.lt-ie10 .list__text {
  display: table-cell;

  vertical-align: top;
}

.list__profile {
  min-width: 80px;
  margin-right: -8px;
  margin-left: 16px;
  padding: 0 10px;

  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
  text-align: center;

  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.list__item_active .list__profile,
.list_popup .list__profile {
  margin-right: 8px;
}
.lt-ie10 .list__profile {
  display: table-cell;

  vertical-align: top;
}

.list__details {
  margin: 10px 0 0;

  text-align: center;
}

.list__go {
  font-size: 13px;

  position: relative;

  display: inline-block;

  padding-right: 10px;

  text-decoration: underline;

  color: #cbcbcb;
}
.list__go:after {
  position: absolute;
  top: 4px;
  right: -3px;

  width: 7px;
  height: 11px;

  content: "";

  background: url("assets/icons/ico_next.svg") no-repeat 0 0;
}
.list__go:hover {
  text-decoration: none;

  color: #cbcbcb;
}

.list__about,
.list__actions,
.list__profile .human__about,
.list__details {
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;

  opacity: 0;
}
.list__item_active .list__about,
.list_popup .list__about,
.list__item_active .list__actions,
.list_popup .list__actions,
.list__item_active .list__profile .human__about,
.list_popup .list__profile .human__about,
.list__item_active .list__details,
.list_popup .list__details {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;

  opacity: 1;
}

.features {
  position: relative;

  margin-top: 70px;
}
.features:last-child {
  margin-bottom: 70px;
}
@media screen and (max-width: 999px) {
  .features {
    margin: 40px 0;
  }
}
@media screen and (max-width: 600px) {
  .features {
    margin: 30px 0;
  }
}

.features__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  margin: 0 0 0 32px;
  padding: 0;

  list-style: none;

  text-align: center;

  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
@media screen and (max-width: 999px) {
  .features__list {
    margin-left: 0;
  }
}
@media screen and (max-width: 762px) {
  .features__list {
    display: block;
  }
}

.features__item {
  display: inline-block;

  box-sizing: border-box;
  width: 32%;

  text-align: left;
  vertical-align: top;

  border: 2px solid #e1e1e1;
}
@media screen and (max-width: 762px) {
  .features__item {
    width: 45%;
    margin: 0 10px 20px;
  }
}
@media screen and (max-width: 600px) {
  .features__item {
    font-size: 14px;

    width: 300px;
  }
}
@media screen and (max-width: 480px) {
  .features__item {
    display: block;

    width: auto;
    margin: 0 0 20px;
  }
}

.features__box {
  padding: 32px 24px 16px;
}

.features__cover {
  text-align: center;
}

.features__label {
  text-align: center;
}
@media screen and (max-width: 600px) {
  .features__label {
    font-size: 15px;
    font-weight: 700;

    margin-bottom: 10px;
  }
}

.features__view {
  height: 177px;
}
@media screen and (max-width: 999px) {
  .features__view {
    height: 120px;
  }
}
@media screen and (max-width: 600px) {
  .features__view {
    height: 112px;
  }
}

.apps {
  margin: 70px 0 0;

  background: url("assets/images/bg_city.svg") repeat-x 50% 100%;
}
@media screen and (max-width: 999px) {
  .apps {
    background-size: cover;
  }
}
.lt-ie10 .apps .apps__inner {
  display: table;

  width: 100%;
}

.apps__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-line-pack: end;
  align-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.apps__phones,
.apps__connect {
  position: relative;

  width: 48%;
}
.lt-ie10 .apps__phones,
.lt-ie10 .apps__connect {
  display: table-cell;

  vertical-align: bottom;
}

@media screen and (max-width: 600px) {
  .apps__phones {
    display: none;
  }
}

.apps__connect {
  padding-bottom: 90px;
}
@media screen and (max-width: 762px) {
  .apps__connect {
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 762px) and (max-width: 762px) {
  .apps__connect {
    width: 52%;
    padding-left: 10px;
  }
}
@media screen and (max-width: 600px) {
  .apps__connect {
    width: 100%;
    padding: 15px 0;

    text-align: center;
  }
}
.lt-ie10 .apps__connect {
  padding-left: 20px;
}

.apps__buttons {
  margin-top: 40px;
}
@media screen and (max-width: 999px) {
  .apps__buttons {
    margin-top: 20px;
  }
}
@media screen and (max-width: 600px) {
  .apps__buttons {
    margin-top: 15px;
  }
}

.apps__button {
  width: 183px;

  vertical-align: bottom;
}
@media screen and (max-width: 999px) {
  .apps__button {
    width: 138px;
  }
}
.lt-ie10 .apps__button {
  height: 52px;
}

.apps__phone {
  width: 55%;

  vertical-align: bottom;
}
.apps__phone_apple {
  position: absolute;
  right: 0;
  bottom: 0;
}

.sitemap {
  padding: 55px 0 20px;
}
.sitemap_cities {
  padding: 20px 0 40px;

  border-top: 2px solid var(--badi-light-grey);
}
@media screen and (max-width: 762px) {
  .sitemap {
    padding: 5px 0;
  }
  .sitemap_cities {
    padding: 20px 0;
  }
}

.sitemap__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.sitemap_cities .sitemap__inner {
  display: block;
}

.sitemap__box {
  display: inline-block;

  width: 20%;

  vertical-align: top;
}
@media screen and (max-width: 762px) {
  .sitemap__box {
    width: 50%;
    margin: 15px 0;
  }
}
.lt-ie10 .sitemap__box {
  width: 24%;
}

.sitemap__label {
  margin: 0 0 20px;
}
.sitemap__connect .sitemap__label {
  font-weight: 700;

  max-width: 440px;
}
@media screen and (max-width: 999px) {
  .sitemap__connect .sitemap__label {
    max-width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .sitemap__connect .sitemap__label {
    margin-bottom: 15px;
  }
}

.sitemap__list {
  margin: 0;
  padding: 0;

  list-style: none;
}
.sitemap_cities .sitemap__list {
  margin: -10px -8px 0;
}

.sitemap__item {
  margin: 12px 0 0;
}
.sitemap_cities .sitemap__item {
  display: inline-block;

  margin: 10px 0 0;
  padding: 0 8px;
}
.sitemap_cities .sitemap__item:last-child {
  margin-right: auto;
}

.sitemap__link {
  color: #494949;
}
.sitemap__link:hover {
  color: #494949;
}

.install {
  position: fixed;
  z-index: -1;
  right: 100%;
  bottom: 0;
  left: 0;

  visibility: hidden;

  height: 42px;

  background: rgba(0, 0, 0, 0.8);
}
@media screen and (max-width: 999px) {
  .install {
    z-index: 90;
    right: 0;

    visibility: visible;

    opacity: 1;
  }
}

.install__inner {
  display: block;

  background-color: #0edc84;
  color: #ffffff;
}
.install__inner:hover {
  color: #1a724d;
  text-decoration: none;
}

.install__ico {
  position: absolute;
  top: 5px;
  right: 8px;

  width: 106px;
  height: 32px;
}
