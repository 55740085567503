@import "../../../styles/baseExt.css";

.Profile {
  &__form {
    display: flex;
    flex-direction: column;
    z-index: 1;

    &--body {
      flex-grow: 1;
      margin-bottom: 90px;
    }

    &--pictures-wrapper {
      z-index: 0;
    }

    &--pictures {
      height: calc(100vh - 260px);
    }

    &--description > textarea {
      height: 140px;
    }

    &--controls {
      padding: 30px 0;
      background-color: var(--badi-white);
      border-top: 1px solid var(--badi-light-grey);

      > div {
        display: flex;
        justify-content: flex-end;
        padding: 0;
      }
    }

    &--bottom {
      z-index: 1;
    }

    &--header-mobile {
      display: none !important;
    }
  }

  form {
    width: 100%;
  }
}

@media screen and (max-width: 990px) {
  .Profile {
    &__form {
      order: 2;

      &--pictures-wrapper {
        position: relative;
      }

      &--body {
        max-height: initial;
        height: auto;

        .Profile__form--title {
          display: none;
        }
      }

      &--description {
        margin-bottom: 60px;
      }

      &--controls {
        padding: 15px;
        border-top: none;

        background-color: white;

        &:first-child {
          display: none;
        }
      }

      &--bottom {
        border-top: 1px solid var(--badi-light-grey);
      }

      &--pictures {
        padding-bottom: 40px;
        height: auto;
      }

      &--header-mobile {
        display: flex !important;

        button {
          background-color: transparent;
          border: none;
          padding: 8px 0 16px 0;
        }

        .Profile__form--title {
          max-width: 280px;
        }
      }
    }
  }
}
