@import "../../styles/baseExt.css";

.ContextualButton {
  display: flex;
  align-items: center;

  padding: 0;

  text-transform: uppercase;
  text-align: left;
  color: var(--badi-dark-grey);

  background: transparent;
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.ContextualButton__Icon {
  display: block;
  flex: 0 0 32px; /* fixes the icon size in small screens */
}
