@import "../../styles/baseExt.css";

/* utils */

.hide-sm {
  @media screen and (max-width: $media-breakpoint-sm) {
    display: none;
  }
}

/* end utils */

.Inbox {
  &--Empty {
    padding-left: 0;
    padding-right: 0;

    & > div {
      text-align: center;
      max-width: 570px;
    }
  }

  &--Pagination {
    margin-top: 2rem;
    margin-bottom: 4rem;

    &-pages {
      display: flex;
      justify-content: center;
    }

    &-overview {
      margin-bottom: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 0;

      @media screen and (min-width: $media-breakpoint-md) {
        margin-bottom: 0;
        justify-content: flex-end;
      }
    }
  }
}

.truncate {
  width: calc(100% - 64px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
