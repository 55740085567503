@import "../../../../styles/baseExt.css";

.LandingTitle {
  width: 100%;

  h1 {
    font-size: 2rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #ffffff;

    @media screen and (min-width: $media-breakpoint-md) {
      font-size: 2.5rem;
      line-height: 1.2;
    }

    @media screen and (min-width: $media-breakpoint-xl) {
      font-size: 3.5rem;
      line-height: 1.14;
    }
  }
}
