@import "../../styles/baseExt.css";

.UserCard {
  background-color: white;
}
.UserCard__wrapper {
  padding: 0 15px 15px;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 1.33rem;
    padding: 16px 0;
    margin: 0;
  }
}
.UserCard__block {
  margin-bottom: 24px;
  position: relative;

  > h3 {
    font-size: 1.2rem;
  }
}

.UserCard__metrics {
  margin-top: 30px;
  display: flex;
  width: 90%;
  justify-content: space-between;
}

.metric {
  padding: 0 10px;
  border-left: 2px solid var(--badi-booking-blue);
  min-width: 50%;

  p:first-child {
    font-weight: 700;
  }

  p {
    margin: 0;
  }
}

.UserCard__metrics.myProfile {
  margin: 0 0 20px;
  position: relative;
  top: -12px;

  .metric {
    p {
      display: inline;
      font-weight: 400;
      margin-right: 5px;
    }
  }
}

.UserCard__metrics.otherProfile > .metric > p {
  font-size: 0.85rem;
}

.UserCard__block.page {
  .UserCard__metrics {
    width: 100%;
  }
}

.UserCard__block_slider {
  margin-left: -15px;
}

.UserCard__slider {
  width: calc(100% + 16px);
}

.UserCard__flag {
  right: -16px;
}

@media screen and (min-width: 768px) {
  .UserCard.page {
    .UserCard__wrapper {
      padding: 40px 0;
    }

    .UserCard__block.basic {
      width: 45%;
    }

    .UserCard_row {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 570px;
      margin: auto;
    }

    .UserCard_row:first-child {
      padding-bottom: 40px;
      flex-direction: row;

      > .UserCard__block {
        padding-bottom: 0;
      }
    }

    .UserCard__block_slider {
      width: 275px;
      margin-left: 0;

      > .UserCard__slider {
        width: 100%;
      }
    }

    .UserCard__flag {
      right: -1px;
    }

    .UserCard__genderAndOccupation {
      flex-direction: column;

      > .UserCard__gender {
        padding-bottom: 16px;
      }
    }

    .UserCard__block_work,
    .UserCard__block_studies {
      width: 50%;
      float: left;
    }

    .UserCard__block_work + .UserCard__block_studies {
      width: 45%;
    }
  }
}
