.ProgressiveImg--Placeholder {
  background-color: #f6f6f6;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.ProgressiveImg--Placeholder img {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 1s linear;
  display: block;
  object-fit: cover;
}

.ProgressiveImg--Placeholder img.ProgressiveImg--Loaded {
  opacity: 1;
}

.ProgressiveImg--TinyImg {
  filter: blur(1vw);
  transform: scale(1);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ styles */
  .ProgressiveImg--Placeholder img:first-child {
    top: 50%;
    transform: translateY(-50%);
    height: auto;
  }
  .ProgressiveImg--Placeholder {
    background-color: white;
  }
}
