.Button__share_messenger {
  background: url("assets/icons/messenger.svg");
  background-size: cover;
}

.Button__share_facebook {
  background: url("assets/icons/facebook.svg");
  background-size: cover;
}

.Button__share_twitter {
  background: url("assets/icons/soc_twitter.svg");
  background-size: cover;
}

.Button__share_copyurl {
  background: url("assets/icons/copy-link.svg");
  background-size: cover;
}

.Button__share_whatsapp {
  background: url("assets/icons/whatsapp.png");
  background-size: cover;
}
