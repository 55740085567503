@import "../../styles/baseExt.css";

.DropZone {
  padding: 15px;
  height: 100%;
}

.DropZone__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-top: 25px;
}

.DropZone__header > h3 {
  font-size: 1.2rem;
  margin: 0;
}

.DropZone__error:after {
  content: "";
  position: absolute;
  right: 32px;
  top: 16px;

  width: 15px;
  height: 15px;

  background-image: url("assets/icons/icon-error-text.svg");
  background-repeat: no-repeat;
  background-size: cover;

  border-radius: 8px;
}

.DropZone__containter_empty {
  display: flex;
  height: 100%;
}

.DropZone__Empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 200px;
}

.DropZone__Empty > img {
  max-width: 36px;
}

.DropZone__Empty--title,
.DropZone__Empty > p {
  margin: 0;
}

.DropZone__Empty--title {
  font-size: 1.06rem;
  font-weight: bold;

  padding-top: 16px;
}

.DropZone__Empty > p {
  font-size: 0.867rem;
}

#description_desktop {
  display: block;
  max-width: 60%;
  text-align: center;
}

#description_mobile {
  display: none;
}

.DropZone__Empty > p > button {
  padding: 0;
  font-size: inherit;
}
.DropZone__Empty > p > button > span {
  text-decoration: underline;
  color: var(--badi-dark-grey);
}

.DropZone__Preview--box {
  width: 100%;
  padding-top: 100%;
  position: relative;
  margin: 15px 0;
}

.DropZone__Preview--add {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  cursor: pointer;

  background-image: url("assets/icons/icon_photo_upload.svg");
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
}

.DropZone__Preview--add:after {
  content: "";
  position: absolute;
  top: 8px;
  right: 8px;
  height: 24px;
  width: 24px;
  background-image: url("assets/icons/photo-add.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.DropZone__Preview--img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  border-radius: 4px;
  cursor: move;
}

.DropZone__Preview--img-cover {
  border: solid 3px #489cff;
  border-radius: 5px;
}

.DropZone__Preview--img-cover:before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: -2px;
  width: 24px;
  height: 24px;
  background-color: #489cff;
  border-radius: 4px;
  background-image: url("assets/icons/star.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px;
}

.DropZone__Preview--loader,
.DropZone__Preview--error {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  border-radius: 4px;

  background: rgba(0, 0, 0, 0.5);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.DropZone__Preview--error {
  background: rgba(240, 240, 240, 0.5);
}

.DropZone__Preview--action {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  flex-direction: row-reverse;
}

.DropZone__Preview--action > div {
  margin-left: 8px;
}

.DropZone__Preview--close {
  background-color: var(--badi-green);
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.DropZone__Preview--close > svg {
  width: 9px;
}

.DropZone__Preview--close,
.DropZone__Preview--retry {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.DropZone__counter {
  font-size: 0.933rem;
  display: flex;
}

.DropZone__container--item {
  z-index: 1;
}

.DropZone__container.row {
  justify-content: flex-start;
}

@media screen and (max-width: 768px) {
  .DropZone {
    background-color: transparent;
    padding: 0;
  }

  .DropZone__container > .DropZone__header {
    padding: 0 7px;
  }

  .DropZone__containter_empty {
    display: initial;
  }

  .DropZone > div {
    padding: 0;
  }

  .DropZone,
  .DropZone__Empty {
    min-height: 150px;
  }

  .DropZone__Empty {
    background-color: #f5f5f5;
  }

  #description_desktop {
    display: none;
  }

  #description_mobile {
    display: block;
    text-align: center;
    max-width: 90%;
  }

  .DropZone .DropZone__container {
    margin-right: -7px;
    margin-left: -7px;
  }

  .DropZone__container > div.DropZone__container--item {
    padding-left: 7px;
    padding-right: 7px;
  }

  .DropZone__Preview--box {
    margin: 7px 0;
  }

  .DropZone__Preview--add {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    cursor: pointer;

    background-image: url("assets/icons/camera.svg");
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  }
}

.DropZone__tip {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 500;
  color: #bbbbbb;

  > span {
    margin: 2px 0 0 8px;
  }
}
