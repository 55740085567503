@import "../../../../styles/baseExt.css";

.LandingSection {
  &__Header {
    padding-bottom: 40px;

    @media screen and (min-width: $media-breakpoint-md) {
      padding-bottom: 64px;
    }

    h2 {
      font-size: 1.75rem;
      margin: 0;
      padding-bottom: 16px;

      @media screen and (min-width: $media-breakpoint-md) {
        font-size: 2rem;
      }
    }

    p {
      margin: 0;
    }
  }
}
