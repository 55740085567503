@import "../../../styles/baseExt.css";

.Form {
  &__TagList {
    position: relative;
    box-sizing: border-box;
    margin: 0 0 16px 0;

    list-style: none;
    padding: 0;

    &_add,
    &_edit {
      .TagCategory__header {
        cursor: pointer;
      }

      li {
        border-bottom: 1px solid var(--badi-light-grey);
      }
    }

    > li {
      box-sizing: border-box;
      position: relative;
      padding: 14px 0px;

      > .TagCategory__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        > img {
          margin-right: 15px;
        }

        > span {
          font-size: 0.93rem;
          font-weight: 500;
          line-height: 1.43;
          color: #bbb;
        }

        > .TagCategory__action_icon {
          margin-left: auto;
          padding: 0;
          background-color: transparent;
          border: 0;
          cursor: pointer;
          height: 28px;
          width: 28px;

          display: flex;
          justify-content: center;
          align-items: center;

          > div {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      > .TagCategory__list {
        padding-top: 8px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > .TagCategory__item {
          margin: 8px 4px 0 4px;
          padding: 0 16px;
          box-sizing: border-box;
          border-radius: 21px;
          border: solid 1px #bbb;

          font-size: 0.93rem;
          font-weight: 500;
          line-height: 40px;
          text-align: center;
          color: #bbb;
          background-color: transparent;

          &_selected {
            border: solid 1px var(--badi-booking-blue);
            color: var(--badi-booking-blue);
          }
        }

        > button.TagCategory__item {
          cursor: pointer;
        }
      }
      > .TagCategory__list.TagCategory__list_collapsed {
        max-height: 0;
        transition: all 0.25s ease-out;
        overflow: hidden;
      }
      > .TagCategory__list {
        max-height: 1500px;
        transition: all 0.25s ease-in;
      }
    }
  }
}

.Form__TagList_wrapper {
  height: 100%;
  overflow: auto;

  > h2 {
    font-size: 1.6rem;
    margin-bottom: 8px;
  }

  ul {
    margin-bottom: 70px;
  }

  .Form__TagList_actions {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
    background-color: white;
  }

  @media screen and (min-width: $media-breakpoint-md) {
    .Form__TagList_actions {
      position: absolute;
    }
  }
}
