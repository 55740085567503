body,
input,
button,
textarea,
select {
  font-family: var(--badi-font-family);
}
body {
  font-size: var(--badi-font-size);
  font-weight: 400;
  line-height: 1.6;

  color: var(--badi-dark-grey);
}

h1 {
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--badi-dark-grey);

  font-size: 1.5rem;
  font-weight: 700;

  -webkit-font-smoothing: antialiased;
}
h2 {
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--badi-dark-grey);

  font-size: 1.26rem;
  font-weight: bold;
}

h3,
.form__title {
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--badi-dark-grey);

  font-size: 1.06rem;
  font-weight: bold;
}

button {
  font-size: 0.875em;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
}

.text-medium {
  font-weight: 500;
  line-height: 1.34;
  text-align: center;
  color: var(--badi-dark-grey);
}

.text-small {
  font-size: 0.7575em;
  font-weight: 400;
  line-height: 1.34;
  color: var(--badi-warm-grey);
}

.tab {
  font-size: 0.7575em;
  font-weight: 500;
  text-align: center;
  color: var(--badi-dark-grey);
}

.tab-lister {
  font-weight: bold;
  color: var(--badi-yellow-dark);
}

.tab-seeker {
  font-weight: bold;
  color: var(--badi-green-dark);
}

input,
select {
  font-size: 0.82;
  font-weight: 500;
  letter-spacing: -0.1px;
  text-align: left;
  color: var(--badi-dark-grey);
}
input::placeholder,
select::placeholder {
  font-size: 0.8789em;
  font-weight: 500;
  color: var(--badi-soft-grey);
}

::-webkit-input-placeholder {
  color: var(--badi-soft-grey);
}

:-moz-placeholder {
  color: var(--badi-soft-grey);
}

::-moz-placeholder {
  color: var(--badi-soft-grey);
}

:-ms-input-placeholder {
  color: var(--badi-soft-grey);
}
