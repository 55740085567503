@import "baseExt.css";

html {
  box-sizing: border-box;
  font-size: var(--badi-font-size);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

#root {
  min-height: 100%;
  display: block;
}
input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}
input::-ms-clear {
  display: none;
}
a.disabled__link {
  pointer-events: none;
  cursor: default;
  opacity: 0.45;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
a,
input,
button,
textarea,
select {
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

textarea {
  resize: vertical;
}

.centered-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.width-100 {
  width: 100%;
}

.no-padding {
  padding: 0;
}
