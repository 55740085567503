@import "../../../../styles/baseExt.css";

.LandingListItem {
  padding-bottom: 48px;

  &:last-of-type {
    padding-bottom: 80px;
  }

  @media screen and (min-width: $media-breakpoint-md) {
    padding-bottom: 64px;

    &:last-of-type {
      padding-bottom: 80px;
    }
  }

  @media screen and (min-width: $media-breakpoint-lg) {
    padding-bottom: 96px;
  }

  &__Decorator {
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    background-image: url("assets/icons/icon-check.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(8, 199, 129, 0.1);
  }

  &__Title {
    font-size: 1.25rem;
    margin: 0;
    padding: 24px 0 8px 0;
  }

  p {
    margin: 0;
  }
}
