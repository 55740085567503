@import "../../../styles/baseExt.css";

.Form {
  &__ChoiceItem {
    &--wrapper {
      flex: 0 0 19%;
      margin: 0 1% 24px 0;
      color: #bbb;
      font-size: 0.73rem;
      text-align: center;

      input {
        display: none;
      }

      /* desktop first here because we want to affect only small screens */
      @media (max-width: 420px) {
        flex: 1 0 32%;
      }

      label {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        hyphens: auto;

        span {
          margin-top: 4px;
        }
      }
    }
  }

  &__Choice {
    &--wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start !important;
    }
  }
}
