@import "baseExt.css";

.info {
  position: relative;

  padding: 0 24px 16px 24px;

  background: #fff;
}
.popup .info {
  margin: 0 -1px;
}
@media screen and (max-width: 999px) {
  .info {
    padding-right: 16px;
    padding-left: 16px;
  }
  .sidebar_listroom .info,
  .sidebar_payment .info,
  .sidebar_requests .info {
    padding: 0;

    background: none;
  }
  .info_menu {
    padding: 0;
  }
  .sidebar_pin .info {
    margin: 0 !important;
  }
}

.info__head {
  padding: 16px 0 16px;
}

.info__head > h2 {
  margin-bottom: 0;
}
@media screen and (max-width: 999px) {
  .sidebar_listroom .info__head,
  .sidebar_payment .info__head,
  .sidebar_requests .info__head {
    margin: 0px -16px 24px -16px;
    padding: 12px 16px;

    text-align: center;

    background: #fff;
  }
  .sidebar__nav .info__head {
    margin: 0 !important;
  }
  .info_menu .info__head {
    position: relative;

    padding: 16px;

    background: #fff;
    z-index: 2;
  }
  .info_menu .info__head:after {
    position: absolute;
    z-index: 87;
    top: 14px;
    right: 16px;

    width: 26px;
    height: 26px;

    content: "";
    -webkit-transition: -webkit-transform 0.125s ease-out;
    transition: -webkit-transform 0.125s ease-out;
    transition: transform 0.125s ease-out;
    transition:
      transform 0.125s ease-out,
      -webkit-transform 0.125s ease-out;
    pointer-events: none;

    background: url("assets/icons/ico_down_gray.svg") no-repeat 50% 50%;
  }
  .sidebar_invitations .info_menu .info__head:after {
    display: none;
  }
  .info_open .info__head:after {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .info__head > h2 {
    margin-bottom: 0;
    font-size: 1.2rem;
  }
}

.info__title {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.25;

  margin: 0;
}
@media screen and (max-width: 999px) {
  .info__title {
    text-align: left;
  }
  .sidebar_listroom .info__title,
  .sidebar_payment .info__title,
  .sidebar_requests .info__title {
    font-size: 15px;
  }
}

@media screen and (max-width: 999px) {
  .info_menu .info__main {
    position: absolute;
    z-index: 2;
    top: 52px;
    right: -16px;
    left: -16px;

    overflow: auto;

    max-height: 0;
    padding: 0 16px;

    -webkit-transition: max-height 0.125s ease-out;
    transition: max-height 0.125s ease-out;

    background: #fff;
  }
  .info_open .info__main {
    max-height: 238px;
  }
}

.info__progress {
  clear: both;

  margin: 0 0 8px;
}

.info__steps {
  margin: 32px 0 0;
  padding: 0;

  list-style: none;
}
@media screen and (max-width: 999px) {
  .sidebar_listroom .info__steps,
  .sidebar_payment .info__steps {
    display: none;
  }
}

.info__step {
  font-weight: 400;
  line-height: 26px;
  cursor: pointer;

  position: relative;

  padding: 0 0 24px 50px;

  color: var(--badi-warm-grey);
}
.info__step:hover {
  color: var(--badi-dark-grey);
}
.info__step:before {
  position: absolute;
  top: 10px;
  bottom: -10px;
  left: 12px;

  width: 2px;

  content: "";

  background: #e1e1e1;
}
.info__step:after {
  position: absolute;
  top: 0;
  left: 0;

  width: 22px;
  height: 22px;

  content: "";

  border: 2px solid #e1e1e1;
  border-radius: 50%;
  background: #fff;
}
.info__step:last-child:before {
  display: none;
}
.info__step_active {
  font-weight: 400;

  color: #494949;
}
.info__step_active:after {
  border: 2px solid #0edc84;
  background: #0edc84 url("assets/icons/ico_check_white.svg") no-repeat 50% 50%;
}
.sidebar_payment .info__step_active:after {
  border: 2px solid #4990e2;
  background: #4990e2 url("assets/icons/ico_check_white.svg") no-repeat 50% 50%;
}

.sidebar_payment .progress__line {
  background: #4990e2;
}

.sidebar_payment > .sidebar__content {
  top: auto;
}

.info__actions {
  font-size: 14px;

  margin: 16px 0 0;
  padding: 16px 0;

  text-align: center;
}
@media screen and (max-width: 999px) {
  .sidebar_listroom .info__actions,
  .sidebar_payment .info__actions {
    display: none;
  }
}

.info__help {
  text-decoration: underline;

  color: #494949;
}
.info__help:hover {
  text-decoration: none;
}

.info__avatar {
  position: relative;

  margin: 0 -24px 8px -24px;
}
.info__avatar_empty {
  padding-top: 30px;
  padding-bottom: 50px;
}
@media screen and (max-width: 999px) {
  .info__avatar {
    float: left;

    width: 50%;
    margin: 0 -16px 8px -16px;
  }
  .popup .info__avatar {
    float: none;

    width: auto;
    margin: 0 -24px 32px -24px;
  }
}
@media screen and (max-width: 600px) {
  .info__avatar {
    float: none;
    width: auto;
  }
}

.info__photo {
  width: 100%;

  vertical-align: bottom;
}

.info__parameters {
  margin: 0 -16px 16px 0;

  text-align: right;
}
.info_owner .info__parameters {
  float: right;

  margin: -6px 0 0 0;
}
@media screen and (max-width: 999px) {
  .info__parameters {
    float: right;

    margin: -4px 0 0 0;
  }
}

.info__parameter {
  display: inline-block;

  margin: 0 0 0 8px;

  vertical-align: middle;
}

.info__user {
  margin: 0 0 32px;
}
.info_owner .info__user {
  margin-bottom: 16px;
}
@media screen and (max-width: 999px) {
  .info__user {
    margin-top: 0;
  }
}

.info__about {
  margin: 0 0 32px;
  padding: 0 0 32px;

  border-bottom: 1px solid #e1e1e1;
}
.info_owner .info__about {
  margin-bottom: 0;
  padding-top: 26px;

  border-bottom: none;
}
.info__about_summary {
  margin: 16px 0 32px 0;
  padding: 32px 0 32px 0;
  border-top: 1px solid #e1e1e1;
  border-bottom: none;
}
@media screen and (max-width: 999px) {
  .info__about {
    margin-left: 50%;
  }
  .popup .info__about {
    margin: 0 0 16px;
    padding: 0 0 16px;
  }
}
@media screen and (max-width: 600px) {
  .info__about {
    margin: 0 0 16px;
    padding: 0 0 16px;
  }
}

.info__verify,
.info__trusted {
  line-height: 32px;

  position: absolute;
  top: 16px;
  right: -1px;

  height: 35px;
  padding: 0 16px 0 8px;

  color: #fff;
  background-size: cover;
}

.info__verify {
  background: url("assets/images/verify.svg") no-repeat 100% 0;
}
.info__trusted {
  background: url("assets/images/trusted-flag.svg") no-repeat 100% 0;
}

.info__verify:before,
.info__trusted:before {
  position: absolute;
  top: 0;
  right: 100%;

  width: 34px;
  height: 100%;

  content: "";

  background: inherit;
  background-position: 0 0;
}
.info__trusted:before {
  width: 38px;
}

.info__text {
  font-size: 14px;

  color: #b2b2b2;
}
.info__text_error {
  color: #ff7485;
}

.info__menu {
  margin: 16px 0 0;
  padding: 0;

  list-style: none;
}

.info__item {
  margin: 24px 0 16px;
}
.info__item:first-child {
  margin-top: 0;
}

.info__label,
.info__counter {
  font-size: 1rem;
  font-weight: 400;
}

.info__item_active .info__label,
.info__item_active .info__counter {
  font-weight: 500;
}

.info__page,
.info__page:hover {
  font-family: inherit;
  font-size: inherit;

  position: relative;

  display: block;

  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;

  cursor: pointer;
  -webkit-transition: 0.125s ease-out;
  transition: 0.125s ease-out;
  text-align: left;

  color: var(--badi-warm-grey);
  border: none;
  outline: none;
  background: none;
}
.info__item_active .info__page,
.info__item_active .info__page:hover {
  color: var(--badi-dark-grey);
}
.info__page:hover,
.info__page:hover:hover {
  text-decoration: none;

  color: #494949;
}
.info__item_link > span > a {
  color: var(--badi-warm-grey);
  width: 100%;
  display: block;
}
.info__item_active.info__item_link > span > a {
  color: var(--badi-dark-grey);
}
.info__item_link > span > a:hover {
  text-decoration: none;
}
@media screen and (max-width: 999px) {
  .info_menu .info__page,
  .info_menu .info__page:hover {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.info__counter {
  position: absolute;
  top: 0;
  right: 0;
}
@media screen and (max-width: 999px) {
  .info__counter {
    right: 16px;
  }
}

.info__overlay {
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 100%;
  left: 0;

  -webkit-transition: opacity 0.25s ease-out;
  transition: opacity 0.25s ease-out;

  opacity: 0;
  background: #000;
}
@media screen and (max-width: 999px) {
  .info_open .info__overlay {
    z-index: 1;
    bottom: 0;

    opacity: 0.4;
  }
  .info_close .info__overlay {
    z-index: -1;
    bottom: 0;

    opacity: 0;
  }
}

@media screen and (max-width: 999px) {
  .info__trigger {
    position: absolute;
    z-index: 2;
    top: 0;
    right: -16px;
    left: -16px;

    height: 52px;

    cursor: pointer;

    opacity: 0.3;
  }
}

.info__total {
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;

  position: absolute;
  top: 18px;
  right: 52px;

  box-sizing: border-box;
  min-width: 20px;
  height: 20px;
  padding: 0 4px;

  text-align: center;

  color: #fff;
  border-radius: 20px;
  background: #ff7485;
}
.info__head .info__total {
  display: none;
}
@media screen and (max-width: 999px) {
  .info__total {
    font-size: 10px;
    line-height: 18px;

    min-width: 16px;
    height: 16px;
  }
  .info__head .info__total {
    display: block;

    -webkit-transition: opacity 0.125s ease-out;
    transition: opacity 0.125s ease-out;

    opacity: 1;
  }
  .info_open .info__total {
    opacity: 0;
  }
}
.sidebar_invitations .info__total {
  right: 16px;
}

.info__friends {
  text-align: center;
}

.info__friend {
  display: inline-block;

  vertical-align: middle;
}
