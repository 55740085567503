@import "../../../styles/baseExt.css";

.Form {
  &__Select {
    &--wrapper {
      position: relative;
      box-sizing: border-box;
      margin-bottom: 16px;

      &:after {
        position: absolute;
        top: 50%;
        right: 2px;
        width: 40px;
        height: 38px;
        margin: -18px 0 0; /* half the height - 1 */
        content: "";
        pointer-events: none;
        background: url(assets/icons/ico_down_black.svg) no-repeat 50% 50%;
      }

      select {
        box-sizing: border-box;
        padding: 0 30px 0 15px;
        font-size: 1rem;
        width: 100%;
        height: 48px;
        line-height: 48px;

        border: 1px solid #bbb;
        border-radius: 4px;

        color: #bbb;
        background-color: var(--badi-white);

        white-space: nowrap;
        text-overflow: ellipsis;

        -webkit-appearance: none;
        -moz-appearance: none;

        &:focus {
          color: var(--badi-dark-grey);
          border: 1px solid var(--badi-dark-grey);
        }

        &::-ms-expand {
          display: none;
        }
      }

      select.Form__Select--select-active {
        color: var(--badi-dark-grey);
        border: 1px solid var(--badi-dark-grey);
      }

      select.Form__Select--select-with-icon {
        padding-left: 45px;
      }

      label {
        cursor: pointer;
      }

      .Form__Select--icon {
        position: absolute;
        width: 45px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0px;
        top: 0px;
      }
    }

    &--wrapper.disabled {
      select {
        border: 1px solid var(--badi-soft-grey);
        color: var(--badi-soft-grey);
      }

      &:after {
        background: url(assets/icons/ico_down_gray.svg) no-repeat 50% 50%;
      }
    }
  }
}
