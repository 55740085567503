.SearchRoomForm {
  &__BedTypes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__AnyPlaceType {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 8px 0 16px 0;

    > p {
      margin: 0 0 0 12px;
    }
  }

  &__BedTypes {
    margin-top: 8px;
    flex-wrap: wrap;

    > * {
      width: calc(50% - 8px);
      margin-bottom: 16px;
    }
  }

  &__Amenities {
    margin-top: 8px;

    > * {
      width: 125px;
    }
  }
}
