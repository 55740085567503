@import "../../styles/baseExt.css";

.SideBarMenu__Space {
  width: 100%;
  height: 58px;

  @media screen and (min-width: $media-breakpoint-md) {
    height: 72px;
  }

  @media screen and (min-width: $media-breakpoint-lg) {
    display: none;
  }
}
