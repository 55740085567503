@import "../../../styles/baseExt.css";

.Form {
  &__RadioButtonDefault {
    &--custom {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      border: 1px solid black;
      border-radius: 50%;

      &-selected {
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: black;
        border-radius: 50%;
        opacity: 0;
      }
    }

    &--wrapper {
      position: relative;
      box-sizing: border-box;
      line-height: 24px;
      padding-left: 32px;
      margin-bottom: 16px;

      input {
        display: none;

        &:checked
          ~ label
          .Form__RadioButtonDefault--custom
          > .Form__RadioButtonDefault--custom-selected {
          opacity: 100;
        }
      }

      label {
        cursor: pointer;
      }
    }
  }
}

.Form {
  &__RadioButton {
    &--wrapper {
      position: relative;
      box-sizing: border-box;

      min-height: 64px;
      border-radius: 5px;
      border: solid 2px #f0f0f0;
      outline: none;
      cursor: pointer;

      padding: 20px;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 20px;
      }

      .Form__RadioButton--additional {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease;
      }

      &.selected {
        border-color: var(--badi-pink);

        .Form__RadioButton--additional {
          max-height: 600px;
          transition: max-height 0.5s ease;
        }
      }

      img {
        margin-right: 20px;
      }

      input {
        display: none;
      }

      label {
        cursor: pointer;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.6;
        color: var(--badi-dark-grey);
      }

      p {
        margin: 0;
        font-size: 0.86rem;
        font-weight: 400;
        line-height: 1.54;
        color: var(--badi-warm-grey);
      }
    }
  }
}
