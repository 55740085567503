@import "../../../styles/baseExt.css";

.popup_active {
  .ModalWithAvatar {
    transform: scale(1);
  }
}

.popup_closed {
  .ModalWithAvatar {
    transform: scale(0);
  }
}

.ModalWithAvatar {
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  width: 450px;
  border-radius: 8px;
  background: var(--badi-white);
  transform: scale(0);

  transition: 0.25s ease-out;

  @media screen and (max-width: $media-breakpoint-sm) {
    position: absolute;
    bottom: 8px;
    width: calc(100% - 16px);
  }

  .ModalWithAvatar__Avatar {
    display: inline-block;
    position: absolute;
    width: 88px;
    height: 88px;
    border: 4px solid var(--badi-white);
    margin-top: -108px;
    left: calc(50% - 44px);
  }

  &__Wrapper {
    box-sizing: border-box;
    position: fixed;
    z-index: 11;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.25s ease-out;
  }
}
