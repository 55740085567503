.header__inner,
.menu__inner {
  position: relative;

  box-sizing: border-box;
  min-width: 320px;
  max-width: 1126px;
  margin: 0 auto;
  padding: 0 16px;
}
@media screen and (max-width: 480px) {
  .header__inner,
  .menu__inner {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.header {
  position: fixed;
  z-index: 200;

  height: 60px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  border-bottom: 1px solid #e1e1e1;
}
.layout_home .header,
.layout_page .header {
  border-bottom: none;
}
.header_search {
  height: 119px;
  position: fixed;
}
@media screen and (max-width: 1200px) {
  .header {
    height: 50px;

    border-bottom: 1px solid #e1e1e1;
  }
  .header_search {
    height: 167px;

    border-bottom: 0;
  }
}

.header__wrapper {
  position: fixed;
  z-index: 190;
  top: 0;
  right: 0;
  left: 0;

  height: 60px;

  -webkit-transition: -webkit-transform 0.25s ease-out;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition:
    transform 0.25s ease-out,
    -webkit-transform 0.25s ease-out;

  border-bottom: 1px solid #e1e1e1;
  background: #fff;
}
.layout_home .header__wrapper,
.layout_page .header__wrapper {
  border-bottom: none;
}
.header_hide .header__wrapper {
  -webkit-transform: translateY(-80px);
  -ms-transform: translateY(-80px);
  transform: translateY(-80px);
}
@media screen and (max-width: 1200px) {
  .header__wrapper {
    z-index: auto;

    height: 50px;

    -webkit-transition: box-shadow 0.25s ease-out;
    transition: box-shadow 0.25s ease-out;
  }
  .page_open .header__wrapper {
    z-index: 240;
  }
  .page_scrolled .header__wrapper {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
  }
}

.header__inner {
  max-width: 100%;
}

.header__user {
  position: relative;

  float: right;

  box-sizing: border-box;
  height: 60px;
  margin: 0px;
}
@media screen and (max-width: 1200px) {
  .header__user {
    height: 50px;
    margin-left: 8px;
  }
}

.header__avatar {
  display: inline-block;
  overflow: hidden;

  width: 42px;
  height: 42px;
  margin: 6px 0 0;

  border: 2px solid #fff;
  border-radius: 50%;
  background-position: 50% 50%;
  background-size: cover;
}
.header__user_online .header__avatar {
  border-color: #fec059;
}
.nav__chat .header__avatar {
  width: 34px;
  height: 34px;
  margin: 0;

  vertical-align: middle;
}
.nav__chat .header__avatar:nth-child(2) {
  margin-left: -15px;
}
@media screen and (max-width: 1200px) {
  .header__avatar {
    width: 38px;
    height: 38px;
    margin-top: 3px;
  }
  .header__user .header__avatar {
    display: none;
  }
  .nav__item_profile .header__avatar {
    position: absolute;
    top: 4px;
    right: 8px;
  }
}

.header__photo {
  width: 100%;
  height: 100%;
}

.header__site {
  position: absolute;
  top: 15px;
  left: 16px;

  width: 93px;
  height: 27px;

  transition: none;
  text-decoration: none;
}
.header__site svg {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1200px) {
  .header__site {
    top: 16px;
    left: 50px;

    width: 72px;
    height: 19px;
  }
  .nav__item_site .header__site {
    left: 15px;
    top: 15px;
    height: 30px;
  }
}
@media screen and (max-width: 480px) {
  .header__site {
    left: 40px;
  }

  .nav__item_site .header__site {
    left: 15px;
    top: 15px;
    height: 30px;
  }
}

.header__nav {
  margin: 0 0 0 126px;
}
.header__user ~ .header__nav {
  margin-right: 64px;
}
@media screen and (max-width: 1200px) {
  .header__nav {
    position: fixed;
    z-index: 200;
    top: 0;
    bottom: 0;
    left: 0;

    overflow-y: auto;

    width: 266px;
    margin: 0;

    -webkit-transition: -webkit-transform 0.25s ease-out;
    transition: -webkit-transform 0.25s ease-out;
    transition: transform 0.25s ease-out;
    transition:
      transform 0.25s ease-out,
      -webkit-transform 0.25s ease-out;
    -webkit-transform: translateX(-266px);
    -ms-transform: translateX(-266px);
    transform: translateX(-266px);

    background: #fff;
  }
  .page_open .header__nav {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  .page_close .header__nav {
    -webkit-transform: translateX(-266px);
    -ms-transform: translateX(-266px);
    transform: translateX(-266px);
  }
}

.header__overlay {
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 100%;
  left: 0;

  display: none;

  -webkit-transition: opacity 0.25s ease-out;
  transition: opacity 0.25s ease-out;

  opacity: 0;
  background: #000;
}
@media screen and (max-width: 1200px) {
  .header__overlay {
    display: block;
  }
  .page_open .header__overlay {
    z-index: 190;
    bottom: 0;

    opacity: 0.4;
  }
  .page_close .header__overlay {
    z-index: -1;
    bottom: 0;

    opacity: 0;
  }
}

.header__actions {
  float: right;
  overflow: hidden;

  margin: 0 -1px 0 0;
  padding: 10px 22px 0;

  text-align: right;
}
.header__actions:before {
  float: right;

  width: 1px;
  height: 16px;
  margin: 12px -32px 0 0;

  content: "";

  background: #e0e0e0;
}
@media screen and (max-width: 1200px) {
  .header__actions {
    float: right;

    padding-top: 5px;
    padding-right: 0;
  }
  .header__actions:before {
    display: none;
  }
}

.header__burger {
  position: absolute;
  top: 15px;
  left: 16px;

  visibility: hidden;

  box-sizing: border-box;
  width: 22px;
  height: 22px;
  margin: 0;

  cursor: pointer;

  opacity: 0;
}
.header__burger_notice:after {
  position: absolute;
  top: -2px;
  right: -4px;

  width: 5px;
  height: 5px;

  content: "";

  border-radius: 50%;
  background: #ff7485;
}
.page_open .header__burger {
  -webkit-transition: top 0.25s ease-out;
  transition: top 0.25s ease-out;
}
.page_open .header__burger:after {
  display: none;
}
@media screen and (max-width: 1200px) {
  .header__burger {
    visibility: visible;

    opacity: 1;
  }
}
@media screen and (max-width: 480px) {
  .header__burger {
    left: 8px;
  }
}

.header__burger-ico {
  position: absolute;
  top: 50%;
  left: 0;

  width: 22px;
  height: 2px;
  margin: -1px 0 0 0;

  border-radius: 3px;
  background: #dfdedf;
}
.header__burger-ico:before,
.header__burger-ico:after {
  position: absolute;
  top: -6px;
  left: 0;

  width: 100%;
  height: 2px;
  margin: -1px 0 0 0;

  content: "";
  -webkit-transition: all 0.125s linear;
  transition: all 0.125s linear;

  border-radius: inherit;
  background: inherit;
}
.header__burger-ico:after {
  top: auto;
  bottom: -7px;
}

.nav {
  font-size: 14px;
  font-weight: 400;
}
@media screen and (max-width: 1200px) {
  .nav {
    overflow: hidden;
  }
}

.nav__list {
  margin: 0;
  padding: 0;

  list-style: none;

  text-align: right;
}
@media screen and (max-width: 1200px) {
  .nav__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;

    text-align: left;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
  }
}

.nav__item {
  position: relative;

  float: right;

  height: 60px;
}
.nav__item:before {
  float: right;

  width: 1px;
  height: 16px;
  margin: 22px -1px 0 0;

  content: "";

  background: var(--badi-dark-grey);
}
.nav__item.nav__item_lang:before {
  display: none;
}
.nav__item_room,
.nav__item_badi {
  float: left;
}
.nav__item_room:before,
.nav__item_badi:before {
  display: none;
}
.nav__item_login_mobile {
  display: none;
}
.nav__item_site,
.nav__item_profile,
.nav__item_settings,
.nav__item_account,
.nav__item_switch,
.nav__item_logout {
  display: none;
}
@media screen and (max-width: 1200px) {
  .nav__item {
    float: none !important;

    height: auto;
  }
  .nav__item:before {
    display: none;
  }
  .nav__item_signup,
  .nav__item_login {
    display: none;
  }
  .nav__item_login_mobile,
  .nav__item_account {
    display: block;
  }
  .nav__item_site,
  .nav__item_profile {
    display: block;

    border-bottom: 1px solid #e0e0e0;
  }
  .nav__item_settings,
  .nav__item_switch,
  .nav__item_logout,
  .nav__item_login_mobile,
  .nav__item_lang {
    display: block;

    border-top: 1px solid #e0e0e0;
  }
  .nav__item_login_mobile {
    padding-top: 16px;
  }
  .nav__item_chat {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .nav__item_settings {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .nav__item_account {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .nav__item_help {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .nav__item_lang {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .nav__item_switch {
    -webkit-box-ordinal-group: 21;
    -ms-flex-order: 20;
    order: 20;
  }
  .nav__item_logout,
  .nav__item_login_mobile {
    text-align: center;

    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
}

.nav__link {
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  color: var(--badi-dark-grey);

  /* Required web styles */
  line-height: 60px;
  display: block;
  height: 60px;
  padding: 0 16px;
  cursor: pointer;
  transition: color 0.125s linear;
  text-decoration: none;
  border: none;
  background: none;
}
.nav__link:hover {
  color: var(--badi-warm-grey);
  text-decoration: none;
}
@media screen and (min-width: 1260px) {
  .nav__link {
    padding: 0 24px;
  }
}
@media screen and (max-width: 1200px) {
  .nav__link {
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 44px;
    text-align: left;

    height: 44px;
    padding: 0 16px;
  }
  .nav__item_active .nav__link {
    padding-left: 13px;

    color: #494949;
    border-left: 3px solid #0edc84;
  }
  .nav__item_site .nav__link,
  .nav__item_profile .nav__link {
    line-height: 58px;

    height: 58px;
  }
  .nav__item_site .nav__link {
    text-align: center;
  }
  .nav__item_switch .nav__link {
    padding: 0 42px 0 16px;
    line-height: 22px;

    min-height: 54px;

    color: var(--badi-yellow-dark);
  }
  .nav__item_request .nav__link {
    display: none;
  }
  .nav__item_request.nav__item_lister .nav__link {
    display: block;
  }
  .nav__item_logout .nav__link {
    width: 100%;

    text-align: center;

    color: #b2b2b2;
  }
  .nav__item_logout .nav__link:hover {
    color: #494949;
  }
  .nav__item_lang .nav__link {
    width: 100%;

    text-align: left;
  }
}
.browser_ff .nav__link {
  font-family: Arial, sans-serif;
}

.nav__icon {
  display: none;

  width: 20px;
  height: 20px;
  margin: 12px 6px 0 0;

  -webkit-transition: opacity 0.125s ease-out;
  transition: opacity 0.125s ease-out;
  vertical-align: top;

  opacity: 0.5;
  background: url("assets/icons/set_menu.svg") no-repeat 0 50px;
}
.nav__item:hover .nav__icon,
.nav__item_active .nav__icon {
  opacity: 1;
}
.nav__icon_profile {
  margin-top: 19px;

  background-position: 0 0;
}
.nav__icon_search {
  background-position: 0 -80px;
}
.nav__icon_room {
  background-position: 0 -640px;
}
.nav__icon_badi {
  background-position: 0 -160px;
}
.nav__icon_settings {
  background-position: 0 -480px;
}
.nav__icon_help {
  background-position: 0 -560px;
}
.nav__icon_request {
  background-position: 0 -240px;
}
.nav__icon_invitations {
  background-position: 0 -240px;
}
.nav__icon_request_room {
  background-position: 0 -320px;
}
.nav__icon_chat {
  background-position: 0 -400px;
}
@media screen and (max-width: 1200px) {
  .nav__icon {
    display: inline-block;
  }
}

@media screen and (max-width: 1200px) {
  .nav__ico {
    position: absolute;
    top: 8px;
    right: 16px;
  }
}

.nav__item_switch .nav__ico {
  top: 14px;
}

.nav__counter {
  font-size: 10px;
  line-height: 22px;

  display: inline-block;

  box-sizing: border-box;
  min-width: 20px;
  height: 20px;
  padding: 0 4px;

  text-align: center;

  color: #fff;
  border-radius: 20px;
  background: var(--badi-error);
}
.nav__link .nav__counter {
  position: absolute;
  top: 9px;
  right: 0px;
}
@media screen and (min-width: 1260px) {
  .nav__link .nav__counter {
    right: 10px;
  }
}
.nav__sublink .nav__counter {
  position: absolute;
  top: 15px;
  right: 0;
}
@media screen and (max-width: 1200px) {
  .nav__counter {
    right: 16px !important;
  }
  .nav__sublink .nav__counter {
    top: 13px;
  }
}

.nav__subnav {
  font-size: 14px;

  position: absolute;
  z-index: -1;
  top: 100%;
  left: 50%;

  visibility: hidden;

  margin: -4px 0 0;
  padding: 0 24px;

  -webkit-transition: 0.125s ease-out;
  transition: 0.125s ease-out;
  -webkit-transform: translate(-50%, 30px);
  -ms-transform: translate(-50%, 30px);
  transform: translate(-50%, 30px);
  text-align: left;
  white-space: nowrap;

  opacity: 0;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
}
.header__user .nav__subnav {
  right: 0;
  left: auto;

  -webkit-transform: translate(0, 10px);
  -ms-transform: translate(0, 10px);
  transform: translate(0, 10px);
}
@media screen and (max-width: 1200px) {
  .header__user .nav__subnav {
    display: none;
  }
}
.nav__item:hover .nav__subnav,
.header__user:hover .nav__subnav {
  z-index: 10000;

  visibility: visible;

  opacity: 1;
}
.nav__item:hover .nav__subnav {
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.header__user:hover .nav__subnav {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
@media screen and (max-width: 1200px) {
  .nav__subnav {
    position: relative;
    z-index: auto;
    top: auto;
    left: auto;

    visibility: visible !important;

    margin: 0;
    padding: 0;

    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;

    opacity: 1 !important;
    box-shadow: none;
  }
  .nav__item_chat .nav__subnav,
  .nav__item_lang .nav__subnav {
    overflow: hidden;

    max-height: 0;
    padding-bottom: 0;
  }
  .nav__item_lang.nav__item_open .nav__subnav {
    max-height: 180px;
    padding-bottom: 16px;
  }
}

.naw__arrow {
  position: absolute;
  top: -20px;
  left: 50%;

  overflow: hidden;

  width: 40px;
  height: 20px;
  margin: 0 0 0 -20px;
}
.naw__arrow:before {
  position: absolute;
  top: 17px;
  left: 10px;

  width: 20px;
  height: 20px;

  content: "";
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);

  background: #fff;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
}
.header__user .naw__arrow {
  right: 5px;
  left: auto;
}
@media screen and (max-width: 1200px) {
  .naw__arrow {
    display: none;
  }
}

.nav__sublist {
  margin: 0;
  padding: 8px 0;

  list-style: none;

  border-top: 1px solid #e1e1e1;
}
.nav__sublist:first-child,
.naw__arrow + .nav__sublist {
  border-top: 0;
}
.nav__sublist_chat {
  padding: 0;
}
@media screen and (max-width: 1200px) {
  .nav__sublist {
    padding: 0;

    border: 0;
  }
  .nav__sublist_chat {
    display: none;
  }
}

.nav__sublist_chat .nav__subitem {
  border-bottom: 1px solid #e1e1e1;
}

.nav__subitem_all {
  font-size: 12px;

  text-align: center;

  border-bottom: none !important;
}
.nav__sublink,
.nav__value {
  color: var(--badi-warm-grey);
  font-size: 0.8125rem;
  font-weight: 400;
}

.nav__value {
  display: inline-block;
  margin: 1px 0 0;
  vertical-align: top;
}
@media screen and (max-width: 1200px) {
  .nav__value {
    margin-top: 2px;
  }
}

.nav__sublink {
  line-height: 48px;
  position: relative;
  display: block;
  padding: 0 40px 0 16px;
  cursor: pointer;
  border: 0;
  background: none;
}
.nav__sublink:hover {
  color: var(--badi-dark-grey);
  text-decoration: none;
}
.nav__subitem_switch .nav__sublink {
  color: var(--badi-yellow-dark);
}
.nav__subitem_all .nav__sublink {
  padding-right: 0;
}
.nav__subitem_active .nav__sublink {
  color: var(--badi-dark-grey);
  font-weight: 500;
}
@media screen and (max-width: 1200px) {
  .nav__sublink {
    font-size: 14px;
    line-height: 44px;

    padding: 0 16px;
  }
  .nav__subitem_active .nav__sublink {
    color: var(--badi-dark-grey);
  }
  .nav__subitem_lang .nav__sublink {
    font-size: 12px;
    line-height: 32px;
  }
}

.nav__chat {
  line-height: 48px;

  color: #747474;
}
.nav__chat:hover {
  text-decoration: none;

  color: #494949;
}

.nav__hard {
  display: block;
}
@media screen and (max-width: 1200px) {
  .nav__hard {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .nav__hard:after {
    display: inline-block;

    width: 16px;
    height: 16px;
    margin: 0 0 0 8px;

    content: "";
    -webkit-transition: -webkit-transform 0.125s ease-out;
    transition: -webkit-transform 0.125s ease-out;
    transition: transform 0.125s ease-out;
    transition:
      transform 0.125s ease-out,
      -webkit-transform 0.125s ease-out;

    background: url("assets/icons/ico_down_small_gray.svg") no-repeat 50% 50%;
  }
  .nav__item_open .nav__hard:after {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.nav__label {
  display: inline-block;

  margin: 0 0 0 6px;

  vertical-align: middle;
}
.nav__hard .nav__label {
  display: none;
}
@media screen and (max-width: 1200px) {
  .nav__hard .nav__label {
    display: inline-block;

    margin-left: 0;

    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
}

.nav__button {
  position: absolute;
  top: -2px;
  right: 16px;
}

.nav__note {
  font-size: 14px;

  display: none;

  margin: 16px;

  color: #747474;
}
@media screen and (max-width: 1200px) {
  .nav__note {
    display: block;
  }
}

.nav__signup {
  float: right;
}

.nav__signup,
.nav__login {
  display: inline-block;

  margin: 16px 0 0 0;

  vertical-align: middle;
}
@media screen and (max-width: 1200px) {
  .nav__signup,
  .nav__login {
    display: block;
    float: none;

    margin: 8px 0 8px -16px;
  }
  .nav__signup .button,
  .nav__login .button {
    width: 120px;
  }
}

.menu {
  position: relative;
  z-index: 190;

  height: 51px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: -webkit-transform 0.25s ease-out;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition:
    transform 0.25s ease-out,
    -webkit-transform 0.25s ease-out;
}
.menu_empty {
  margin-top: -51px;
}
.menu_hide {
  -webkit-transform: translateY(-80px);
  -ms-transform: translateY(-80px);
  transform: translateY(-80px);
}
@media screen and (max-width: 1200px) {
  .menu {
    display: none;
  }
}

.menu__wrapper {
  position: fixed;
  top: 80px;
  right: 0;
  left: 0;

  height: 50px;

  -webkit-transition: box-shadow 0.25s ease-out;
  transition: box-shadow 0.25s ease-out;

  border-bottom: 1px solid #e1e1e1;
  background: #fff;
}
.menu_empty .menu__wrapper {
  top: 10px;
}
.page_scrolled .menu__wrapper {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
}
.layout_home .menu__wrapper,
.layout_page .menu__wrapper {
  border-bottom: none;
}

.menu__list {
  margin: 0;
  padding: 0;

  list-style: none;
}
@media screen and (min-width: 1260px) {
  .menu__list {
    padding: 0 16px;
  }
}

.menu__item {
  display: inline-block;

  vertical-align: top;
}

.menu__link {
  font-size: 0.86rem;
  font-weight: 500;
  text-align: left;
  color: var(--badi-dark-grey);

  /* Necessary web styles */
  line-height: 50px;
  position: relative;
  display: block;
  overflow: hidden;
  height: 50px;
  margin: 0 30px 0 0;
  padding: 0 16px;
  text-decoration: none;
}
.menu__link:after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 3px;
  content: "";
  transition: transform 0.125s ease-out;
  transform: translateY(3px);
  opacity: 0;
  background: var(--badi-green);
}
.menu_orange .menu__link:after {
  background: var(--badi-yellow-dark);
}
.menu__link:hover,
.menu__item_active .menu__link {
  text-decoration: none;
  color: var(--badi-green);
}
.menu__link:hover:after,
.menu__item_active .menu__link:after {
  transform: translateY(0);
  opacity: 1;
}
.menu_orange .menu__link:hover,
.menu_orange .menu__item_active .menu__link {
  color: var(--badi-yellow-dark);
}
@media screen and (min-width: 1260px) {
  .menu__link {
    padding: 0 32px;
  }
}
