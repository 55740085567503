.popup {
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  visibility: hidden;

  min-width: 320px;

  opacity: 0;

  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.popup_active {
  z-index: 2000;

  visibility: visible;

  opacity: 1;
}
.popup_room {
  z-index: 1999;
}

.popup__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  visibility: hidden;

  -webkit-transition: 0.125s ease-out;
  transition: 0.125s ease-out;

  opacity: 0;
  background: #000;
}
.popup_active .popup__bg {
  visibility: visible;

  opacity: 0.42;
}
.popup_closed .popup__bg {
  opacity: 0;
}

.popup__main {
  position: relative;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  width: 554px;
  max-width: 94%;
  max-height: 92%;

  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);

  opacity: 0;
  border-radius: 4px;
  background: #fff;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
}
.popup__main_thin {
  width: 420px;
}
.popup_login .popup__main,
.popup_signup .popup__main {
  max-width: 570px;
  -ms-overflow-y: scroll;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.popup_booking .popup__main {
  width: 385px;
  min-height: 500px;
}
.popup_payment .popup__main {
  width: 950px;
  overflow-y: hidden;
}
.popup_info .popup__main {
  width: 865px;
  overflow-y: hidden;
}
.popup_signup .popup__main {
  background-color: #fff;
}
.popup_image .popup__main {
  width: auto;

  border-radius: 0;
  background: none;
}
.popup_room .popup__main {
  width: auto;

  border-radius: 0;
}
.popup_profile .popup__main {
  width: 360px;

  border-radius: 0;
}
.popup_profile .popup__content {
  overflow-x: hidden;
}
.popup_active .popup__main {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);

  opacity: 1;
}
.popup_closed .popup__main {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);

  opacity: 0;
}
.popup_booking .popup__main .booking {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup__decoration_left {
  float: left;
}
.popup__decoration_right {
  float: right;
}
.popup__head {
  min-height: 32px;
  padding: 16px;
  padding-bottom: 0;
}
.popup_image .popup__head {
  position: absolute;
  top: -48px;
  right: -48px;
}

.popup_profile .popup__head {
  position: absolute;
  top: -18px;
  left: 18px;
  z-index: 4;
}
@media screen and (max-width: 600px) {
  .popup_profile .popup__head {
    top: -8px;
  }
}

.popup_room .popup__head {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;
}
@media screen and (max-width: 600px) {
  .popup__head {
    min-height: 16px;
  }
  .popup_room .popup__head {
    z-index: 5;
    top: 0;
    right: -6px;
  }
}

.popup__close {
  position: absolute;
  top: 16px;
  right: 16px;

  width: 32px;
  height: 32px;

  cursor: pointer;

  background: url("assets/icons/ico_cross_gray.svg") no-repeat 50% 50%;
}
.popup__close_white {
  background: url("assets/icons/ico_cross_white.svg") no-repeat 50% 50%;
}
.popup__close_shadow {
  background: url("assets/icons/ico_cross_shadowwhite.svg") no-repeat 50% 50%;
}
.popup__close:hover {
  background-image: url("assets/icons/ico_cross_primary.svg");
}
.popup__close_shadow:hover {
  background: url("assets/icons/ico_cross_shadowprimary.svg") no-repeat 50% 50%;
}
@media screen and (max-width: 600px) {
  .popup__close {
    top: 8px;
  }
  .popup_room .popup__close {
    background-image: url("assets/icons/ico_cross_light.svg");
  }
  .popup_room .popup__close:hover {
    background-image: url("assets/icons/ico_cross_primary.svg");
  }
}

.popup_profile .popup__close {
  background: url("assets/icons/ico_cross_shadowwhite.svg") no-repeat 50% 50%;
}

.popup__body {
  overflow: auto;

  padding: 0 32px 32px;

  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.popup__body h2 {
  margin-top: 16px;
}
.popup_image .popup__body,
.popup_profile .popup__body,
.popup_room .popup__body {
  padding: 0;
}
.popup_info .popup__body {
  background-image: url(assets/images/booking-help.svg);
  background-repeat: no-repeat;
  background-position: right 100px bottom;
  background-size: 13%;
}

@media screen and (max-width: 600px) {
  .popup__body {
    padding: 16px;
  }
  .popup__body h2 {
    margin-top: 0;
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 762px) {
  .popup_info .popup__body {
    background-image: none;
  }
}
.popup__content {
  max-width: 400px;
  margin: 0 auto;
}
.popup_signup .popup__content,
.popup_login .popup__content {
  max-width: 85%;
}
.popup_image .popup__content {
  max-width: 100%;
}

.popup_payment .popup__content,
.popup_info .popup__content {
  margin: auto;
  max-width: 100%;
}

.popup__social {
  margin: 32px 0 0;

  text-align: center;
}
@media screen and (max-width: 600px) {
  .popup__social {
    margin-top: 16px;
    padding: 20px 0 30px 0;
  }
}

.popup__step {
  position: absolute;
  top: 12px;
  left: 12px;
  display: flex;
  flex-direction: row;
}
.popup__step > img {
  height: 48px;
}
.popup__step > span {
  line-height: 48px;
  color: #b2b2b2;
}

.popup__network {
  display: inline-block;

  margin: 0 8px;

  vertical-align: middle;
}

.popup__soclogo {
  width: 40px;
  height: 40px;
}
@media screen and (max-width: 600px) {
  .popup__soclogo {
    width: 28px;
    height: 28px;
  }
}

.popup__box {
  position: relative;

  overflow-y: auto;

  height: 280px;
  margin: 32px 0;

  text-align: center;

  border-bottom: 1px solid #e1e1e1;
  border-top: 1px solid #e1e1e1;
  background-image: url("assets/images/rentedto-placeholder.svg");
  background-repeat: no-repeat;
  background-position-x: 35px;
}
@media screen and (max-width: 600px) {
  .popup__box {
    max-height: 260px;
    margin: 16px auto;
  }
}

.popup__list {
  display: inline-block;

  margin: 0;
  padding: 0;

  list-style: none;

  text-align: left;
}

.popup__list_humans {
  margin-top: 10px;
  background-color: white;
  min-width: 190px;
  min-height: 270px;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

.popup__item {
  margin: 0 0 16px;
}
@media screen and (max-width: 600px) {
  .popup__item {
    margin-bottom: 8px;
  }
}

.popup__actions {
  margin: 32px 0 0;

  display: flex;
  justify-content: center;
  text-align: center;
}
.popup__actions_inline {
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup__action {
  margin: 0 5px;
}
.popup__actions > span,
.popup__action > span {
  vertical-align: middle;
}

@media screen and (max-width: 600px) {
  .popup__actions {
    flex-direction: column;
  }

  .popup__action {
    margin: 5px 0;
  }
}

.popup__actions_vertical {
  margin: 50px 0 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.popup__actions_vertical > button {
  min-width: 220px;
}
.popup__actions_vertical > button:last-child {
  margin: 10px 0 0 0;
}
@media screen and (max-width: 600px) {
  .popup__actions_vertical {
    margin-top: 25px;
  }
}

.popup_payment .popup__main > .popup__head {
  min-height: 23px;
}

.popup__chat > .popup__main > .popup__head {
  padding: 0;
  min-height: 0;
}

.popup__chat > .popup__main > .popup__head > .popup__close {
  background: url("assets/icons/ico_cross_white.svg") no-repeat 50% 50%;
}

.popup__chat > .popup__main {
  width: 400px;
}

.popup__chat > .popup__main > .popup__body {
  padding: 0 0 48px;
}

.popup__chat > .popup__main > .popup__body > .popup__content {
  max-width: 400px;
}

.popup__content_chat {
  padding-top: 67px;
}

.popup__chat_bgimage {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 215px;
  top: 0;
  z-index: -1;
}

.popup__chat_coverimage {
  width: 100%;
  height: 215px;
  position: absolute;
  top: 0;
  background-color: #000;
  opacity: 0.45;
  z-index: -1;
}

.popup__chat_bg {
  width: 100%;
  height: 215px;
  position: absolute;
  top: 200px;
  background-image: url("assets/images/curve.svg");
  background-size: contain;
  z-index: -1;
}

.popup__content_chat > .popup__content_title {
}

.popup__matchimage {
  max-height: 130px;
  display: flex;
  margin: 0 auto 20px auto;
}

@media screen and (max-width: 600px) {
  .popup__matchimage {
    max-height: 80px;
  }
}

.popup__chatroom {
  width: 216px;
  height: 152px;
  margin: 0 auto;

  border-radius: 6px;
  background: no-repeat 50% 50%;
  background-size: cover;
}

.popup__chatusers {
  width: 152px;
  height: 100px;
  margin: 30px auto 16px;
  position: relative;
}

@media screen and (max-width: 600px) {
  .popup__chatusers {
    margin: 30px auto 0px;
    height: 70px;
  }
}

.popup__chatuser_1 {
  position: absolute;
}

.popup__chatuser_2 {
  position: absolute;
  right: 5px;
}
