/* Bold */
@font-face {
  font-family: modelica;
  font-weight: 700;
  src: url("assets/fonts/modelica/eot/BwBadi-Bold.eot");
  src:
    url("assets/fonts/modelica/eot/BwBadi-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/modelica/woff2/BwBadi-Bold.woff2") format("woff2"),
    url("assets/fonts/modelica/woff/BwBadi-Bold.woff") format("woff"),
    url("assets/fonts/modelica/otf/BwBadi-Bold.otf") format("opentype"),
    url("assets/fonts/modelica/ttf/BwBadi-Bold.ttf") format("truetype");
}

/* Medium */
@font-face {
  font-family: modelica;
  font-weight: 500;
  src: url("assets/fonts/modelica/eot/BwBadi-Medium.eot");
  src:
    url("assets/fonts/modelica/eot/BwBadi-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/modelica/woff2/BwBadi-Medium.woff2") format("woff2"),
    url("assets/fonts/modelica/woff/BwBadi-Medium.woff") format("woff"),
    url("assets/fonts/modelica/otf/BwBadi-Medium.otf") format("opentype"),
    url("assets/fonts/modelica/ttf/BwBadi-Medium.ttf") format("truetype");
}

/* Regular */
@font-face {
  font-family: modelica;
  font-weight: 400;
  src: url("assets/fonts/modelica/eot/BwBadi-Regular.eot");
  src:
    url("assets/fonts/modelica/eot/BwBadi-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/modelica/woff2/BwBadi-Regular.woff2") format("woff2"),
    url("assets/fonts/modelica/woff/BwBadi-Regular.woff") format("woff"),
    url("assets/fonts/modelica/otf/BwBadi-Regular.otf") format("opentype"),
    url("assets/fonts/modelica/ttf/BwBadi-Regular.ttf") format("truetype");
}
