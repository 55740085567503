@import "../../../styles/baseExt.css";

.Form {
  &__SelectMultiple {
    &--wrapper {
      position: relative;
      box-sizing: border-box;
      margin-bottom: 16px;
    }
    &--select {
      position: relative;
    }
    &--placeholder {
      box-sizing: border-box;
      padding: 0 15px;
      font-size: 1rem;
      font-size: 1rem;
      width: 100%;
      max-height: 48px;
      line-height: 48px;
      color: #bbb;
      border: 1px solid #bbb;
      border-radius: 4px;
      padding-right: 40px;
      outline: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;

      &:focus {
        color: var(--badi-dark-grey);
        border-color: var(--badi-dark-grey);
      }

      &:after {
        position: absolute;
        top: 7px;
        right: 2px;
        width: 40px;
        height: 38px;
        content: "";
        pointer-events: none;
        background: url(assets/icons/ico_down_black.svg) no-repeat 50% 50%;
      }
    }
    &--active {
      color: var(--badi-dark-grey);
      border-color: var(--badi-dark-grey);
    }
    &--outer-background {
      position: fixed;
      top: 0;
      left: 0;
      background-color: transparent;
      width: 100%;
      height: 100%;
    }
    &--options {
      overflow: auto;
      position: absolute;
      z-index: 1;
      top: 100%;
      box-sizing: border-box;
      width: 100%;
      max-height: 200px;
      margin-top: -4px;
      padding-top: 4px;
      border: 1px solid var(--badi-dark-grey);
      border-top: none;
      border-radius: 0 0 4px 4px;
      background-color: #fff;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);

      &-visible {
        visibility: visible;
      }
      &-hidden {
        visibility: hidden;
      }
      &-list {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &--option {
      position: relative;
      overflow-y: auto;
      box-sizing: border-box;
      border: none;
      background: none;
      padding: 0 0 0 40px;
      line-height: 2em;
      width: 100%;
      cursor: pointer;

      color: var(--badi-dark-grey);
      font-size: 1rem;
      font-weight: 400;
      text-align: left;

      &-selected {
        font-weight: 500;
      }
      &-selected:before {
        position: absolute;
        top: 5px;
        left: 15px;
        width: 16px;
        height: 16px;
        content: "";
        border-radius: 50%;
        background: #0edc84 url(assets/icons/ico_check_white.svg) no-repeat 50%
          50%;
        background-size: 70% 70%;
      }
    }
  }
}
