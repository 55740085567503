.payment {
  box-sizing: border-box;
  border: 1px solid #e1e1e1;
  color: #494949;
  outline: none;
  padding: 0 20px;
  height: 48px;
  cursor: text;
  border-radius: 4px;
}

.payment__legend {
  line-height: 30px;
  margin-top: 5px;
}
