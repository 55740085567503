.gallery {
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  visibility: hidden;

  min-width: 320px;

  opacity: 0;
  color: #fff;
}
.gallery_active {
  z-index: 1900;

  visibility: visible;

  opacity: 1;
}

.gallery__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  visibility: hidden;

  -webkit-transition: 0.125s ease-out;
  transition: 0.125s ease-out;

  opacity: 0;
  background: #000;
}
.gallery_active .gallery__bg {
  visibility: visible;

  opacity: 0.8;
}
.gallery_closed .gallery__bg {
  opacity: 0;
}

.gallery__main {
  position: absolute;

  width: 100%;
  height: 100%;

  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;

  opacity: 0;
}
.gallery__main_thin {
  width: 420px;
}
.gallery_active .gallery__main {
  opacity: 1;
}
.gallery_closed .gallery__main {
  opacity: 0;
}

.gallery__close {
  position: absolute;
  top: 60px;
  right: 60px;

  width: 39px;
  height: 39px;

  cursor: pointer;
  -webkit-transition: opacity 0.25s 1s ease-out;
  transition: opacity 0.25s 1s ease-out;

  opacity: 0;
  background: url("assets/icons/ico_cross_big_light.svg") no-repeat 50% 50%;
  background-size: cover;
}
.gallery_active .gallery__close {
  opacity: 1;
}
.gallery_closed .gallery__close {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;

  opacity: 0;
}
@media screen and (max-height: 968px) {
  .gallery__close {
    top: 30px;
    right: 30px;

    margin: 0;
  }
}
@media screen and (max-height: 880px) {
  .gallery__close {
    top: 16px;
    right: 16px;

    width: 16px;
    height: 16px;
  }
}

.gallery__slider {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;

  height: 560px;
  margin: -280px 0 0;

  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);

  opacity: 0;
}
.gallery_active .gallery__slider {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);

  opacity: 1;
}
.gallery_closed .gallery__slider {
  opacity: 0;
}
@media screen and (max-height: 968px) {
  .gallery__slider {
    margin-top: -340px;
  }
}
@media screen and (max-height: 780px) {
  .gallery__slider {
    height: 400px;
    margin-top: -270px;
  }
}

.gallery__list {
  position: relative;

  overflow: hidden;

  width: 840px;
  height: 100%;
  margin: 0 auto;
  padding: 0;

  list-style: none;
}
@media screen and (max-width: 999px) {
  .gallery__list {
    width: 100%;
  }
}

.gallery__item {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow: hidden;

  height: 100%;

  -webkit-transition: -webkit-transform 0.125s ease-out;
  transition: -webkit-transform 0.125s ease-out;
  transition: transform 0.125s ease-out;
  transition:
    transform 0.125s ease-out,
    -webkit-transform 0.125s ease-out;

  opacity: 0;
}
.gallery__item_prev {
  -webkit-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
  transform: translateX(-30px);
}
.gallery__item_next {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}
.gallery__item_active {
  z-index: 1910;

  -webkit-transition: 0.125s ease-out;
  transition: 0.125s ease-out;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);

  opacity: 1;
}

.gallery__box {
  position: absolute;
  left: 50%;

  width: 2000px;
  height: 100%;
  margin: 0 0 0 -1000px;

  text-align: center;
}

.gallery__photo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-color: transparent;

  height: 100%;

  vertical-align: bottom;

  object-fit: scale-down;
}

.gallery__photo > img.gallery__photo_img {
  top: initial;
  left: initial;
  width: auto;
  display: initial;
}

.gallery__prev,
.gallery__next {
  position: absolute;
  z-index: 1920;
  top: 50%;
  left: 20px;

  width: 40px;
  height: 74px;
  margin: -37px 0 0;

  cursor: pointer;
  -webkit-transition: 0.25s 0.75s ease-out;
  transition: 0.25s 0.75s ease-out;

  opacity: 0;
  border: none;
  outline: none;
  background: url("assets/icons/ico_prev_light.svg") no-repeat 50% 50%;
  background-size: cover;
}
.gallery_active .gallery__prev,
.gallery_active .gallery__next {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);

  opacity: 1;
}
.gallery_closed .gallery__prev,
.gallery_closed .gallery__next {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;

  opacity: 0;
}

.gallery__prev {
  -webkit-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
  transform: translateX(-30px);
}
@media screen and (min-width: 1140px) {
  .gallery__prev {
    left: 60px;
  }
}

.gallery__next {
  right: 20px;
  left: auto;

  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);

  background-image: url("assets/icons/ico_next_light.svg");
}
@media screen and (min-width: 1140px) {
  .gallery__next {
    right: 60px;
  }
}

.gallery__panel {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 840px;
  height: 156px;
  margin: 310px 0 0 -420px;

  -webkit-transition: 0.25s 0.5s ease-out;
  transition: 0.25s 0.5s ease-out;
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);

  opacity: 0;
}
.gallery_active .gallery__panel {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);

  opacity: 1;
}
.gallery_closed .gallery__panel {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);

  opacity: 0;
}
@media screen and (max-height: 968px) {
  .gallery__panel {
    margin-top: 240px;
  }
}
@media screen and (max-height: 880px) {
  .gallery__panel {
    margin-top: 230px;
  }
}
@media screen and (max-height: 780px) {
  .gallery__panel {
    margin-top: 140px;
  }
}
@media screen and (max-width: 999px) {
  .gallery__panel {
    left: 0;

    width: 100%;
    margin-left: 0;
  }
}

.gallery__counter {
  font-size: 14px;
  line-height: 32px;

  height: 32px;
  margin: 0 0 22px;

  border-bottom: 2px solid rgba(255, 255, 255, 0.8);
}
@media screen and (max-width: 999px) {
  .gallery__counter {
    padding-left: 8px;
  }
}

.gallery__previews {
  overflow: hidden;

  height: 100px;
}

.gallery__sublist {
  overflow-x: auto;

  height: 130px;
  margin: 0;
  padding: 0;

  list-style: none;

  white-space: nowrap;
}

.gallery__subitem {
  display: inline-block;

  margin: 0 0 0 13px;
}
.gallery__subitem:first-child {
  margin-left: 0;
}

.gallery__thumb {
  min-width: 100px;
  height: 100px;

  opacity: 0.6;
  vertical-align: bottom;
  cursor: pointer;
}

.gallery__thumb:hover {
  opacity: 1;
}

.gallery_thumb_selected {
  opacity: 1;
  border: 1px white solid;
  box-sizing: border-box;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ styles */
  .gallery__photo img.gallery__photo_img:first-child {
    position: relative;
    transform: none;
    height: 100%;
  }
  .gallery__photo img.gallery__photo_img:last-child {
    display: none;
  }
  .gallery__subbox > .gallery__thumb {
    background-color: transparent;
  }
}
