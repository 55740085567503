@import "../../../styles/baseExt.css";

.RoomList {
  &__CheckBox {
    &--custom {
      position: absolute;
      left: 0;
      top: 0;
      height: 24px;
      width: 24px;
      border: 1px solid var(--badi-dark-grey);
      border-radius: 4px;
      cursor: pointer;
    }

    &--wrapper {
      display: block;
      position: relative;
      line-height: 24px;
      padding-left: 32px;
      margin-bottom: 16px;

      input {
        display: none;

        &:checked ~ .RoomList__CheckBox--custom {
          background: url("assets/icons/form-check.svg") no-repeat center;
        }
      }

      label {
        cursor: pointer;
      }
    }
  }
}
