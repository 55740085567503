@import "../../../styles/baseExt.css";

.Form {
  &__DatePicker {
    &--wrapper {
      * {
        background: none;
      }

      &-disabled {
        border-color: #bbb;

        .DateInput {
          &__input {
            cursor: not-allowed !important;
          }
        }
      }

      border: 1px solid;
      border-radius: 4px;
      max-height: 48px;
      position: relative;
      padding-left: 35px;

      .DatePickerIcon {
        position: absolute;
        top: 11px;
        left: 14px;
      }

      .DateInput {
        &__input {
          cursor: pointer;
        }

        display: flex;
        align-items: center;
        width: 100%;
        height: 48px;
        z-index: 1;
      }

      .DateInput__display-text {
        font-weight: 500;
        font-size: 1rem;
        padding: 0 0 0 5px;
      }
    }
  }
}
