.flow__inner {
  position: relative;

  box-sizing: border-box;
  min-width: 320px;
  max-width: 1126px;
  margin: 0 auto;
  padding: 0 16px;
}
@media screen and (max-width: 480px) {
  .flow__inner {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.flow__prev,
.flow__next,
.flow__action {
  font-size: inherit;
  font-weight: inherit;

  margin: 0;
  padding: 0;

  cursor: pointer;

  border: none;
  outline: none;
  background: none;
}

.flow {
  position: relative;

  margin: 55px 0;
}
.flow:hover {
  z-index: 12;
}
@media screen and (max-width: 999px) {
  .flow {
    margin-top: 40px;
  }
}
@media screen and (max-width: 600px) {
  .flow {
    margin-top: 30px;
  }
}

.layout__results .flow__inner {
  margin-left: 0;
  padding-left: 40px;
}

.flow__title {
  font-size: 20px;

  margin-bottom: 20px;
}

.flow__direction {
  position: relative;

  margin: 16px 48px 16px 0;

  text-align: right;
}

.flow__prev,
.flow__next {
  width: 18px;
  height: 13px;

  -webkit-transition: opacity 0.25s ease-out;
  transition: opacity 0.25s ease-out;
  vertical-align: top;

  background: url("assets/icons/arrow_next_dark.svg") no-repeat 50% 50%;
  background-size: cover;
}
.flow__prev_hidden,
.flow__next_hidden {
  cursor: default;

  opacity: 0.5;
}
.flow_popup .flow__prev,
.flow_popup .flow__next {
  position: absolute;
  top: 116px;
  right: 10px;

  width: 32px;
  height: 47px;

  background-image: url("assets/icons/arrow_right_white.svg");
}

.flow__prev {
  float: left;

  background-image: url("assets/icons/arrow_prev_dark.svg");
}
.flow_popup .flow__prev {
  right: auto;
  left: 10px;

  background-image: url("assets/icons/arrow_left_white.svg");
}

.flow__main {
  position: relative;

  height: 206px;
}

.flow__list {
  position: absolute;
  top: 0;
  right: 0;
  left: -2px;

  margin: 0;
  padding: 0;

  list-style: none;

  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
  white-space: nowrap;
}
@media screen and (max-width: 999px) {
  .flow__list {
    display: block;
    overflow: auto;

    margin: -20px -16px -10px 0 !important;
    padding: 10px 0 10px 16px;

    white-space: nowrap;
  }
  .flow__flow .flow__list {
    overflow: visible;

    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .flow_popup .flow__list {
    padding: 0;
  }
}
@media screen and (max-width: 480px) {
  .flow__list {
    margin-right: -8px !important;
  }
  .flow__flow .flow__list {
    margin-right: 0 !important;
  }
}

.flow__item {
  position: relative;

  display: inline-block;

  width: 40%;
  min-width: 220px;
  max-width: 280px;
  padding: 0 2px;

  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.layout__search .flow__item {
  width: 45%;
}
.layout__search_close .flow__item {
  width: 23.5%;
}
@media screen and (min-width: 1260px) {
  .layout__search .flow__item {
    width: 31%;
  }
  .layout__search_close .flow__item {
    width: 19%;
  }
}
@media screen and (min-width: 1600px) {
  .layout__search .flow__item {
    width: 23.5%;
  }
  .layout__search_close .flow__item {
    width: 16%;
  }
}
@media screen and (max-width: 999px) {
  .flow__item {
    display: inline-block;

    width: 23.5vw !important;
    min-width: 180px;
    margin-top: 20px;
    margin-right: 4px;

    vertical-align: top;
  }
  .flow__item_triple {
    width: 270px !important;
  }
  .flow__item_double {
    width: 360px !important;
  }
  .flow__item_room {
    height: 209px;

    background: #e1e1e1;
  }
  .flow__flow .flow__item {
    width: 180px !important;
  }
}
.flow__item_active {
  z-index: 300;
}

.flow__cnt {
  position: relative;

  height: 206px;
}

.flow__box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;

  display: block;

  box-sizing: border-box;
  width: 100%;

  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  white-space: normal;
  text-decoration: none;

  color: #494949;
  border: 2px solid #e1e1e1;
  background: #fff;

  will-change: width, bottom;
}
.flow__item_active .flow__box {
  bottom: -124px;

  width: 330px;

  -webkit-transform: translate(-50%, -50px);
  -ms-transform: translate(-50%, -50px);
  transform: translate(-50%, -50px);
}

.flow__cover {
  position: relative;

  height: 140px;
  margin: 0 0 5px;

  -webkit-transition: height 0.25s ease-out;
  transition: height 0.25s ease-out;

  background: #e1e1e1 no-repeat 50% 50%;
  background-size: cover;
}
.flow__cover:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: "";
  -webkit-transition: opacity 0.25s ease-out;
  transition: opacity 0.25s ease-out;

  opacity: 0;
  background: #000;
}
.flow__item_active .flow__cover {
  height: 190px !important;
}

.flow__badge {
  position: absolute;
  top: -10px;
  right: 12px;

  width: 42px;
  height: 37px;

  -webkit-transition: top 0.25s ease-out;
  transition: top 0.25s ease-out;

  background: url("assets/icons/badge.svg") no-repeat 0 0;
  background-size: cover;
}
.flow__badge:before {
  position: absolute;
  top: 7px;
  left: 14px;

  width: 15px;
  height: 15px;

  content: "";

  background: url("assets/icons/ico_star.svg") no-repeat 0 0;
  background-size: cover;
}
.flow__badge_like:before {
  top: 7px;
  left: 13px;

  width: 18px;
  height: 15px;

  background-image: url("assets/icons/ico_favorite.svg");
  background-size: cover;
}
.flow__item_active .flow__badge,
.flow_popup .flow__badge {
  top: 14px;
}

.flow__info {
  box-sizing: border-box;
  height: 57px;
  padding: 5px 0 5px 12px;

  background: #fff;
}
@media screen and (max-width: 999px) {
  .flow__info {
    font-size: 13px;

    height: 60px;
  }
}

@media screen and (max-width: 999px) {
  .flow__bg {
    position: fixed;
    z-index: 2;
    top: 100%;
    right: 0;
    bottom: 0;
    left: 0;

    -webkit-transition: opacity 0.25s ease-out;
    transition: opacity 0.25s ease-out;

    opacity: 0;
    background: #000;
  }
  .flow__item_active .flow__bg {
    top: 0;

    opacity: 0.5;
  }
}

@media screen and (max-width: 999px) {
  .flow__close {
    position: fixed;
    top: 66px;
    right: 16px;

    width: 48px;
    height: 48px;

    cursor: pointer;

    opacity: 0;
    border-radius: 50%;
    background: #fff url("assets/icons/ico_close.svg") no-repeat 50% 50%;
  }
  .flow__item_active .flow__close {
    z-index: 40;

    -webkit-transition: opacity 0.25s 0.25s ease-out;
    transition: opacity 0.25s 0.25s ease-out;

    opacity: 1;
  }
}

.flow__user {
  font-size: 16px;

  position: relative;

  overflow: hidden;

  height: 80px;
}
@media screen and (max-width: 999px) {
  .flow__user {
    height: 50px;
  }
}
.flow__item_active .flow__user {
  height: auto;
}

.flow__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.lt-ie10 .flow__content {
  display: table;

  width: 100%;
}

.flow__price {
  font-size: 16px;
}
@media screen and (max-width: 999px) {
  .flow__price {
    font-size: 12px !important;
  }
}

.flow__about {
  line-height: 1.15;

  overflow: hidden;

  height: 38px;
  margin: 12px 0 0;

  word-wrap: break-word;
  word-break: break-all;

  color: #939393;
}

.flow__actions {
  height: 22px;
  margin: 12px 0 0;
}

.flow__action {
  display: inline-block;

  width: 22px;
  height: 22px;
  margin: 0 10px 0 0;

  background: no-repeat 50% 50%;
}
.flow__action_send {
  background-image: url("assets/icons/ico_send_primary.svg");
}
.flow__action_load {
  background-image: url("assets/icons/ico_load.svg");
}
.flow__action_load:hover {
  background-image: url("assets/icons/ico_load_primary.svg");
}
.flow__action_like {
  background-image: url("assets/icons/ico_like.svg");
}
.flow__action_like:hover {
  background-image: url("assets/icons/ico_like_primary.svg");
}

.flow__text {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.lt-ie10 .flow__text {
  display: table-cell;

  vertical-align: top;
}

.flow__profile {
  min-width: 80px;
  margin-right: -16px;
  margin-left: 16px;
  padding: 0 10px;

  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
  text-align: center;

  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.flow__item_active .flow__profile {
  margin-right: 8px;
}
.lt-ie10 .flow__profile {
  display: table-cell;

  vertical-align: top;
}

.flow__about,
.flow__actions,
.flow__profile .human__about {
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;

  opacity: 0;
}
.flow__item_active .flow__about,
.flow__item_active .flow__actions,
.flow__item_active .flow__profile .human__about {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;

  opacity: 1;
}
