.ReducedFilters {
  width: calc(100% - 140px);
  display: flex;
  position: relative;
  overflow-x: auto;
  margin: 0 40px 0 40px;
  padding: 0px 12px;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
