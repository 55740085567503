@import "baseExt.css";

.listings > .section > .section__main {
  overflow: initial;
}

.listing__item {
  padding: 16px 0;

  border-top: 1px solid #e1e1e1;
}
.listing__item:first-child {
  padding-top: 0;

  border-top: none;
}
@media screen and (max-width: 762px) {
  .listing__item:last-child {
    padding-bottom: 0;
  }
  .listing__item_request {
    padding-top: 24px;
  }
  .listing__item_request:first-child {
    padding-top: 16px;
  }
}
@media screen and (max-width: 600px) {
  .listing__item {
    padding-bottom: 20px;
  }
}

.listing__content {
  position: relative;
}

.listing__main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;

  min-height: 180px;
  margin-left: 307px;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
}
.listing__item_request .listing__main {
  min-height: 215px;
}
@media screen and (max-width: 762px) {
  .listing__main {
    margin-left: 256px;
  }
}
@media screen and (max-width: 600px) {
  .listing__main {
    display: block;

    margin-left: 0;
  }
}

.listing__header {
  position: relative;

  margin: 0 0 16px;
  padding-right: 32px;

  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.listing__title {
  margin: 0;
}
.listing__title:after {
  display: none;
}

.listing__location {
  margin: 8px 0 0;
}

.listing__menu {
  position: absolute;
  top: -8px;
  right: 0;
}

.listing__cover {
  position: absolute;
  top: 0;
  left: 0;

  width: 275px;
  height: 180px;

  background: #eee url("assets/images/listing_empty.svg") no-repeat 50% 50%;
  background-size: cover;
}
.listing__cover_empty {
  background-size: auto;
}
.listing__item_request .listing__cover {
  height: 215px;
}
@media screen and (max-width: 762px) {
  .listing__cover {
    width: 240px;
  }
}
@media screen and (max-width: 600px) {
  .listing__cover {
    position: relative;

    width: 275px;
    margin: 8px 0 20px;
  }
}
@media screen and (max-width: 480px) {
  .listing__cover {
    width: auto;
    min-height: 200px;
  }
}

.listing__text {
  font-size: 0.86rem;
  color: var(--badi-dark-grey);
}
.listing__text_booking {
  font-weight: 500;
  color: var(--badi-booking-blue);
}
.listing__item_request .listing__text {
  font-size: 16px;

  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.listing__actions {
  margin: 16px 0 0;
}

@media screen and (max-width: 600px) {
  .listing__actions {
    clear: both;

    text-align: center;
  }
}

.listing__action {
  display: inline-block;
  margin: 0 5px 0 0;
}
.listing__action:first-child {
  width: calc(100% - 125px);
}
.listing__action:last-child {
  margin: 0;
}

@media screen and (min-width: 1260px) {
  .listing__action:first-child {
    width: calc(100% - 155px);
  }
}

.listing__progress {
  max-width: 310px;
}

.listing__user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  min-height: 56px;
  margin: 0 0 0 -2px;

  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.listing__user_online {
  margin-left: 0;
}
@media screen and (max-width: 600px) {
  .listing__user {
    display: block;
    float: right;

    width: 86px;
    margin: 0 0 10px 10px;

    text-align: center;
  }
}

.listing__author {
  margin-left: 16px;
}
@media screen and (max-width: 600px) {
  .listing__author {
    margin-left: 0px;
  }
}

.listing__photo {
  width: 100%;
  height: 100%;
}

.listing__name {
  font-size: 16px;
  font-weight: 700;

  color: #aeaeae;
}
@media screen and (max-width: 600px) {
  .listing__name {
    font-size: 14px;
  }
}

.listing__price {
  font-size: 18px;
  font-weight: 700;

  margin: 16px 0 8px;
}
@media screen and (max-width: 600px) {
  .listing__price {
    font-size: 16px;
  }
}

.listing__label {
  font-size: 18px;

  margin: 0;
}
@media screen and (max-width: 600px) {
  .listing__label {
    font-size: 14px;
  }
}
