@import "../../../../styles/baseExt.css";

.LandingList {
  list-style: none;
  counter-reset: info-list-counter;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;

  &__Title {
    padding-bottom: 48px;

    > * {
      margin: 0;
      font-size: 1.5rem;
    }
  }

  > li {
    counter-increment: info-list-counter;
  }
}
