@import "../../../../styles/baseExt.css";

.LandingSection {
  &__ImageWrapper {
    width: 100%;
    position: relative;
  }

  &__Image {
    width: 100%;
    padding: 0;
    height: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding-bottom: 100%;

    @media screen and (min-width: $media-breakpoint-md) {
      padding-bottom: 50%;
    }
  }

  &__Content {
    position: absolute;
    left: 10%;
    width: 80%;
    bottom: 10%;

    @media screen and (min-width: $media-breakpoint-md) {
      width: 50%;
    }

    > * + * {
      margin-top: 16px;

      @media screen and (min-width: $media-breakpoint-lg) {
        margin-top: 32px;
      }
    }
  }
}
