.SearchAvailabilityForm {
  &__FromDate {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 8px 0 16px 0;

    > * {
      width: calc(50% - 8px);
    }
  }

  &__Calendar {
    position: absolute;
    left: 0;
    top: 45px;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > *:last-child {
      z-index: 5;
    }
  }

  &__Slider {
    margin-top: 0;
    margin-bottom: 16px;
    padding: 0 14px;
  }
}
