@import "../../../../styles/baseExt.css";

.SecurityFaqs {
  border-radius: 4px;
  border: solid 2px #f0f0f0;
  padding: 32px 15px 40px 15px;
  margin-bottom: 56px;

  @media screen and (min-width: $media-breakpoint-md) {
    margin-bottom: 72px;
    padding: 32px 32px 40px 32px;
  }

  @media screen and (min-width: $media-breakpoint-lg) {
    margin-bottom: 96px;
  }

  > h3 {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.33;
    color: var(--badi-dark-grey);
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__Option {
    display: flex;
    align-items: flex-start;
    padding-bottom: 16px;

    a {
      text-decoration: none;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: var(--badi-dark-grey);

      &:hover,
      &:visited,
      &:link,
      &:active {
        text-decoration: none;
      }
    }
  }

  &__Arrow {
    display: block;
    float: left;
    margin-right: 16px;
  }

  &__MainLink {
    text-decoration: none;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 1.33;
    color: var(--badi-green-dark);

    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
    }
  }
}
