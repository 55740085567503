@import "../../styles/baseExt.css";

.Report--Modal .popup__main {
  width: 630px;
}
.Report--Modal .popup__content {
  margin: 0;
  max-width: initial;
}

.Report--Modal .popup__body {
  padding: 0 30px 30px;
}

.Report--description {
  font-size: 1rem;
}

.Report--content {
  padding: 32px 0;
}

.Report--option {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 8px 0;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.Report--option-name {
  font-size: 1.06rem;
  font-weight: bold;
}

.Report--option-description {
  color: #bbbbbb;
  margin-top: 0;
}

.Report--option > svg {
  width: 62px;
  margin-right: 33px;
}

.Report--option-selected .Report--option-name {
  color: var(--badi-pink);
}

.Report--result,
.Report--result h1 {
  text-align: center;
}

.Report--result p {
  padding: 24px 0 64px 0;
  margin: 0;
}
